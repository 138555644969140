const storageBaseUrl = "https://storage.googleapis.com/leeearning.appspot.com/";
const storageEnDirectory = "enAudio";

const gcVoices = [
  "en-US-News-L",
  "en-US-Standard-F",
  "en-US-Standard-G",
  "en-US-Standard-H",
  "en-US-Standard-J",
];

const myNewWords = ["to shape", "destiny", "vase", "clay"];

const drawerWidth = 240;
const footerHeight = 50;
const appBarHeight = 48;

const storageEnAduioDirectory = "https://storage.googleapis.com/leeearning.appspot.com/enAudio/";
const storageUaAduioDirectory = "https://storage.googleapis.com/leeearning.appspot.com/uaAudio/";

const englishGrammarCourseWordsMap: Record<string, string[]> = {
  "1": [
    "he",
    "(verb) does",
    "it",
    "every",
    "day",
    "here",
    "there",
    "very",
    "well",
    "(verb) helps",
    "her",
    "(verb) knows",
    "(verb) likes",
    "you",
    "(verb) remembers",
    "me",
    "(verb) speaks",
    "English",
    "Ukrainian",
    "(verb) understands",
    "them",
    "us",
    "(verb) works",
    "I",
    "(verb) do",
    "(verb) help",
    "him",
    "(verb) know",
    "(verb) like",
    "(verb) need",
    "(verb) remember",
    "(verb) speak",
    "(verb) understand",
    "(verb) work",
    "she",
    "they",
    "together",
    "(verb) live",
    "we",
  ],
  "2": [
    "(verb) to want",
    "to",
    "(verb) to do",
    "(verb) to know",
    "(verb) wants",
    "how",
    "(verb) to need",
    "today",
    "(verb) to see",
    "(verb) to sleep",
    "(verb) to call",
    "(verb) to hug",
    "(verb) to have",
    "(verb) has",
    "(verb) to help",
    "tomorrow",
    "my",
    "(verb) to visit",
    "(verb) needs",
    "uncle",
    "aunt",
    "(verb) to like",
    "(verb) to taste",
    "different",
    "food",
    "(verb) to eat",
    "homework",
    "(verb) to try",
    "something",
    "(verb) to be",
    "kind",
    "(verb) tries",
    "(verb) to go",
    "again",
    "(verb) to tell",
    "(verb) to buy",
    "some",
    "milk",
    "(verb) to drink",
    "cheese",
    "for",
    "(verb) to listen",
  ],
  "3": [
    "your",
    "project",
    "(verb) to get",
    "(verb) to meet",
    "work",
    "(verb) to plan",
    "now",
    "(verb) to talk",
    "new",
    "his",
    "better",
    "school",
    "everything",
    "always",
    "sister",
    "carefully",
    "brother",
    "music",
  ],
};

const verbs = [
  "want",
  "do",
  "know",
  "need",
  "see",
  "sleep",
  "call",
  "happen",
  "hug",
  "have",
  "like",
  "help",
  "plan",
  "visit",
  "taste",
  "eat",
  "try",
  "get",
  "be",
  "go",
  "meet",
  "work",
  "talk",
  "tell",
  "watch",
  "buy",
  "drink",
  "think",
  "listen",
  "sing",
  "understand",
];

export const wordsWithDifferentPronunciation = ["produce", "live"];

// const alwaysUpperWords = [
//   "I",
//   "English",
//   "I'll",
//   "Ukraine",
//   "Netherlands",
//   "X-ray",
//   "I'm",
//   "I've",
//   "Lisa",
//   "I'd",
//   "Celsius",
//   "Monday",
//   "Thursday",
//   "Tuesday",
//   "Wednesday",
//   "Friday",
//   "Sunday",
//   "Saturday",
//   "March",
//   "Brazil",
// ];

const bindTopics: Record<string, string> = {
  introduction: "introduction",
  introductions: "introduction",
  "introductions, nicknames": "introduction",
  "introducing oneself": "introduction",
  "family upbringing": "family",
};
const reverseBindTopics = Object.entries(bindTopics).reduce<Record<string, string[]>>(
  (prev, [key, value]) => {
    return {
      ...prev,
      [value]: Array.from(new Set([...(prev[value] || []), key])),
    };
  },
  {},
);

const adminUserId = "shevsky1488";

const thirdPersonVerbs = [
  "wants",
  "does",
  "knows",
  "happens",
  "watchs",
  "thinks",
  "needs",
  "sees",
  "sleeps",
  "calls",
  "hugs",
  "has",
  "likes",
  "helps",
  "plans",
  "visits",
  "tastes",
  "eats",
  "tries",
  "gets",
  "is",
  "are",
  "am",
  "goes",
  "meets",
  "works",
  "talks",
  "tells",
  "buys",
  "sings",
  "drinks",
  "listens",
  "understands",
];

const mainTopics = [
  "job",
  "time",
  "cafe",
  "food",
  "store",
  "street",
  "family",
  "request",
  "language",
  "education",
  "interests",
  "introduction",
];

const versesWords: string[] = [
  "mum",
  "says",
  "I",
  "have",
  "to",
  "brush",
  "my",
  "teeth",
  "in",
  "the",
  "morning",
  "and",
  "at",
  "night",
  "they",
  "look",
  "taste",
  "much",
  "better",
  "but",
  "hate",
  "it",
  "when",
  "she’s",
  "right",
  "dad",
  "take",
  "a",
  "bath",
  "wash",
  "body",
  "well",
  "do",
  "feel",
  "nice",
  "clean",
  "fresh",
  "now",
  "he’s",
  "as",
  "brother",
  "hands",
  "keep",
  "them",
  "fingers",
  "so",
  "good",
  "without",
  "dirt",
  "between",
  "sister",
  "eat",
  "some",
  "vegetables",
  "each",
  "day",
  "more",
  "energy",
  "run",
  "walk",
  "play",
  "healthy",
  "I’ve",
  "got",
  "skin",
  "don’t",
  "tell",
  "that",
  "think",
  "they’re",
  "win",
  "Gillian",
  "Craig",
  "why",
  "we",
  "call",
  "circle",
  "of",
  "friends",
  "reason",
  "is",
  "always",
  "bends",
  "shape",
  "won’t",
  "change",
  "with",
  "someone",
  "new",
  "if",
  "there",
  "are",
  "many",
  "or",
  "just",
  "few",
  "strong",
  "can",
  "shrink",
  "grow",
  "you",
  "meet",
  "people",
  "you’d",
  "like",
  "know",
  "lose",
  "friend",
  "silly",
  "fight",
  "others",
  "will",
  "be",
  "all",
  "never",
  "runs",
  "out",
  "space",
  "come",
  "go",
  "there’ll",
  "place",
  "gap",
  "close",
  "open",
  "again",
  "filled",
  "by",
  "no",
  "matter",
  "there’s",
  "room",
  "for",
  "another",
  "one",
  "person",
  "share",
  "fun",
  "who",
  "your",
  "feelings",
  "care",
  "inside",
  "free",
  "two",
  "three",
  "skates",
  "scooters",
  "books",
  "toys",
  "different",
  "girls",
  "boys",
  "other",
  "shapes",
  "angles",
  "lines",
  "really",
  "can’t",
  "those",
  "designs",
  "bend",
  "make",
  "wide",
  "you’ll",
  "find",
  "world",
  "families",
  "let’s",
  "might",
  "here",
  "lee’s",
  "family",
  "his",
  "helps",
  "homework",
  "gets",
  "done",
  "she",
  "keeps",
  "their",
  "house",
  "reads",
  "stories",
  "works",
  "lawyer",
  "makes",
  "bad",
  "things",
  "jade’s",
  "her",
  "man",
  "knows",
  "number-one",
  "fan",
  "wakes",
  "up",
  "noise",
  "he",
  "cooks",
  "yummy",
  "food",
  "fixes",
  "here’s",
  "jo",
  "sam’s",
  "granny’s",
  "feeds",
  "clothes",
  "home",
  "although",
  "retired",
  "still",
  "lots",
  "sure",
  "study",
  "exercise",
  "too",
  "yours",
  "theirs",
  "help",
  "everyone",
  "shares",
  "doesn’t",
  "how",
  "thing",
  "that’s",
  "important",
  "love",
  "ever",
  "wonder",
  "mole",
  "see",
  "snake",
  "sing",
  "fish",
  "snail",
  "talk",
  "what",
  "it’s",
  "me",
  "goose",
  "ski",
  "dog",
  "write",
  "hen",
  "hop",
  "shark",
  "stop",
  "cow",
  "moo",
  "seal",
  "clap",
  "goat",
  "bleat",
  "bird",
  "tweet",
  "i’m",
  "not",
  "same",
  "show",
  "say",
  "games",
  "dreams",
  "last",
  "flew",
  "outer",
  "fast",
  "far",
  "an",
  "alien",
  "spaceship",
  "tried",
  "chase",
  "round",
  "shining",
  "star",
  "had",
  "pair",
  "wings",
  "couldn’t",
  "fly",
  "watch",
  "chairs",
  "went",
  "floating",
  "helped",
  "dragon",
  "roar",
  "fire",
  "gave",
  "chili",
  "peppers",
  "watched",
  "flames",
  "shoot",
  "higher",
  "found",
  "magic",
  "shoes",
  "which",
  "made",
  "tall",
  "jumped",
  "high",
  "played",
  "basketball",
  "painted",
  "whole",
  "town",
  "yellow",
  "blue",
  "red",
  "looked",
  "circus",
  "clown",
  "colored",
  "instead",
  "curl",
  "bed",
  "excited",
  "head",
  "about",
  "favorite",
  "places",
  "maybe",
  "tree",
  "winter",
  "snow",
  "green",
  "leaves",
  "summer",
  "branches",
  "swinging",
  "pulling",
  "on",
  "seeing",
  "climb",
  "beach",
  "sand",
  "waves",
  "splash",
  "shore",
  "boats",
  "wave",
  "brighter",
  "sun",
  "than",
  "before",
  "garden",
  "grass",
  "trees",
  "path",
  "winds",
  "around",
  "swing",
  "sandpit",
  "use",
  "flowers",
  "over",
  "ground",
  "kitchen",
  "warm",
  "safe",
  "where",
  "meets",
  "cook",
  "laugh",
  "chat",
  "happened",
  "today",
  "from",
  "these",
  "shop",
  "train",
  "zoo",
  "need",
  "perfect",
  "game",
  "chores",
  "time",
  "tidy",
  "score",
  "points",
  "household",
  "housework",
  "cup",
  "dust",
  "shelves",
  "get",
  "point",
  "sweep",
  "floor",
  "vacuum",
  "rugs",
  "then",
  "bathroom",
  "shower",
  "sink",
  "toilet",
  "five",
  "lay",
  "table",
  "washing-up",
  "extra",
  "mum’s",
  "car",
  "also",
  "very",
  "ask",
  "yourself",
  "(if",
  "want",
  "play)",
  "could",
  "tidied",
  "going",
  "school",
  "came",
  "zebra",
  "laughed",
  "must",
  "quite",
  "funny",
  "ride",
  "was",
  "arrived",
  "tricycle",
  "thought",
  "late",
  "legs",
  "were",
  "cycling",
  "whizzed",
  "straight",
  "through",
  "gate",
  "hot",
  "air",
  "balloon",
  "earliest",
  "arrive",
  "he’d",
  "floated",
  "above",
  "traffic",
  "jam",
  "cars",
  "drive",
  "next",
  "children",
  "bus",
  "talking",
  "together",
  "arrives",
  "every",
  "kind",
  "weather",
  "best",
  "walked",
  "does",
  "met",
  "chatted",
  "way",
  "arriving",
  "took",
  "long",
  "queue",
  "let",
  "engines",
  "ways",
  "shops",
  "parks",
  "it's",
  "easy",
  "click",
  "you're",
  "browsing",
  "nobody",
  "discover",
  "cool",
  "bizarre",
  "link",
  "opens",
  "picture",
  "wish",
  "wasn't",
  "can't",
  "seem",
  "delete",
  "charge",
  "fair",
  "anything",
  "gives",
  "feeling",
  "stomach",
  "something",
  "wrong",
  "don't",
  "afraid",
  "parents",
  "truth",
  "shows",
  "they'll",
  "angry",
  "scared",
  "mad",
  "glad",
  "told",
  "knew",
  "moving",
  "fill",
  "boxes",
  "van",
  "put",
  "back",
  "move",
  "pack",
  "grab",
  "fold",
  "forget",
  "single",
  "even",
  "old",
  "under",
  "beds",
  "top",
  "desks",
  "lamps",
  "behind",
  "door",
  "please",
  "check",
  "everywhere",
  "goodbye",
  "ceilings",
  "walls",
  "windows",
  "remember",
  "times",
  "explore",
  "you'll",
  "adore",
  "stay",
  "live",
  "roam",
  "neighborhood",
  "streets",
  "special",
  "brings",
  "friendly",
  "faces",
  "adults",
  "this",
  "that's",
  "happy",
  "older",
  "left",
  "hand",
  "internet",
  "shopping",
  "yet",
  "problems",
  "class",
  "stressed",
  "exams",
  "pass",
  "understand",
  "tricky",
  "part",
  "start",
  "messy",
  "litter",
  "throw",
  "bin",
  "recycling",
  "let's",
  "visit",
  "nursing",
  "away",
  "collect",
  "charity",
  "store",
  "power",
  "boy",
  "started",
  "he's",
  "sitting",
  "Jude",
  "everybody",
  "him",
  "rude",
  "wondered",
  "mine",
  "bothered",
  "seemed",
  "fine",
  "suddenly",
  "classroom",
  "exciting",
  "bigger",
  "coming",
  "face",
  "spoke",
  "break",
  "felt",
  "shy",
  "would",
  "cry",
  "saw",
  "staring",
  "eyes",
  "because",
  "tails",
  "trail",
  "along",
  "we're",
  "name",
  "I'd",
  "looks",
  "familiar",
  "she's",
  "teacher",
  "should",
  "hard",
  "hope",
  "classmates",
  "names",
  "correct",
  "playtime's",
  "playground",
  "after",
  "I've",
  "PE",
  "science",
  "quiz",
  "packed",
  "t-shirt",
  "running",
  "feet",
  "used",
  "walking",
  "english",
  "math",
  "history",
  "shirt",
  "feels",
  "itchy",
  "course",
  "kid",
  "who's",
  "wearing",
  "Peter",
  "said",
  "hello",
  "I'm",
  "lucky",
  "smile",
  "big",
  "only",
  "takes",
  "picnic",
  "our",
  "fruit",
  "sweets",
  "crisps",
  "sandwiches",
  "cheese",
  "meat",
  "park",
  "dark",
  "granny",
  "grandad",
  "oh",
  "upside",
  "down",
  "ants",
  "ate",
  "biscuits",
  "has",
  "gone",
  "brown",
  "closed",
  "Sally's",
  "sneezing",
  "we'll",
  "basket",
  "try",
  "senses",
  "rainbow",
  "glowing",
  "rain",
  "speeding",
  "forever",
  "smell",
  "raindrops",
  "falling",
  "paint",
  "paper",
  "art",
  "cooking",
  "lovely",
  "hear",
  "wind",
  "blowing",
  "trying",
  "hungry",
  "cat",
  "climbing",
  "whisper",
  "scream",
  "supporters",
  "cheering",
  "team",
  "touch",
  "pillow",
  "soft",
  "smooth",
  "bubbles",
  "splashing",
  "pool",
  "shelf",
  "toes",
  "petals",
  "rose",
  "ice",
  "cream",
  "creamy",
  "sweet",
  "(just",
  "smelly",
  "feet)",
  "sour",
  "lime",
  "bitter",
  "tea",
  "salt",
  "near",
  "sea",
  "helpers",
  "tasting",
  "tongues",
  "touching",
  "smelling",
  "noses",
  "hearing",
  "ears",
  "surprise",
  "spring",
  "buds",
  "shoots",
  "pushing",
  "opening",
  "bright-green",
  "freshness",
  "lambs",
  "ducklings",
  "having",
  "kites",
  "flying",
  "sudden",
  "showers",
  "us",
  "spring's",
  "days",
  "swimming",
  "bright",
  "there's",
  "summer's",
  "drop",
  "disguise",
  "squirrels",
  "gather",
  "nuts",
  "sleepy",
  "creatures",
  "short",
  "afternoons",
  "mornings",
  "darker",
  "skies",
  "autumn's",
  "stars",
  "snowflakes",
  "sky",
  "robins",
  "sit",
  "leafless",
  "waiting",
  "lakes",
  "freeze",
  "cheeks",
  "turn",
  "sneeze",
  "winter's",
  "I'll",
  "bike",
  "front",
  "road",
  "duck",
  "pond",
  "library",
  "cross",
  "street",
  "brain",
  "bakery",
  "supermarket",
  "bank",
  "enormous",
  "past",
  "bridge",
  "nearly",
  "florist",
  "bookshop",
  "cinema",
  "small",
  "until",
  "mind",
  "sorry",
  "forgive",
  "hurt",
  "someone's",
  "been",
  "unkind",
  "listen",
  "doing",
  "press",
  "rewind",
  "most",
  "wall",
  "adventures",
  "sleep",
  "land",
  "dream",
  "wandering",
  "hall",
  "track",
  "race",
  "lane",
  "bowl",
  "stage",
  "box",
  "hide",
  "hat",
  "wear",
  "treasure",
  "glittering",
  "queen",
  "king",
  "Billy",
  "listens",
  "mother",
  "brushes",
  "goes",
  "doesn't",
  "asks",
  "first",
  "tells",
  "billy",
  "child",
  "life",
  "Nancy",
  "wants",
  "hundred",
  "years",
  "95",
  "100",
  "father",
  "30",
  "70",
  "daddy",
  "okay",
  "yes",
  "honey",
  "nancy",
  "smiled",
  "hug",
  "barking",
  "Bobby",
  "woke",
  "heard",
  "outside",
  "window",
  "loud",
  "opened",
  "asked",
  "bobby",
  "stopped",
  "lost",
  "pencil",
  "David",
  "did",
  "i",
  "lunch",
  "note",
  "dear",
  "thank",
  "helping",
  "david",
  "envelope",
  "counter",
  "toaster",
  "horse",
  "rider",
  "mommy",
  "Sara",
  "mom",
  "loved",
  "rode",
  "almost",
  "saturday",
  "ready",
  "into",
  "bedroom",
  "pink",
  "socks",
  "sneakers",
  "grabbed",
  "wait",
  "minute",
  "sat",
  "seat",
  "corn",
  "Brian",
  "dinner",
  "chair",
  "white",
  "plate",
  "silver",
  "fork",
  "spoon",
  "rice",
  "peas",
  "delicious",
  "row",
  "boat",
  "Brenda",
  "sang",
  "song",
  "while",
  "liked",
  "sometimes",
  "both",
  "words",
  "jumper",
  "Johnny",
  "lying",
  "jump",
  "barked",
  "ran",
  "fence",
  "chased",
  "turned",
  "Elizabeth",
  "washes",
  "likes",
  "soap",
  "water",
  "uses",
  "seconds",
  "stops",
  "washing",
  "turns",
  "off",
  "dries",
  "towel",
  "hours",
  "germs",
  "Germs",
  "cannot",
  "Joseph",
  "knocks",
  "yesterday",
  "knocked",
  "mailman",
  "tomorrow",
  "any",
  "trash",
  "Jennifer",
  "pulled",
  "monday",
  "truck",
  "metal",
  "arm",
  "picked",
  "raised",
  "emptied",
  "afternoon",
  "empty",
  "yard",
  "pull",
  "cold",
  "Thomas",
  "either",
  "jacket",
  "none",
  "warmer",
  "add",
  "numbers",
  "Maria",
  "learning",
  "four",
  "six",
  "didn't",
  "eight",
  "am",
  "learner",
  "slow",
];
const wordsToSkip = ["–", "b"];

export const fullScreenModalStyle = {
  position: "absolute" as "absolute",
  top: "25px",
  left: "50%",
  transform: "translate(-50%, 0%)",
  width: 400,
  overflow: "auto",
  maxHeight: "calc(100% - 50px)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export {
  verbs,
  wordsToSkip,
  versesWords,
  gcVoices,
  mainTopics,
  myNewWords,
  bindTopics,
  drawerWidth,
  adminUserId,
  footerHeight,
  appBarHeight,
  storageBaseUrl,
  // alwaysUpperWords,
  thirdPersonVerbs,
  reverseBindTopics,
  storageEnDirectory,
  storageUaAduioDirectory,
  storageEnAduioDirectory,
  englishGrammarCourseWordsMap,
};
