const upperCaseWords = [
  "English",
  "Oscar",
  "Harry Potter",
  "Mr.",
  "Mrs.",
  "Dursley",
  "Grunnings",
  "Tuesday",
  "Dursleys",
  "Dudley",
  "January",
  "Potters",
  "Dursley's",
  "Privet Drive",
  "Miss",
  "Ms.",
  "I",
  "WAN",
  "Sunday",
  "I'm",
  "LAN",
  "March",
  "Fran",
  "Fran's",
  "Mondays",
  "Netherlands",
  "Ukraine",
  "I've",
  "I'll",
  "I'd",
  "Celsius",
  "Monday",
  "Amazon",
  "Denmark",
  "Europe",
  "April",
  "TV",
  "Saturday",
  "Friday",
  "Sundays",
  "Canada",
  "June",
  "USA",
  "Ms. Ann",
  "Mr. Jones",
  "Paris",
  "Miss May",
  "Mr. and Mrs. Dursley",
  "February",
  "Mrs. Jones",
];

export default upperCaseWords;
