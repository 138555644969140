import { Box, IconButton, Modal, TextField, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import supabaseClient from "../../../common/supabaseClient";
import ChatIcon from "@mui/icons-material/Chat";
import { useLocalStorage } from "react-use";
import fetchCurrentUser, { RPCUser } from "../../../common/fetchCurrentUser";
import isAdmin from "../../../common/isAdmin";
import useMountedWaitAsyncEffect from "../../../hooks/useMountedWaitAsyncEffect";
import { useLessonsStore } from "../../../store/lessonsStore";
import AdminScreen from "../../Admin/AdminScreen";
import AdminAddBanglaSentence from "../../AdminAddBanglaSentence";
import AdminAddVerse from "../../AdminAddVerse";
import AdminCheckUserQuotes from "../../AdminCheckUserQuotes";
import AdminCheckUserStories from "../../AdminCheckUserStories";
import AdminCreateBanglaGrammarCourse from "../../AdminCreateBanglaGrammarCourse";
import AdminCreateDifferenceLesson from "../../AdminCreateDifferenceLesson";
import AdminCreateGrammarCourse from "../../AdminCreateGrammarCourse";
import AdminUsers from "../../AdminUsers";
import BanglaGrammarLesson from "../../BanglaGrammar/BanglaGrammarLesson";
import BanglaGrammarScreen from "../../BanglaGrammar/BanglaGrammarScreen";
import CreatePersonalLesson from "../../CreatePersonalLesson";
import DifferenceSubject from "../../Difference/DifferenceSubject";
import Difference from "../../Difference/Page";
import FoodTrackingScreen from "../../Food/FoodTrackingScreen";
import GrammarLesson from "../../Grammar/GrammarLesson";
import { GrammarScreen } from "../../Grammar/GrammarScreen";
import Home from "../../Home/Page";
import PersonalDialogs from "../../PersonalDialogs";
import PersonalLessons from "../../PersonalLessons";
import Preview from "../../Preview";
import HarryPotterChapter from "../../Reading/HarryPotterChapter";
import HarryPotterScreen from "../../Reading/HarryPotterScreen";
import HarryPotterPhilosophersStone from "../../Reading/HarryPotterScreen/HarryPotterPhilosophersStone";
import Reading from "../../Reading/ReadingScreen";
import BanglaRepetitionSentencesScreen from "../../Sentence/BanglaRepetitionSentencesScreen";
import RepetitionUserSentenceScreen from "../../Sentence/RepetitionUserSentenceScreen";
import TimerScreen from "../../Timer/TimerScreen";
import RepetitionUserWordsScreen from "../../Word/RepetitionUserWordsScreen";
import SanskritRepetitionWordsScreen from "../../Word/SanskritRepetitionWordsScreen";
import GrammarFeed from "../grammar/Feed/GrammarFeed";
import NotFound from "../NotFound";
import AdminUploadVideo from "../../AdminUploadVideo";
import Declarations from "../../Declarations";

// const userIds: Record<string, string> = {
//   tef2304: "86523831-4881-4b89-91b5-def185deba6e",
//   vavdijchuk07: "592d3941-7376-4c08-83c7-a374b2da849c",
//   shevsky1488: "19c7ac10-110d-43e3-ad86-5e425aef49a5",
// };

const chatModalStyle = {
  position: "absolute" as "absolute",
  // top: "50%",
  // left: "50%",
  // transform: "translate(-50%, -50%)",
  // width: 400,
  width: "100vw",
  height: "100%",
  overflow: "auto",
  bgcolor: "background.paper",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  // justifyContent: "center",
  // alignItems: "center",
  // border: "2px solid #000",
  // boxShadow: 24,
  p: 4,
};

const Chat = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = async () => {
    setOpen(false);

    await supabaseClient
      .from("dialog-admin")
      .update({
        open: false,
      })
      .eq("id", "chat");
  };
  const [textValue, setTextValue] = useState("");
  const inputRef = useRef<any>(null);
  const authUser = useLessonsStore((state) => state.authUser);
  const [users, setUsers] = useState<any[]>([]);
  const [dbChat, setDbChat] = useState<any>();
  const [studentUserUuid, setStudentUserUuid] = useState<string | null>(null);

  useEffect(() => {
    setTimeout(() => {
      if (open && inputRef.current) {
        inputRef.current.focus();
      }
    }, 200);
  }, [open, inputRef]);

  useMountedWaitAsyncEffect(async () => {
    if (studentUserUuid) {
      await supabaseClient
        .from("dialog-admin")
        .update({ user: studentUserUuid, text: [] })
        .eq("id", "chat");
    }
  }, [studentUserUuid]);

  useMountedWaitAsyncEffect(async () => {
    // if (isAdmin(authUser?.id)) {
    // }
    const { data } = await supabaseClient
      .from("dialog-admin")
      .select()
      .eq("id", "chat")
      .single()
      .throwOnError();
    if (data) setDbChat(data);

    const channel = supabaseClient
      .channel("room5")
      .on(
        "postgres_changes",
        { event: "UPDATE", schema: "public", table: "dialog-admin" },
        (payload) => {
          if (payload.new.id === "chat") {
            setDbChat(payload.new as any);
          }
        },
      )
      .subscribe();

    return () => {
      channel.unsubscribe();
    };
  }, []);

  useMountedWaitAsyncEffect(
    async () => {
      if (isAdmin(authUser?.id)) {
        const text = textValue
          .split("\n")
          .map((v) => v.trim())
          .filter(Boolean);

        await supabaseClient
          .from("dialog-admin")
          .update({
            text: text.reverse(),
            open: !!text.length,
          })
          .eq("id", "chat");
      }
    },
    [textValue, authUser],
    200,
  );

  useMountedWaitAsyncEffect(async () => {
    if (isAdmin(authUser?.id) && !users.length) {
      const { data } = await supabaseClient.from("users").select();
      if (data) setUsers(data);
    }
  }, [users, authUser]);

  useEffect(() => {
    if (isAdmin(authUser?.id)) {
      const handleKeyDown = async (event: any) => {
        if (event.shiftKey && event.metaKey && event.keyCode === 13) {
          setOpen(true);
        } else if (event.ctrlKey && event.metaKey && event.keyCode === 13) {
          setTextValue("");

          await supabaseClient
            .from("dialog-admin")
            .update({
              text: [],
            })
            .eq("id", "chat");
          setOpen(true);
        }
      };

      window.addEventListener("keydown", handleKeyDown);

      return () => {
        window.removeEventListener("keydown", handleKeyDown);
      };
    }
  }, [authUser]);

  useEffect(() => {
    if (authUser && dbChat?.user === authUser.uuid && dbChat.open) {
      setOpen(true);
    } else if (authUser && !isAdmin(authUser.id) && dbChat) {
      if (dbChat.user !== authUser.uuid || !dbChat.open) {
        setOpen(false);
      }
    }
  }, [dbChat]);

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={chatModalStyle}>
          <Box mb={20}></Box>
          <Box maxWidth={300} mb={20} width="100%">
            {authUser && dbChat?.user === authUser.uuid && !isAdmin(authUser.id) && (
              <>
                <Box display="flex" flexDirection="column" alignItems="revert">
                  {dbChat.text.map((text: string) => {
                    return (
                      <Typography variant="body1" fontSize={25} width="100%" gutterBottom>
                        {text}
                      </Typography>
                    );
                  })}
                </Box>
              </>
            )}
            {isAdmin(authUser?.id) && (
              <>
                <Box my={10} mb={10}>
                  <TextField
                    inputRef={inputRef}
                    color="primary"
                    fullWidth
                    name="text"
                    value={textValue}
                    label="Baba nam kevalam"
                    multiline
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(v) => setTextValue(v.target.value)}
                    size="medium"
                    placeholder="Baba nam kevalam"
                    type="email"
                    variant="outlined"
                  />
                </Box>
                <Box pb={10}>
                  <AdminUsers
                    users={users}
                    onChange={(user) => {
                      setStudentUserUuid(user.uuid);
                    }}
                  />
                </Box>
              </>
            )}
          </Box>
        </Box>
      </Modal>
      {isAdmin(authUser?.id) && (
        <Box
          position="fixed"
          sx={{
            right: 10,
            bottom: 10,
          }}
        >
          <IconButton onClick={handleOpen}>
            <ChatIcon />
          </IconButton>
        </Box>
      )}
    </>
  );
};

// const translationMap: Record<string, string> = {
//   "Рацион, г (ваши данные):": "Diet, g (your data): (Раціон, г (ваші дані):)",
//   "Вода, г": "Water, g (Вода, г)",
//   "Энергия, кКал": "Energy, kcal (Енергія, ккал)",
//   "Белки, г": "Protein, g (Білки, г)",
//   "Жиры, г": "Fat, g (Жири, г)",
//   "Углеводы, г": "Carbohydrates, g (Вуглеводи, г)",
//   "Волокна, г": "Fiber, g (Волокна, г)",
//   "Сахара, г": "Sugars, g (Цукор, г)",
//   Минералы: "Minerals (Мінерали)",
//   "Кальций, Ca, мг": "Calcium, Ca, mg (Кальцій, Ca, мг)",
//   "Железо, Fe, мг": "Iron, Fe, mg (Залізо, Fe, мг)",
//   "Магний, Mg, мг": "Magnesium, Mg, mg (Магній, Mg, мг)",
//   "Фосфор, P, мг": "Phosphorus, P, mg (Фосфор, P, мг)",
//   "Калий, K, мг": "Potassium, K, mg (Калій, K, мг)",
//   "Натрий, Na, мг": "Sodium, Na, mg (Натрій, Na, мг)",
//   "Цинк, Zn, мг": "Zinc, Zn, mg (Цинк, Zn, мг)",
//   "Медь, Cu, мг": "Copper, Cu, mg (Мідь, Cu, мг)",
//   "Марганец, Mn, мг": "Manganese, Mn, mg (Марганець, Mn, мг)",
//   "Селен, Se, мкг": "Selenium, Se, mcg (Селен, Se, мкг)",
//   "Фтор, F, мкг": "Fluoride, F, mcg (Фтор, F, мкг)",
//   Витамины: "Vitamins (Вітаміни)",
//   "C, аскорбиновая к-та, мг": "C, ascorbic acid, mg (C, аскорбінова кислота, мг)",
//   "B1, тиамин, мг": "B1, thiamine, mg (B1, тіамін, мг)",
//   "B2, рибофлавин, мг": "B2, riboflavin, mg (B2, рибофлавін, мг)",
//   "B3, ниацин, мг": "B3, niacin, mg (B3, ніацин, мг)",
//   "B6, пиридоксин, мг": "B6, pyridoxine, mg (B6, піридоксин, мг)",
//   "B9, фолаты, мкг": "B9, folate, mcg (B9, фолати, мкг)",
//   "В12, кобаламин, мкг": "B12, cobalamin, mcg (B12, кобаламін, мкг)",
//   "A, ретинол, мкг": "A, retinol, mcg (A, ретинол, мкг)",
//   "А, ретинол, МЕ": "A, retinol, IU (A, ретинол, МО)",
//   "E, α-токоферол, мг": "E, α-tocopherol, mg (E, α-токоферол, мг)",
//   "D (D2+D3), мкг": "D (D2+D3), mcg (D (D2+D3), мкг)",
//   "D, МЕ": "D, IU (D, МО)",
//   "K, филлохинон, мкг": "K, phylloquinone, mcg (K, філлохінон, мкг)",
//   "B5, пантотеновая к-та, мг": "B5, pantothenic acid, mg (B5, пантотенова кислота, мг)",
//   "В4, холин, мг": "B4, choline, mg (B4, холін, мг)",
//   "Бетаин, мг": "Betaine, mg (Бетаїн, мг)",
//   "Жирные кислоты": "Fatty acids (Жирні кислоти)",
//   "Насыщенные,  г": "Saturated, g (Насичені, г)",
//   "Мононенасыщенные,  г": "Monounsaturated, g (Мононенасичені, г)",
//   "Полиненасыщенные,  г": "Polyunsaturated, g (Поліненасичені, г)",
//   "Холестерин, мг": "Cholesterol, mg (Холестерин, мг)",
//   Аминокислоты: "Amino acids (Амінокислоти)",
//   "Триптофан, г": "Tryptophan, g (Триптофан, г)",
//   "Треонин, г": "Threonine, g (Треонін, г)",
//   "Изолейцин, г": "Isoleucine, g (Ізолейцин, г)",
//   "Лейцин, г": "Leucine, g (Лейцин, г)",
//   "Лизин, г": "Lysine, g (Лізин, г)",
//   "Метионин, г": "Methionine, g (Метионін, г)",
//   "Цистин, г": "Cystine, g (Цистин, г)",
//   "Фенилаланин, г": "Phenylalanine, g (Фенілаланін, г)",
//   "Тирозин, г": "Tyrosine, g (Тирозин, г)",
//   "Валин, г": "Valine, g (Валин, г)",
//   "Аргинин, г": "Arginine, g (Аргінін, г)",
//   "Гистидин, г": "Histidine, g (Гістидин, г)",
//   "Аланин, г": "Alanine, g (Аланін, г)",
//   "Аспарагиновая кислота, г": "Aspartic acid, g (Аспарагінова кислота, г)",
//   "Глутаминовая кислота, г": "Glutamic acid, g (Глутамінова кислота, г)",
//   "Глицин, г": "Glycine, g (Гліцин, г)",
//   "Пролин, г": "Proline, g (Пролін, г)",
//   "Серин, г": "Serine, g (Серин, г)",
//   "Омега-3 (линоленовая), г": "Omega-3 (linolenic), g (Омега-3 (ліноленова), г)",
//   "Омега-6 (линолевая), г": "Omega-6 (linoleic), g (Омега-6 (лінолева), г)",
//   "Теобромин, мг": "Theobromine, mg (Теобромін, мг)",
//   "Кофеин, мг": "Caffeine, mg (Кофеїн, мг)",
//   "Алкоголь, г": "Alcohol, g (Алкоголь, г)",
//   Наркотики: "Drugs (Наркотики)",
// };

const Root: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const session = useLessonsStore((state) => state.session);
  const setSession = useLessonsStore((state) => state.setSession);
  const authUser = useLessonsStore((state) => state.authUser);
  // const setAuthUser = useLessonsStore((state) => state.setRPCUser);
  const setRPCUser = useLessonsStore((state) => state.setRPCUser);
  const isPersonalLesson = useLessonsStore((state) => state.isPersonalLesson);
  const setIsPersonalLesson = useLessonsStore((state) => state.setIsPersonalLesson);
  const [externalPrevAuthPage, setExternalPrevAuthPage, removeExternalPrevAuthPage] =
    useLocalStorage<string | null>("external-prev-auth-page", null);

  // useEffect(() => {
  //   const channel = supabaseClient
  //     .channel("room7")
  //     .on(
  //       "postgres_changes",
  //       { event: "UPDATE", schema: "public", table: "users" },
  //       async (payload) => {
  //         console.log("🚀 ~ payload:", payload);
  //         // await getUserSentences();
  //       },
  //     )
  //     .subscribe();

  //   return () => {
  //     channel.unsubscribe();
  //   };
  // }, []);

  useEffect(() => {
    if (externalPrevAuthPage && authUser && session) {
      removeExternalPrevAuthPage();
      navigate(externalPrevAuthPage);
    }
  }, [authUser, session, externalPrevAuthPage]);

  useMountedWaitAsyncEffect(async () => {
    // const { data } = await supabaseClient.from("user-words").select();
    // .is("user", null);
    // await Promise.all(
    //   data!.map((userWord) => {
    //     const [userId, word] = userWord.id.split("-");
    //     return supabaseClient
    //       .from("user-words")
    //       .update({
    //         id2: `${userIds[userId]}-${word}`,
    //         user: userIds[userId],
    //       })
    //       .eq("id", userWord.id);
    //   }),
    // );
    // console.log(
    //   "🚀 ~ data:",
    //   Object.entries(
    //     data!
    //       .map((i) => i.id)
    //       .reduce((prev, curr) => {
    //         return {
    //           ...prev,
    //           [curr]: (prev[curr] || 0) + 1,
    //         };
    //       }, {}),
    //   ).filter(([key, number]) => (number as number) > 1),
    // );
  }, []);

  useEffect(() => {
    if (location.pathname === "/#") {
      navigate("/");
    }
  }, [location.pathname, navigate]);

  useEffect(() => {
    const channel = supabaseClient
      .channel("room8")
      .on(
        "postgres_changes",
        { event: "UPDATE", schema: "public", table: "users" },
        async (payload) => {
          // console.log("🚀 ~ payload:", payload);
          if (payload.new.id === authUser?.id) {
            setRPCUser(payload.new as RPCUser);
          }
        },
      )
      .subscribe();

    return () => {
      channel.unsubscribe();
    };
  }, []);

  useEffect(() => {
    let mounted = true;
    (async () => {
      const {
        data: { session: newSession },
      } = await supabaseClient.auth.getSession();

      if (!session && newSession) {
        const { data: userData, error } = await supabaseClient.rpc("get_user", {
          userprop: newSession.user.email,
        });

        if (!error) {
          setSession(newSession);

          if (!authUser) {
            const { data: rpcUser } = await supabaseClient.rpc("create_user_google_auth");

            setRPCUser(rpcUser);

            if (location.pathname === "/" && mounted) {
              navigate("/1");
              navigate("/");
            }
          }
        }
      } else if (session && !newSession) {
        setSession(null);
        navigate("/");
      }
    })();

    return () => {
      mounted = false;
    };
  });

  useMountedWaitAsyncEffect(async () => {
    if (session?.user && session.user.email && isPersonalLesson === null) {
      const { data: userData, error } = await supabaseClient.rpc("get_user", {
        userprop: session.user.email,
      });

      if (error) {
        sessionStorage.clear();
        navigate("/");
        return;
      }
      const fetchedUser = await fetchCurrentUser(session.user.email);
      if (fetchedUser) {
        const { is_personal_lesson } = fetchedUser;
        setIsPersonalLesson(Boolean(is_personal_lesson));
      }
    }
  }, [session]);

  useEffect(() => {
    const tiemout = setTimeout(() => {
      if (!session && location.pathname !== "/") {
        // http://localhost:3000/difference/it-this
        setExternalPrevAuthPage(location.pathname);
        navigate("/");
      }
    }, 1000);

    return () => {
      clearTimeout(tiemout);
    };
  }, [session, location.pathname]);

  if (!authUser) {
    return (
      <Box>
        <Routes>
          <Route path="/" element={<Home session={session} />} />
        </Routes>
      </Box>
    );
  }

  // const nameWithTranslation: any = {
  //   name: "name",
  //   "Water, g (Вода, г)": "Water, g",
  //   type: "type",
  //   "Energy, kcal (Енергія, ккал)": "Energy, kcal",
  //   "Protein, g (Білки, г)": "Protein, g",
  //   "Fat, g (Жири, г)": "Fat, g",
  //   "Carbohydrates, g (Вуглеводи, г)": "Carbohydrates, g",
  //   "Fiber, g (Волокна, г)": "Fiber, g",
  //   "Sugars, g (Цукор, г)": "Sugars, g",
  //   "Minerals (Мінерали)": "Minerals",
  //   "Calcium, Ca, mg (Кальцій, Ca, мг)": "Calcium, Ca, mg",
  //   "Iron, Fe, mg (Залізо, Fe, мг)": "Iron, Fe, mg",
  //   "Magnesium, Mg, mg (Магній, Mg, мг)": "Magnesium, Mg, mg",
  //   "Phosphorus, P, mg (Фосфор, P, мг)": "Phosphorus, P, mg",
  //   "Potassium, K, mg (Калій, K, мг)": "Potassium, K, mg",
  //   "Sodium, Na, mg (Натрій, Na, мг)": "Sodium, Na, mg",
  //   "Zinc, Zn, mg (Цинк, Zn, мг)": "Zinc, Zn, mg",
  //   "Copper, Cu, mg (Мідь, Cu, мг)": "Copper, Cu, mg",
  //   "Manganese, Mn, mg (Марганець, Mn, мг)": "Manganese, Mn, mg",
  //   "Selenium, Se, mcg (Селен, Se, мкг)": "Selenium, Se, mcg",
  //   "Fluoride, F, mcg (Фтор, F, мкг)": "Fluoride, F, mcg",
  //   "Vitamins (Вітаміни)": "Vitamins",
  //   "C, ascorbic acid, mg (C, аскорбінова кислота, мг)": "C, ascorbic acid, mg",
  //   "B1, thiamine, mg (B1, тіамін, мг)": "B1, thiamine, mg",
  //   "B2, riboflavin, mg (B2, рибофлавін, мг)": "B2, riboflavin, mg",
  //   "B3, niacin, mg (B3, ніацин, мг)": "B3, niacin, mg",
  //   "B6, pyridoxine, mg (B6, піридоксин, мг)": "B6, pyridoxine, mg",
  //   "B9, folate, mcg (B9, фолати, мкг)": "B9, folate, mcg",
  //   "B12, cobalamin, mcg (B12, кобаламін, мкг)": "B12, cobalamin, mcg",
  //   "A, retinol, mcg (A, ретинол, мкг)": "A, retinol, mcg",
  //   "A, retinol, IU (A, ретинол, МО)": "A, retinol, IU",
  //   "E, α-tocopherol, mg (E, α-токоферол, мг)": "E, α-tocopherol, mg",
  //   "D (D2+D3), mcg (D (D2+D3), мкг)": "D (D2+D3), mcg",
  //   "D, IU (D, МО)": "D, IU",
  //   "K, phylloquinone, mcg (K, філлохінон, мкг)": "K, phylloquinone, mcg",
  //   "B5, pantothenic acid, mg (B5, пантотенова кислота, мг)": "B5, pantothenic acid, mg",
  //   "B4, choline, mg (B4, холін, мг)": "B4, choline, mg",
  //   "Betaine, mg (Бетаїн, мг)": "Betaine, mg",
  //   "Fatty acids (Жирні кислоти)": "Fatty acids",
  //   "Saturated, g (Насичені, г)": "Saturated, g",
  //   "Monounsaturated, g (Мононенасичені, г)": "Monounsaturated, g",
  //   "Polyunsaturated, g (Поліненасичені, г)": "Polyunsaturated, g",
  //   "Cholesterol, mg (Холестерин, мг)": "Cholesterol, mg",
  //   "Amino acids (Амінокислоти)": "Amino acids",
  //   "Tryptophan, g (Триптофан, г)": "Tryptophan, g",
  //   "Threonine, g (Треонін, г)": "Threonine, g",
  //   "Isoleucine, g (Ізолейцин, г)": "Isoleucine, g",
  //   "Leucine, g (Лейцин, г)": "Leucine, g",
  //   "Lysine, g (Лізин, г)": "Lysine, g",
  //   "Methionine, g (Метионін, г)": "Methionine, g",
  //   "Cystine, g (Цистин, г)": "Cystine, g",
  //   "Phenylalanine, g (Фенілаланін, г)": "Phenylalanine, g",
  //   "Tyrosine, g (Тирозин, г)": "Tyrosine, g",
  //   "Valine, g (Валин, г)": "Valine, g",
  //   "Arginine, g (Аргінін, г)": "Arginine, g",
  //   "Histidine, g (Гістидин, г)": "Histidine, g",
  //   "Alanine, g (Аланін, г)": "Alanine, g",
  //   "Aspartic acid, g (Аспарагінова кислота, г)": "Aspartic acid, g",
  //   "Glutamic acid, g (Глутамінова кислота, г)": "Glutamic acid, g",
  //   "Glycine, g (Гліцин, г)": "Glycine, g",
  //   "Proline, g (Пролін, г)": "Proline, g",
  //   "Serine, g (Серин, г)": "Serine, g",
  //   "Drugs (Наркотики)": "Drugs",
  //   "Alcohol, g (Алкоголь, г)": "Alcohol, g",
  //   "Caffeine, mg (Кофеїн, мг)": "Caffeine, mg",
  //   "Theobromine, mg (Теобромін, мг)": "Theobromine, mg",
  //   "Omega-3 (linolenic), g (Омега-3 (ліноленова), г)": "Omega-3 (linolenic), g",
  //   "Omega-6 (linoleic), g (Омега-6 (лінолева), г)": "Omega-6 (linoleic), g",
  // };

  return (
    <Box>
      {/* <Box>
        Copy nutrition
        <Button
          onClick={async () => {
            const data = nutrition;

            // const keys = Array.from(new Set(data.map((i) => Object.keys(i)).flat()));
            // console.log(keys);
            // navigator.clipboard.writeText(JSON.stringify(keys, null, 2));

            const result = await Promise.all(
              data.map((d) => {
                const updatedName = nameWithTranslation[d.name as any];
                return supabaseClient.from("food_items").insert({
                  ...Object.entries(d).reduce((prev, curr) => {
                    return {
                      ...prev,
                      [nameWithTranslation[curr[0]]]: curr[1],
                    };
                  }, {}),
                  // name: updatedName,
                });
              }),
            );
            console.log("🚀 ~ result:", result.map((i) => i.error).filter(Boolean));

            // const foramtedData: any[] = [];
            // const allKeys: string[] = [];
            // // const allNames: string[] = [];

            // let itemName = "";

            // for (let i = 0; i < data.length; i++) {
            //   // for (let i = 0; i < 1; i++) {
            //   const item = data[i];

            //   // console.clear();

            //   const values = Object.values(item);

            //   if (values[0] && typeof values[0] === "string") {
            //     itemName = values[0];
            //     console.log();
            //   } else {
            //   }

            //   const skipValues = ["Помните: продукты, содержащие кофеин, теобромин и другие психоактивные вещества, вызывают зависимость!", "Их употребление сказывается на Вашем здоровье, самочуствии и восприятии!", "Незамен. жирные к-ты (средн. значение)", "Помните: животные - не еда!", "Данные раздела приведены исключительно в ознакомительных/сравнительных целях!", "Производимые животными молоко, яйца, мёд - не могут быть получены без насилия!", "Общие", "Таблица составлена по данным USDA National Nutrient Database национальной сельскохозяйственной библиотеки США (NAL, учр. USDA). Данные были получены в исследовательской лабаратории USDA Nutrient Data Labaratory.", "http://www.ars.usda.gov/", "http://ndb.nal.usda.gov/", "Нормы и максимумы: Food and Nutrition Board, Institute of Medicine, National Academies.", "http://www.iom.edu/", "Мы выбираем мир без насилия, здоровье и жизнь!", "http://veganworld.ru/", "Рацион, г (ваши данные):"];
            //   allKeys.push(...Object.keys(item));

            //   foramtedData.push({
            //     ...Object.entries(item).reduce((prev, curr, index) => {
            //       // if (index === 0) {
            //       //   return prev;
            //       // } else if (index === 1) {
            //       if (index === 0) {
            //         const value = curr[1] as string;

            //         const translation = itemTranslations[value];
            //         return {
            //           ...prev,
            //           // name: `${itemName} - ${value}`,
            //           name: translation || value,
            //         };
            //       } else if (curr[0].startsWith("__")) {
            //         return prev;
            //       } else if (skipValues.includes(curr[0])) {
            //         return prev;
            //       }

            //       const translation = translationMap[curr[0] as any] as any;

            //       if (!translation) {
            //         console.log(`No translation for: ${curr[0]}`);
            //       }

            //       let isValueNumber = curr[1];
            //       if (typeof isValueNumber === "string") {
            //         if (Number(isValueNumber)) {
            //           isValueNumber = Number(isValueNumber);
            //         } else if (isValueNumber.includes(",")) {
            //           const newV = isValueNumber.replace(",", ".");
            //           if (Number(newV)) {
            //             isValueNumber = Number(newV);
            //           }
            //         }
            //       }

            //       return {
            //         ...prev,
            //         [translation || curr[0]]: isValueNumber,
            //         type: "фрукти",
            //       };
            //     }, {}),
            //   });
            // }

            // console.log("done");
            // navigator.clipboard.writeText(JSON.stringify(foramtedData, null, 2));
            // navigator.clipboard.writeText(JSON.stringify(Array.from(new Set(foramtedData.map((i) => i.name))), null, 2));
          }}
        >
          Copy
        </Button>
      </Box> */}
      <Chat />
      <GrammarFeed />
      {/* {authUser.uuid === "19c7ac10-110d-43e3-ad86-5e425aef49a5" && <SignInScreen />} */}
      <Routes>
        <Route path="/" element={<Home session={session} />} />
        <Route path="/difference" element={<Difference />}>
          <Route path=":subject" element={<DifferenceSubject />} />
        </Route>
        <Route path="/sentences" element={<RepetitionUserSentenceScreen />} />
        <Route path="/words" element={<RepetitionUserWordsScreen />} />

        {isPersonalLesson && (
          <>
            <Route path="/personal-lessons" element={<PersonalLessons />} />
            <Route path="/personal-dialogs" element={<PersonalDialogs />} />
            <Route path="/reading" element={<Reading />}>
              <Route path="/reading/harry-potter" element={<HarryPotterScreen />}>
                <Route
                  path="/reading/harry-potter/:bookId"
                  element={<HarryPotterPhilosophersStone />}
                ></Route>
                <Route
                  path="/reading/harry-potter/:bookId/:chapterId"
                  element={<HarryPotterChapter />}
                />
              </Route>
            </Route>
            <Route path="/grammar" element={<GrammarScreen />} />
            <Route
              path="/grammar/grammar-lesson/:lessonId/:lessonType"
              element={<GrammarLesson />}
            />
          </>
        )}
        {/* <Route path="/login10" element={<SignInScreen />} /> */}

        {(authUser.uuid === "19c7ac10-110d-43e3-ad86-5e425aef49a5" ||
          authUser.uuid === "86523831-4881-4b89-91b5-def185deba6e") && (
          <>
            <Route path="/declarations" element={<Declarations />} />
          </>
        )}
        {authUser.uuid === "19c7ac10-110d-43e3-ad86-5e425aef49a5" && (
          <>
            <Route path="/sanskrit" element={<SanskritRepetitionWordsScreen />} />
            <Route path="/bangla-sentences" element={<BanglaRepetitionSentencesScreen />} />
            <Route path="/previews" element={<Preview />} />
            <Route path="/admin" element={<AdminScreen />} />
            <Route path="/add-verse" element={<AdminAddVerse />} />
            <Route path="/upload-video" element={<AdminUploadVideo />} />
            <Route path="/create-personal-lesson" element={<CreatePersonalLesson />} />
            <Route path="/check-user-quotes" element={<AdminCheckUserQuotes />} />
            <Route path="/check-user-stories" element={<AdminCheckUserStories />} />
            <Route path="/create-difference-lesson" element={<AdminCreateDifferenceLesson />} />
            <Route path="/create-grammar-course" element={<AdminCreateGrammarCourse />} />
            <Route
              path="/create-bangla-grammar-course"
              element={<AdminCreateBanglaGrammarCourse />}
            />
            <Route path="/add-bangla-sentence" element={<AdminAddBanglaSentence />} />
            <Route path="/bangla-grammar" element={<BanglaGrammarScreen />} />
            <Route
              path="/bangla-grammar/bangla-grammar-lesson/:lessonId/:lessonType"
              element={<BanglaGrammarLesson />}
            />
            <Route path="/timer" element={<TimerScreen />} />
          </>
        )}
        {(authUser.uuid === "19c7ac10-110d-43e3-ad86-5e425aef49a5" ||
          authUser.uuid === "86523831-4881-4b89-91b5-def185deba6e") && (
          <Route path="/food-tracking" element={<FoodTrackingScreen />} />
        )}

        <Route path="*" element={<NotFound />} />
      </Routes>
    </Box>
  );
};

export default Root;
