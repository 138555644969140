import {
  Link as MuiLink,
  Box,
  Typography,
  Container,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import supabaseClient from "../../../common/supabaseClient";
import useMountedWaitAsyncEffect from "../../../hooks/useMountedWaitAsyncEffect";
import { useLessonsStore } from "../../../store/lessonsStore";
import { EnglishStory, Module, Sentence, Word } from "../../../types/supabase";
import MenuLayout from "../../Layout/MenuLayout";
import Loader from "../../Unknown/Loader";
import Stories from "../Stories";

const RepeatLastLessons: React.FC<{ modules: Module[] }> = ({ modules }) => {
  const [lessonSentences, setLessonSentences] = useState<Sentence[]>([]);
  const [currentModuleWords, setCurrentModuleWords] = useState<Word[]>([]);
  const setGlobalWords = useLessonsStore((state) => state.setGlobalWords);
  const globalWords = useLessonsStore((state) => state.globalWords);
  const authUser = useLessonsStore((state) => state.authUser);
  const [numberOfLessons, setNumberOfLessons] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [videosIds, setVideosIds] = useState<number[]>([]);
  const [stories, setStories] = useState<EnglishStory[]>([]);

  useMountedWaitAsyncEffect(async () => {
    if (!numberOfLessons) return null;
    // setIsLoading(true);

    const selectedModules = modules.sort((a, b) => b.module - a.module).slice(0, numberOfLessons);
    // alert(`modules length: ${modules.length}`);
    // alert(`selectedModules length: ${selectedModules.length}`);
    // alert(
    //   `modules ids: ${modules
    //     .map((m) => m.stories)
    //     .flat()
    //     .join(", ")}`,
    // );
    // alert(
    //   `modules is video: ${modules
    //     .map((m) => m.is_video)
    //     .flat()
    //     .join(", ")}`,
    // );

    const videosIds = selectedModules
      .filter((m) => m.is_video)
      .map((m) => m.stories)
      .flat()
      .filter(Boolean);

    // alert(`video ids in use mkounted: ${videosIds.join(", ")}`);

    setVideosIds(videosIds as number[]);
    // setIsLoading(false);
  }, [modules, numberOfLessons]);

  useMountedWaitAsyncEffect(async () => {
    if (!videosIds.length) return;
    // setIsLoading(true);

    const { data } = await supabaseClient.from("english_verses").select().in("id", videosIds);
    // alert(`stories length ${data!.length}`);
    if (data) setStories(data);
    setIsLoading(false);
  }, [videosIds]);

  // useEffect(() => {
  //   if (videosIds.length) alert(`videoIds ${videosIds}`);
  // }, [videosIds]);
  useEffect(() => {
    setIsLoading(false);
    // if (stories.length) alert(`stories length ${stories.length}`);
  }, [stories]);
  useEffect(() => {
    // setIsLoading(false);
    // if (numberOfLessons) alert(`numberoflessons ${numberOfLessons}`);
  }, [numberOfLessons]);

  if (authUser?.grammar_lesson && authUser?.grammar_lesson < 5) return null;

  return (
    <Box pb={5}>
      <Typography gutterBottom variant="h3">
        Повторення уроків
      </Typography>
      <Box mb={3}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Кількість уроків</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={numberOfLessons}
            sx={{ width: 250 }}
            label="Кількість уроків"
            onChange={(event) => {
              const numb = +event.target.value!;
              if (numb !== numberOfLessons) {
                setIsLoading(true);
                setNumberOfLessons(numb);
              }
            }}
          >
            <MenuItem value={2}>2</MenuItem>
            <MenuItem value={3}>3</MenuItem>
            <MenuItem value={5}>5</MenuItem>

            {authUser?.grammar_lesson! > 7 && <MenuItem value={7}>7</MenuItem>}
          </Select>
        </FormControl>
      </Box>
      {!!stories.length && !isLoading && <Stories stories={stories} />}
      {isLoading && <Loader />}
    </Box>
  );
};

export default RepeatLastLessons;
