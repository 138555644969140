import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Button, IconButton } from "@mui/material";
import isAdmin from "../../../common/isAdmin";
import UkrainianWord from "../../Sentence/UkrainianWord";
import Box from "../../Unknown/Box";
import Typography from "../../Unknown/Typography";
import WordTranscription from "../../Word/WordTranscription";
import { Popover, PopoverContent, PopoverTrigger } from "../../Reading/HarryPotterChapter/Popover";
import useMountedWaitAsyncEffect from "../../../hooks/useMountedWaitAsyncEffect";
import supabaseClient from "../../../common/supabaseClient";
import { Fragment, PropsWithChildren, forwardRef, useEffect, useMemo, useState } from "react";
import {
  AdditionaWord,
  Book,
  FunctionGetWordsWithAdditionalReturn,
  HoverDetailsWords,
  Word,
} from "../../../types/supabase";
import { useLessonsStore } from "../../../store/lessonsStore";
import { useContextMenu } from "react-contexify";

const MENU_ID = "blahblah";

export const Explain: React.FC<
  PropsWithChildren & {
    word: {
      variant?: string | null;
      id: string | number;
      label: string;
    };
    ref?: any;
    onClick: () => void;
    onContextMenu: (event: any) => void;
  }
> = forwardRef(({ word, onClick, onContextMenu, children }, ref) => {
  const authUser = useLessonsStore((state) => state.authUser);

  return (
    <Typography
      key={word.id}
      ref={ref as any}
      // onClick={onClick}
      variant={word.variant as any}
      component="span"
      display="inline-block"
      fontFamily="Nunito Sans"
      onContextMenu={onContextMenu}
      {...(word.variant
        ? {
            fontSize: word.variant === "h3" ? 30 : word.variant === "h4" ? 25 : 35,
          }
        : { fontSize: 20 })}
      sx={{
        cursor: "pointer",
        position: "relative",
        fontWeight: 500,
        "&:hover": {
          textDecoration: "underline",
          "& button": {
            opacity: 1,
          },
        },
      }}
    >
      {word.label}
    </Typography>
  );
});

type HoverDetailsWordType = {
  name: string;
  translation?: string | null;
  description?: string | null;
  additional_words?: AdditionaWord[] | null;
  audio_url?: string | null;
  transcription?: string | null;
  variant?: string | null;
  label: string;
  id: string | number;
  transliterationSentence?: string;
  scriptSentence?: string;
  scriptWord?: string;
};

const HoverDetailsWord: React.FC<{
  word: HoverDetailsWordType;
  onContext: (event: any, word: HoverDetailsWords) => void;
  onDetailsOpen: (currentWordName: string) => void;
}> = ({ word, onContext, onDetailsOpen }) => {
  // const [chapter, setChapter] = useState<Book>();
  const [isLoading, setIsLoading] = useState(true);
  // const explainText = useLessonsStore((state) => state.explainText);
  // const setExplainText = useLessonsStore((state) => state.setExplainText);

  const authUser = useLessonsStore((state) => state.authUser);
  const [open, setOpen] = useState(false);

  // const { show } = useContextMenu({
  //   id: MENU_ID,
  // });

  // function handleContextMenu(event: any, d: HoverDetailsWordType) {
  //   console.log("🚀 ~ d:", d);
  //   console.log("🚀 ~ event:", event);
  //   show({
  //     event,
  //     props: {
  //       word: d,
  //     },
  //   });
  // }

  return (
    <Popover>
      <PopoverTrigger
        onClick={() => {
          setOpen((v) => !v);
        }}
      >
        <Explain
          word={{
            id: word.id,
            label: word.label,
            variant: word.variant,
          }}
          onContextMenu={(event: any) => {
            if (isAdmin(authUser?.id)) {
              onContext(event, {
                additional_words: word.additional_words,
                audio_url: word.audio_url,
                description: word.description,
                id: word.name,
                // label: word.name,
                label: "sdfds",
                page: 1,
                transcription: word.transcription,
                translation: word.translation,
                word: word.name,
                variant: null,
              });
              // handleContextMenu(event, word);
            }
          }}
          onClick={async () => {}}
        />{" "}
      </PopoverTrigger>
      {word.name && (
        <PopoverContent
          open={open}
          className="Popover"
          style={{ boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.4)", zIndex: 999999999999 }}
        >
          {/* <PopoverHeading>My popover heading</PopoverHeading> */}
          {/* <PopoverDescription>My popover description</PopoverDescription> */}
          {!!word.additional_words?.length && (
            <>
              {word.additional_words.map((w) => {
                return (
                  <Fragment key={w.name}>
                    <Box display="flex" flexWrap="wrap" alignItems="center" position="relative">
                      <IconButton
                        sx={{
                          marglinLeft: "-5px",
                          position: "absolute",
                          left: -25,
                          top: -5,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        onClick={() => onDetailsOpen(w.name)}
                      >
                        <MoreVertIcon
                          sx={{
                            fontSize: 18,
                          }}
                        />
                      </IconButton>
                      <Typography variant="h3" fontSize={16} fontFamily="Nunito Sans">
                        {w.name}
                      </Typography>
                      <Typography
                        component="span"
                        variant="h3"
                        fontSize={14}
                        fontFamily="Nunito Sans"
                      >
                        &nbsp;
                      </Typography>
                      <Typography variant="body1" fontSize={16} fontFamily="Roboto">
                        {w.transcription}
                      </Typography>
                      <Typography
                        component="span"
                        variant="h3"
                        fontSize={16}
                        fontFamily="Nunito Sans"
                      >
                        &nbsp;
                      </Typography>
                      <UkrainianWord fontSize={16} alignLeft translation={w.translation} />
                    </Box>
                    {/* <WordTranscription word={{ ...w, audio_url: `https://storage.googleapis.com/leeearning.appspot.com/enAudio/${w.audioUrl}` } as any} play autoplay={false} small /> */}
                  </Fragment>
                );
              })}
              <hr />
            </>
          )}
          <Box>
            <Box display="flex" alignItems="center" position="relative">
              <IconButton
                sx={{
                  marglinLeft: "-5px",
                  position: "absolute",
                  left: -22,
                  top: 0,
                  height: 30,
                  width: 30,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onClick={() => onDetailsOpen(word.name)}
              >
                <MoreVertIcon
                  sx={{
                    fontSize: 18,
                  }}
                />
              </IconButton>
              <Typography variant="h3" fontSize={25} fontFamily="Nunito Sans">
                &nbsp;{word.name}
              </Typography>
            </Box>
            {/* <Typography variant="h4">{d.translation}</Typography> */}
            {/* <Translation translation={d.translation} /> */}

            <UkrainianWord alignLeft translation={word.translation || ""} />
            {/* <UkrainianWord translation={d.description} /> */}
            {/* <WordAudio url={`https://storage.googleapis.com/leeearning.appspot.com/enAudio/${d.audioUrl}`} /> */}
            {/* <WordTranscription word={{ ...d, audio_url: `https://storage.googleapis.com/leeearning.appspot.com/enAudio/${d.audio_url}` } as any} play autoplay={false} small /> */}
            {word.scriptWord && (
              <Box>
                <UkrainianWord alignLeft translation={word.scriptWord} />
              </Box>
            )}
            <Box py={2}>
              <WordTranscription word={{ ...word } as any} play autoplay={false} small />
            </Box>
            {/* <PopoverClose>Close</PopoverClose> */}
            {word.description && <Typography>{word.description}</Typography>}
            {word.transliterationSentence && word.scriptSentence && (
              <Box>
                <Button
                  // variant="contained"
                  color="info"
                  sx={{ position: "absolute", right: 0, bottom: 0 }}
                  onClick={() => {
                    navigator.clipboard.writeText(`
  1. Bengali word: ${word.name}
  2. Used in the sentence: ${word.transliterationSentence} (${word.scriptSentence})

  Question: What does this word mean in this sentence? What is the translation in this sentence?

  Response in JSON forman. !!! Strictly follow my example. Response example:
    [{
      "type": "Bengali",
      "transliteration": string (a word I have provided with diacritic marks if they are needed),
      "scriptWord": string (Bengali script version of current word),
      "en": string[] (English version of current word, all possible translations),
      "ua": string[] (Ukrainian version of current word, all possible translations),
      "script_definition": string[]
      // 1. Explain this word in different contexts including this sentence (but don't mention this sentence, I need a general explanation. It will be used for different contexts)

      // Very IMPORTANT! Please take time and think carefully how to pronounce it!!!
      "pronunciation": response type is string without "**"/"\\n" and so on!!!
          // 1. explain how to pronounce it, how to pronounce each syllable? What is the similar syllables/words in English?
          // 3. Carefully analyze the Bengali letters to understand which inherent vowels are present and which are silent.
          // 4. Always refer to the Bengali script as the primary source for pronunciation. Transliteration is just a guide, and it can be misleading.
          // 6. Break down each syllable individually
          // 7. Specify whether each consonant is followed by a vowel sound or not
          // 9. Compare sounds to common English words where possible
          // 10. Explicitly state if any sounds are silent or if syllables merge
          // 11. Don't give me examples from Spanish. I don't know Spanish. Only English!!!

      "ipa_pronunciation": string,
        // Verify the pronunciation against the Bengali script and common Bengali pronunciation rules, not just the transliteration. Transliteration is just a guide, and it can be misleading.
        // Verify the pronunciation against Bengali pronunciation rules, such as the common pattern of consonant clusters (like "pn") where inherent vowels are typically silent.
        // Use International Phonetic Alphabet (IPA) symbols for precise phonetic representation

      "part_of_speech": string[],
        // noun / pronoun / adjective / verb / adverb / preposition / conjunction / inrerjection / particles / ...

      // the next part only if the word is a verb!!!
      // if so, give this word in different cases
      conjugation: {
        "I": "?", // in one word!
        "You (formal)": "?", // in one word!
        "You (informal)": "?", // in one word!
        "You (plural formal)": "?", // in one word!
        "You (plural informal)": "?", // in one word!
        "We": "?", // in one word!
        "They": "?", // in one word!
        "He/She": "?", // in one word!
        "It": "?", // in one word!
      }
    }]


    P.S. don't give anything else. Just a json object as an example pattern. It hard to copy object from you response if it contains other information.
    P.S. Double-check your response against all my instructions before submitting.
  `);
                  }}
                >
                  ai
                </Button>
              </Box>
            )}
            {/* {!d.description && isAdmin(authUser?.id) && (
            <Button size="small" variant="outlined">

            Add description
            </Button>
          )} */}
          </Box>
        </PopoverContent>
      )}
    </Popover>
  );
};

export default HoverDetailsWord;
