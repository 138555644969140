import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Button, Drawer, Grid, IconButton } from "@mui/material";
import isAdmin from "../../../common/isAdmin";
import UkrainianWord from "../../Sentence/UkrainianWord";
import Box from "../../Unknown/Box";
import Typography from "../../Unknown/Typography";
import WordTranscription from "../../Word/WordTranscription";
import { Popover, PopoverContent, PopoverTrigger } from "../../Reading/HarryPotterChapter/Popover";
import useMountedWaitAsyncEffect from "../../../hooks/useMountedWaitAsyncEffect";
import supabaseClient from "../../../common/supabaseClient";
import { useEffect, useMemo, useState } from "react";
import {
  AdditionaWord,
  Book,
  FunctionGetWordsWithAdditionalReturn,
  HoverDetailsWords,
  Word,
} from "../../../types/supabase";
import { useLessonsStore } from "../../../store/lessonsStore";
import HoverDetailsWord from "../HoverDetailsWord";
import AdminModalChange from "../../Unknown/AdminModalChange";
import { Menu, Item, Separator, Submenu, useContextMenu } from "react-contexify";
import HStack from "../../Unknown/HStack";
import FullScreenLoader from "../../Unknown/FullScreenLoader";
import ModalCloseButton from "../../Unknown/ModalCloseButton";
import ExampleItem from "../../Reading/ExampleItem";

const MENU_ID = "blahblah";

type HoverDetailsType = {
  // name: string;
  // translation?: string | null;
  // description?: string | null;
  // additional_words?: AdditionaWord[] | null;
  isCenter?: boolean;
  words: HoverDetailsWords[];
};

const HoverDetails: React.FC<HoverDetailsType> = ({ words, isCenter }) => {
  const authUser = useLessonsStore((state) => state.authUser);
  const [isAdminModalOpen, setIsAdminModalOpen] = useState(false);
  const [wordToUpdate, setWordToUpdate] = useState<FunctionGetWordsWithAdditionalReturn | null>(
    null,
  );
  const [typeOfUpdate, setTypeOfUpdate] = useState<string>("");
  const [adminWordUpdateInitialValue, setAdminWoerUpdateInitialValue] = useState<string>("");
  const [explainWord, setExplainWord] = useState<Word | null>(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const updateExistingWord = (currentWordName: string) => {
    const existingWord = globalWords.find(
      (word) => currentWordName && word.name === currentWordName,
    );
    if (existingWord) setExplainWord(existingWord);
  };
  const setGlobalWords = useLessonsStore((state) => state.setGlobalWords);
  const globalWords = useLessonsStore((state) => state.globalWords);

  const handleChangeVariant = (event: any) => {
    const { word } = event.props;
    setWordToUpdate(word);
    setTypeOfUpdate("change-variant");
    setAdminWoerUpdateInitialValue("h3");
    setIsAdminModalOpen(true);
  };

  const handleChangeTranslation = (event: any) => {
    const { word } = event.props;
    setWordToUpdate(word);
    setTypeOfUpdate("change-translation");
    setAdminWoerUpdateInitialValue(word.translation);
    setIsAdminModalOpen(true);
  };

  const handleAddAdditional = (event: any) => {
    const { word } = event.props;
    setWordToUpdate(word);
    setTypeOfUpdate("add-additional");
    setAdminWoerUpdateInitialValue(
      word?.additional_words?.map((w: Word) => w.name)?.join(", ") || "",
    );
    setIsAdminModalOpen(true);
  };

  const handleAddDescription = (event: any) => {
    const { word } = event.props;
    setWordToUpdate(word);
    setTypeOfUpdate("add-description");
    setAdminWoerUpdateInitialValue(word.description || "");
    setIsAdminModalOpen(true);
  };

  const changeTranscription = (event: any) => {
    const { word } = event.props;
    setWordToUpdate(word);
    setTypeOfUpdate("change-transcription");
    setAdminWoerUpdateInitialValue(word.transcription || "");
    setIsAdminModalOpen(true);
  };

  const addRowExamples = (event: any) => {
    const { word } = event.props;
    setWordToUpdate(word);
    setTypeOfUpdate("add-row-examples");
    setAdminWoerUpdateInitialValue(word.row_examples || "");
    setIsAdminModalOpen(true);
  };

  const addQuestionAnswer = (word: any) => {
    setWordToUpdate(word);
    setTypeOfUpdate("add-question-answer");
    setAdminWoerUpdateInitialValue(word.question_answer || []);
    setIsAdminModalOpen(true);
  };

  const { show } = useContextMenu({
    id: MENU_ID,
  });

  function handleContextMenu(event: any, word: HoverDetailsWords) {
    console.log("🚀 ~ word:", word);
    show({
      event,
      props: {
        word,
      },
    });
  }

  const drawer = useMemo(() => {
    return (
      <Drawer
        open={isDrawerOpen}
        anchor="right"
        onClose={() => {
          setIsDrawerOpen(false);
        }}
      >
        <Box p={5} minWidth={300}>
          {isLoading && <FullScreenLoader />}
          <ModalCloseButton
            onClose={() => {
              setIsDrawerOpen(false);
            }}
          />

          {
            <>
              {explainWord && (
                <Box key={explainWord.name} mb={3}>
                  <Typography gutterBottom variant="h3" fontWeight={700}>
                    {explainWord.name}
                  </Typography>
                  <WordTranscription word={explainWord} play small autoplay={false} />

                  {/* <AdminModalChange
                    open={isAdminModalOpen}
                    onClose={() => {
                      setIsAdminModalOpen(false)
                    }}
                    currentValue={explainWord.translation}
                    onUpdate={async (newValue) => {
                      await supabaseClient.from("words").update({ translation: newValue }).eq("name", explainWord.name);
                    }}
                  > */}
                  <UkrainianWord alignLeft translation={explainWord.translation} />
                  {/* </AdminModalChange> */}
                  <Typography gutterBottom fontSize={20}>
                    {explainWord.description}
                  </Typography>
                  {explainWord.examples.map((example, index) => {
                    return (
                      <ExampleItem
                        key={index}
                        en={example.en}
                        ua={example.ua}
                        highlight={explainWord.name}
                      />
                    );
                  })}

                  {explainWord.row_examples?.split("\n").map((row, index) => {
                    return <ExampleItem key={index} en={row} ua="" highlight={explainWord.name} />;
                  })}
                </Box>
              )}
            </>
          }
        </Box>
      </Drawer>
    );
  }, [globalWords, isDrawerOpen, isLoading, explainWord]);

  const handleDetailsOpen = async (currentWordName: string) => {
    const existingWord = globalWords.find(
      (word) => currentWordName && word.name === currentWordName,
    );

    if (existingWord) {
      setExplainWord(existingWord);
      setIsDrawerOpen(true);
      // setExplainText(combineText());
    } else {
      setExplainWord(null);
      setIsLoading(true);
      setIsDrawerOpen(true);

      const { data } = await supabaseClient
        .from("words")
        .select()
        .eq("name", currentWordName)
        .single();

      if (data) {
        setExplainWord(data);
        setGlobalWords([data]);
      }
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (explainWord) updateExistingWord(explainWord.name);
  }, [globalWords, explainWord]);

  return (
    <>
      {drawer}
      {isAdmin(authUser?.id) && (
        <AdminModalChange
          open={isAdminModalOpen}
          onClose={() => {
            setIsAdminModalOpen(false);
          }}
          typeOfUpdate={typeOfUpdate}
          currentValue={
            typeof adminWordUpdateInitialValue === "string"
              ? adminWordUpdateInitialValue
              : (adminWordUpdateInitialValue as any).join(", ")
          }
          onUpdate={async (newValue) => {
            // console.log("d", d);
            if (!wordToUpdate) return;

            if (typeOfUpdate === "change-variant") {
              await supabaseClient
                .from("harry_potter_words")
                .update({ variant: newValue })
                .eq("id", wordToUpdate.id);
            } else if (typeOfUpdate === "change-translation") {
              await supabaseClient
                .from("words")
                .update({ translation: newValue })
                .eq("name", wordToUpdate.word);
            } else if (typeOfUpdate === "add-additional") {
              await supabaseClient
                .from("harry_potter_words")
                .update({ additional: newValue.split(", ") })
                .eq("id", wordToUpdate.id);
            } else if (typeOfUpdate === "add-description") {
              await supabaseClient
                .from("words")
                .update({ description: newValue })
                .eq("name", wordToUpdate.word);
            } else if (typeOfUpdate === "change-transcription") {
              await supabaseClient
                .from("words")
                .update({ transcription: newValue })
                .eq("name", wordToUpdate.word);
            } else if (typeOfUpdate === "add-row-examples") {
              await supabaseClient
                .from("words")
                .update({ row_examples: newValue })
                .eq("name", wordToUpdate.word);
            } else if (typeOfUpdate === "add-question-answer") {
              await supabaseClient
                .from("harry_potter_words")
                .update({ question_answer: newValue.split("\n") })
                .eq("id", wordToUpdate.id);
            }

            setIsAdminModalOpen(false);
          }}
        />
      )}
      {isAdmin(authUser?.id) && (
        <Menu id={MENU_ID}>
          <Item id="changeVariant" onClick={handleChangeVariant}>
            Change variant
          </Item>
          <Item id="changeTranslation" onClick={handleChangeTranslation}>
            Change translation
          </Item>
          <Item id="addAdditional" onClick={handleAddAdditional}>
            Add additional
          </Item>
          <Item id="addDescription" onClick={handleAddDescription}>
            Add description
          </Item>
          <Item id="changeTranscription" onClick={changeTranscription}>
            Change transcription
          </Item>
          <Item id="addRowExamples" onClick={addRowExamples}>
            Add row examples
          </Item>
        </Menu>
      )}
      <Grid
        container
        spacing={0}
        rowSpacing={-0.4}
        columnSpacing={0.9}
        gap={0}
        justifyContent={isCenter ? "center" : "flex-start"}
      >
        {words.map((d, index) => {
          if (d.word === "____")
            return (
              <Grid item position="relative" key={`${d.id}-${index}`} width="100%">
                <br />
                {isAdmin(authUser?.id) && (
                  <Button
                    sx={{
                      position: "absolute",
                      top: -5,
                      left: 0,
                      fontSize: 12,
                    }}
                    onClick={() => addQuestionAnswer(d)}
                  >
                    ADD Q/A
                  </Button>
                )}
                {/* <br /> */}
              </Grid>
            );

          return (
            <Grid item key={d.id}>
              <HoverDetailsWord
                key={d.id}
                onContext={handleContextMenu}
                onDetailsOpen={handleDetailsOpen}
                word={{
                  name: d.word,
                  description: d.description,
                  translation: d.translation,
                  additional_words: d.additional_words,
                  audio_url: d.audio_url,
                  transcription: d.transcription,
                  variant: d.variant,
                  label: d.label || d.word,
                  id: d.id,
                  scriptSentence: d.scriptSentence,
                  transliterationSentence: d.transliterationSentence,
                  scriptWord: d.scriptWord,
                }}
              />
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default HoverDetails;
