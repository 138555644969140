const storiesData = [
  {
    row_new_words: ["snore", "when"],
    words: ["I", "don't", "snore", "when", "sleep", "do", "you"],
    link: "(3)https://www.youtube.com/watch?v=GBee7qwyl5k",
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/3snore.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=cYNk4lM4%2B%2BCoXgJs1bL6CzVhphHuEIYt%2BTfae2yiz83PRfeOWbU8jXk7k8zEkFbLZ1S9D2ATqjrFTomAkZjiOyNa4SqxIm%2Fjn5hy%2Fcc9E2OhGNvYOXl8D1BbvZT7ZUxTr0EH9Ew7OxSTgmkzMfYwbblLR%2Fj7R35MSoVozDyUQF4b%2FrLvH4CYtZZWTVv1exR%2FhZppESlYv3ZXg0UZWF19G9gJ4xmxQqGYMoEqbGM2i8efoZ%2BVPDFuyfeRo9o5LlrUSej34vHfxIidZjTYF7RhENcdgwqNmszVso%2BtOa%2B6bC6SEXWYVKbpcockVOYvvAeZEEHmv7R3CgXgWLAbgM8r8g%3D%3D",
    id: "I don't snore when I sleep.\nI don't snore when I sleep.\nDo you snore?",
    story_id: 1183,
    word_count: 7,
    all_intersection_count: 5,
    new_words_count: 2,
  },
  {
    row_new_words: ["stretch", "arms", "morning"],
    words: ["I", "stretch", "my", "arms", "in", "the", "morning"],
    link: "https://www.youtube.com/watch?v=GBee7qwyl5k",
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/1stretch.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=CMyNVCczQaoUouufEZo2FIEimJUAFry3oRUFT2WUTFOhIJD4Iodghixd87bYOUJvHDR1VxTUTzTHNUojL%2F8BNO7a%2FtZq4tkSC%2BKCwkUge%2BAb92cQwS%2FystS7Z1ICpOK2YyKiW9SMhvNnkMzVh8qgKSDbq4vCdbR6FFkHCBZZ6cWGNNpzOkmHglXhW1n%2FJG%2FC0HBrAZxFHXG0Fx9N%2F9ji%2FQ0scYfcL22%2BIw4ADJM7baBFN5V%2F33mnLicZ4dSKqgDzB%2BTYjkaO%2B3EDvyK%2F00bSWl8esbShYmACi5aPjCBSOKX3f6y%2BfP4WW4eG5QjDL64Y2cu9M9m7QbScT2VPT9EGLg%3D%3D",
    id: "I stretch my arms I stretch my arms in the morning.",
    story_id: 1181,
    word_count: 7,
    all_intersection_count: 4,
    new_words_count: 3,
  },
  {
    row_new_words: ["ow", "hate", "mosquitoes"],
    words: ["ow", "I", "hate", "mosquitoes"],
    link: "(1)https://www.youtube.com/watch?v=dAz3UmAKvDU",
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/1thepresentsimplebob.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=b3PV2Y8aTS02qahyGlNYYaCQ5dmRBZIgoqHHAeigbpJWr8%2Bf4hrvLkSXGov4Dr9IBiOxsKIWLasvc8OLWeAXa16h%2BaE909GdlH3yI22iA3M4e1wBXECdqH3N03Z0Re68kILTtYdFTNmor3Qc3b8DaLph8zNA4O2u7iYuucyYqKb%2Bwr8cXHGO5eqTd0Kgsmd6qBRb72HVwEEKwdRj2AxSWgWMS3UGpM6Kq2LiAALK4LIgKVNtvt82II%2Bop0rwgIiLRm3qKSzLK%2BlVA0I8UZdj1QuRUMunWDQEU8GZtOAb2DfUeC68pw3liM86%2FSrNf%2FHkd53v4SJ0QzPyyQ%2FfHYXrDQ%3D%3D",
    id: "Ow. I hate mosquitoes.",
    story_id: 1287,
    word_count: 4,
    all_intersection_count: 1,
    new_words_count: 3,
  },
  {
    row_new_words: ["off", "winter", "when", "means"],
    words: [
      "so",
      "I",
      "have",
      "a",
      "day",
      "off",
      "today",
      "it's",
      "beautiful",
      "winter",
      "and",
      "in",
      "English",
      "when",
      "you",
      "say",
      "it",
      "means",
      "don't",
      "to",
      "go",
      "work",
      "that",
    ],
    link: "(1)https://www.youtube.com/watch?v=yW-YeEc_ULg&list=PLQut38RVINETJDG9hzlMPp62gDRCPKmrH&index=21",
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/1dayoffbob.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=byuTqmMHNCYMZ2TfDUkmuzR3EMi5ELQ%2FyNsMGf%2BF4l1spnAPwIGJ%2BLbThzMzEKOu2d%2F5EiMOMGC7Bmhx%2FPCRXEGtQFJitkQQu1QpW5y8IFxYX8de5VXUWjNLumW%2BItKdCZejS8Ei0P3uRK%2F6asyQ%2Fkt%2FheXXiKOS%2B44wi%2FEGFLBoflWD2%2FqZxsbw4hLoCrvRcKG7KwyEhWlbgwjkW3%2BUmNWigF6wESohmxlpSRYNcoP%2BCykHryrBztKm5VEsTnTFcYlnvI4whgkZxwX%2BJq3E%2FB37OQsWTiLLKNQUgqCWqgPJlKRwhtqbUJuRO4kRGXrKy0rbK8J6k%2FBX6Bgfw6A1WA%3D%3D",
    id: "So I have a day off today. It's a beautiful winter day, and I have a day off. In English, when you say you have a day off, it means you don't have to go to work that day.",
    story_id: 1391,
    word_count: 23,
    all_intersection_count: 19,
    new_words_count: 4,
  },
  {
    row_new_words: ["crying", "because", "sad", "cry", "when"],
    words: ["why", "are", "you", "crying", "I'm", "because", "sad", "I", "always", "cry", "when"],
    link: "https://www.youtube.com/watch?v=vaq6pX7q15s&list=PL31D7CB49ABAAB4DB&index=18",
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/crying.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=BLaDMKyNo%2BQQaC4oXUk9%2FnIo23du4JfzOeORs2KJQ95TXht7BH8xxm20c%2BhdMLBr9%2FM8HSW4cprZ5ihSWR%2FiLbFR3SYNlb%2FowQhpttxC6FyDMr3QxPxdDNYwPoA627OkDnRXpK6fMSl%2FN%2BLr6j%2FUA6cmFwgdhbMeSgZahuJU0t8L8hec57FJHIXhf%2B79fkxG26s8eQHB4MwJN7ZzyiuP3KuoW%2F%2B6DotjveJHBk4e8wR76xZV9yDtrfWxH792wReXmD%2FpvVFpnQZl1E2ryWxLJ%2F5GbJkXdE28Z68K5iY0tCcPpgEJ%2BPxruHnrgUgrHo%2F0Frr9%2F%2FQSWOYp3JDQdx82jg%3D%3D",
    id: "Why are you crying?\nI'm crying because I'm sad.\nI always cry when I sad.",
    story_id: 990,
    word_count: 11,
    all_intersection_count: 6,
    new_words_count: 5,
  },
  {
    row_new_words: ["problem", "heart", "unusual", "heartbeat", "oh"],
    words: [
      "doctor",
      "do",
      "I",
      "have",
      "a",
      "problem",
      "with",
      "my",
      "heart",
      "yes",
      "you",
      "very",
      "unusual",
      "heartbeat",
      "oh",
    ],
    link: "https://www.youtube.com/watch?v=fvRabsiahk4&list=PL31D7CB49ABAAB4DB&index=17",
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/problem.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=ImcigZiQWvZJ0Gs4yFExh2eTzIq7dkKL32MZobnGUoc50WnVVCbW1pKZ3uM5KSM0gzARs84DceSv1peyUr7qVa3ShxMKy8C%2FIC0lgefzwEmsz1dfEexrxlVcbn1WTgLLaTAG7LH38qTeWGE48Jt212H32uixVJesdbMXjRQ7SANajcc0qcPFx6xuITGJ6LGSbsU8OWB4dVlGhePYtXL08UfTsLhIJ3o2LGMRlpNrpk1d8VurBlH%2FgBtskhyO%2FqmmQ1llQBhAcdEB9qZiieqWo7ge44JuE6n0qT2tA7LfVL33M16arVlP%2BWW8WfOKEtaeTUNupoNhoNg1VnriDOE33Q%3D%3D",
    id: "Doctor, do I have a problem with my heart?\nYes. You have a very unusual heartbeat.\nOh, my.",
    story_id: 988,
    word_count: 15,
    all_intersection_count: 10,
    new_words_count: 5,
  },
  {
    row_new_words: ["will", "moon", "shine", "even", "when", "whole"],
    words: [
      "I",
      "will",
      "be",
      "like",
      "the",
      "moon",
      "and",
      "learn",
      "to",
      "shine",
      "even",
      "when",
      "am",
      "not",
      "whole",
    ],
    link: "https://www.familyfriendpoems.com/poem/like-the-moon",
    storage_link: null,
    id: "I will be\nlike the moon\nand learn to shine\neven when\nI am not whole.",
    story_id: 916,
    word_count: 15,
    all_intersection_count: 9,
    new_words_count: 6,
  },
  {
    row_new_words: ["look", "wear", "prison", "jumpsuit", "careful", "will", "saying"],
    words: [
      "you",
      "look",
      "like",
      "know",
      "what",
      "they",
      "wear",
      "in",
      "prison",
      "with",
      "the",
      "orange",
      "jumpsuit",
      "be",
      "careful",
      "please",
      "I",
      "will",
      "but",
      "I'm",
      "just",
      "saying",
    ],
    link: "https://www.youtube.com/watch?v=QrFlNEEpXsM",
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/lion.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=CPTRe1wk%2BaA3Q3A9mui%2FEnLLsm3uJfV6E%2FgDXmKVqVY2fM5Q0djAJWGqEm9vMgHcUsPJxpU4otdJdECI6Eto61taX2sjg%2BsyIX1eNv0%2FkR%2FFELGH1Zfu3%2F71SDUPGaYDxck1TPQLNJPwiMLKtvFvnHwuVU0yDaIf5MCx3%2BPwxhB1rX7XA8h072%2B%2BbYN%2BP46aZWpMPHu4i5r%2Bo1CN7vvW%2BKprCPQA%2B3MdMvSOlQrTUFr1IyjmDloIA7HEl%2FFxGSql5TihJzRNhXqAnY0L495DvP1Pvh3WehVg5e%2BN%2FUUgmyI0azIvS5F34%2B8C9eCDYJAOLVRzcBOu34pL7812AKkRww%3D%3D",
    id: "You look like, you know what they wear in prison.\nWith the orange jumpsuit.\nBe careful please.\nI will be careful, but I'm just saying you look like what they wear.",
    story_id: 1034,
    word_count: 22,
    all_intersection_count: 15,
    new_words_count: 7,
  },
  {
    row_new_words: ["talk", "stop", "train", "chat", "make", "call", "plane"],
    words: [
      "we",
      "can't",
      "talk",
      "now",
      "I",
      "have",
      "to",
      "go",
      "work",
      "stop",
      "catch",
      "a",
      "train",
      "chat",
      "make",
      "call",
      "plane",
    ],
    link: "https://www.youtube.com/watch?v=9ozhdznI3xU&list=PL31D7CB49ABAAB4DB&index=21",
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/can.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=grC7BkY%2Fq2JfpUCfLSd7yZddugA2aST93kMXeTXg9nmWbehku8Q2bzwH2mtlVPBs1%2FeDZ9uA%2BOdt6HTIZBygLDpWViG2YQ7qfEYd1SQD2x9AYx6wdx7kp6EpZAK3csUeXAE%2FGv98E0SAAERW5FyAf6O%2BtqVd7DI8hr3DnQ4BAE%2B6yPbx%2BJdsQqkTgBDmNhKhCVJk71oBY1jQEc0g9dTzm0G7ZhtP%2Fw0SpEIXVwNA4Hv4hsC6auuUW3RgikLKOs7owdJ58X7AnofxEqIN7AotRZeNIBhUN3%2Bw%2FZS0ZaVW0YVzdvwiHbHRTfh%2BSF3d0gX2z7uG9r2Z4NZckQ6ZlUNJgA%3D%3D",
    id: "We can't talk now.\nWe can't talk now.\nWe can't talk now.\nWe can't talk now.\nI can't talk now.\nI have to go to work.\nI can't stop now.\nI have to catch a train.\nI can't chat now.\nI have to make a call.\nI can't stop now.\nI have to catch a plane.\nWe can't talk now.\nWe have to go to work.\nWe can't stop now.\nWe have to catch a train.\nWe can't chat now.\nWe have to make a call.\nWe can't stop now.\nWe have to catch a plane.\nWe can't talk now.\nWe can't talk now.\nWe can't talk now.\nWe can't talk now.",
    story_id: 1002,
    word_count: 17,
    all_intersection_count: 10,
    new_words_count: 7,
  },
  {
    row_new_words: ["agree", "someone", "nod", "disagree", "shake", "head", "means"],
    words: [
      "if",
      "you",
      "agree",
      "with",
      "someone",
      "can",
      "nod",
      "and",
      "disagree",
      "shake",
      "your",
      "head",
      "a",
      "or",
      "to",
      "means",
      "yes",
      "it",
      "no",
    ],
    link: "(2)https://www.youtube.com/watch?v=vA-uEPEHU_M&list=PLQut38RVINERTw9It9-fkKY8jgNEJ5AHC",
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/agree.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=H%2FxqWnsXXqgVlwRyDofX2x4ie9zp5s8Tz5pdibG2HlUqTdhrpcXudcWU6rAtmfKlZAd5LjnTfHQG6MQh8QGKddpxCvGYFkiZtdidG23XJZKHi8xhaxpZ1Mb%2BOxNgbWjzwRmkzQd6HbY6JhdPr%2FM65i%2FeCvZt4CyektkoQX8d6q5sfFQ9sjVtzR6Y4WbnrXo08foaB0H%2FJdOJ1MN7lRaJ7M5tclrkTGKlUGQot8bWukQYLfFcEsIM3JyVdgjo4JpwlY5dmh5%2Bpu1Y86Tk18wm8lkiMPO1EwToCG6eQn%2Bgq8oa7scYxxzjFH0lpMTjfgrBvF%2FsK4rzg0Ta04aQ8q3LhQ%3D%3D",
    id: "If you agree with someone, you can nod. And if you disagree with someone, you can shake your head. A nod or to nod means yes. And if you shake your head, it means no.",
    story_id: 1113,
    word_count: 19,
    all_intersection_count: 12,
    new_words_count: 7,
  },
  {
    row_new_words: ["sing", "luckiest", "world", "how's", "feelings", "nothing", "more", "than"],
    words: [
      "can",
      "you",
      "sing",
      "yes",
      "I",
      "people",
      "who",
      "need",
      "are",
      "the",
      "luckiest",
      "in",
      "world",
      "how's",
      "that",
      "very",
      "nice",
      "no",
      "can't",
      "feelings",
      "nothing",
      "more",
      "than",
      "see",
      "sorry",
    ],
    link: "https://www.youtube.com/watch?v=RPDHqfX4PZQ&list=PL31D7CB49ABAAB4DB&index=20",
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/sing.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=T9NC5TnU4ZCl%2FHF8Vp3XPuV3YB77g66LsTVU9D7qk6RwOMM5cPDw81SW9ZVhKCipDFANOxTgvupSJfA5iELGorvwvy%2F5yf8pk2ZJCQoIFPwZPAlmkLMe8SbhwLE5zYu5aeyVwTexkjYg6EfVqIl3USb5p29d%2BntKinfZzvO506KJdvl4qdJbmD4d%2BsS3NpBX%2Bo5OZgFouh%2BImW155fZeNdyfGA3Ab9qnasWRTR4WptZOG5KIKpDqv0oiv980T2GUihwgUQdUh3pXnGpq9a7Eseomrj5FedKKvRSJIbfcYnSbB5zmZY4OMoRmiUSS4Bmh%2FfPY7p3GKPNmF9B%2BMgaSlA%3D%3D",
    id: "Can you sing?\nYes. I can. \n\nPeople,\npeople who need \npeople\nare the luckiest people in the world.\n\nHow's that?\nVery nice.\n\nCan you sing?\nNo, I can't.\n\nFeelings, nothing more than feelings.\n\nYou see, I can't sing. Sorry.",
    story_id: 998,
    word_count: 25,
    all_intersection_count: 17,
    new_words_count: 8,
  },
  {
    row_new_words: ["mom", "dad", "living", "channel", "7", "mom's", "dad's", "charlie's"],
    words: [
      "where",
      "are",
      "mom",
      "and",
      "dad",
      "they're",
      "in",
      "the",
      "living",
      "room",
      "what",
      "they",
      "doing",
      "watching",
      "channel",
      "7",
      "Betty's",
      "bedroom",
      "mom's",
      "dad's",
      "charlie's",
      "kitchen",
      "where's",
      "Charlie",
      "he's",
      "what's",
      "he",
      "eating",
      "lunch",
    ],
    link: "(3)https://www.youtube.com/watch?v=Ay2D8chFBKw&t=50s",
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/Present%20Continuous.%20Question%20form%203.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=t4djn%2BTZ3bRFDLhC2yx0UfjrX58Z54yr4k8PuVfjonU4xn837lDA14KquFEsGvUl9uE9JKO39pTF%2FuDCiTOxE4ACbN4mJ5prNEn4RRxgH81Q9Fs4ZthG2JQuf95YCfemOQ3Gv63H%2BHvsb%2FRvu4Skl6Dyg2UtOkFhKeuTuzlNYklQFLS9VjkxtWA%2FSfi3bc68xIUH1iQfVrNyx%2BfC279iLmz5vbKG8wUgzyT71Vvjap4moouNYhlriNHKjcyjgD3eXvCUU4Ih%2Bmtxir7CFaIP2OgNI6BNY0rsjWk1suMDFE00blvOGL2WkovzKhidetP2bGc5oCC7TakiNe2c3p%2FEew%3D%3D",
    id: "Where are mom and dad?\nThey're in the living room.\nWhat are they doing?\nWatching channel 7.\nBetty's in the bedroom.\nMom's in the living room.\nDad's in the living room.\nCharlie's in the kitchen.\nWhere's Charlie?\nHe's in the kitchen.\nWhat's he doing? Eating lunch.",
    story_id: 956,
    word_count: 29,
    all_intersection_count: 21,
    new_words_count: 8,
  },
  {
    row_new_words: [
      "glow",
      "fireplace",
      "fills",
      "Christmas",
      "cheer",
      "family",
      "raise",
      "wishes",
      "year",
    ],
    words: [
      "the",
      "orange",
      "glow",
      "of",
      "fireplace",
      "fills",
      "room",
      "with",
      "Christmas",
      "cheer",
      "to",
      "my",
      "family",
      "I",
      "raise",
      "glass",
      "full",
      "wishes",
      "for",
      "year",
    ],
    link: "https://www.familyfriendpoems.com/poem/wishes-6",
    storage_link: null,
    id: "The orange glow of the fireplace\nfills the room with Christmas cheer.\nTo my family I raise my glass,\nfull of wishes for the year.",
    story_id: 924,
    word_count: 20,
    all_intersection_count: 11,
    new_words_count: 9,
  },
  {
    row_new_words: [
      "yawn",
      "when",
      "sleepy",
      "polite",
      "cover",
      "mouth",
      "this",
      "same",
      "country",
    ],
    words: [
      "I",
      "yawn",
      "when",
      "I'm",
      "sleepy",
      "in",
      "the",
      "us",
      "it's",
      "polite",
      "to",
      "cover",
      "your",
      "mouth",
      "you",
      "is",
      "this",
      "same",
      "country",
    ],
    link: "(2)https://www.youtube.com/watch?v=GBee7qwyl5k",
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/2yawn.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=Z9BkF1mk43LRJjWfhQ%2BlMEWWvCn%2F23a1tjyWzLOaaRe5McEapBCJ24K5Thj1iAAB%2Bi3XIMql%2BADbLl7G6nLXmD5v2Gq5lMXJEGwafEg4ghaS%2FHKityyLmg4XkjJgzFLmVL77h0PURDkQo0p6eV1luEPBTZedkAt2rJVJZJklN1sW2d%2Btz7ZjMkdHPUixud63J2lgWyA0Hg1AdODGd%2BqwaC71xwFJHj95Ccn09w%2FVJ1Y4Bw4g1Mf7m2gleIHvBzI7vOum14D2knD78nWGYVH%2FcWCbkBYBrQUm0cpO4n11l%2BlnBd2wJqW76dVdid9s3PN6HNGnlJcL1wZS%2Fb6wHTzeHw%3D%3D",
    id: "I yawn when I'm sleepy. I yawn when I'm sleepy. In the US, it's polite to cover your mouth when you yawn. Is this the same in your country?",
    story_id: 1182,
    word_count: 19,
    all_intersection_count: 10,
    new_words_count: 9,
  },
  {
    row_new_words: ["autumn", "proof", "change", "thing", "even", "though", "hard", "at", "first"],
    words: [
      "let",
      "autumn",
      "be",
      "proof",
      "that",
      "change",
      "is",
      "a",
      "beautiful",
      "thing",
      "even",
      "though",
      "it",
      "can",
      "hard",
      "to",
      "see",
      "at",
      "first",
    ],
    link: "https://www.familyfriendpoems.com/poem/change-is-a-beautiful-thing",
    storage_link: null,
    id: "let autumn be proof that\nchange is a beautiful thing\neven though it can be\nhard to see at first.",
    story_id: 932,
    word_count: 19,
    all_intersection_count: 10,
    new_words_count: 9,
  },
  {
    row_new_words: [
      "should",
      "bicycle",
      "motorcycle",
      "bicycles",
      "safer",
      "than",
      "motorcycles",
      "probably",
      "right",
    ],
    words: [
      "should",
      "I",
      "buy",
      "a",
      "bicycle",
      "or",
      "motorcycle",
      "think",
      "you",
      "why",
      "bicycles",
      "are",
      "safer",
      "than",
      "motorcycles",
      "you're",
      "probably",
      "right",
    ],
    link: "https://www.youtube.com/watch?v=1dW7V33qLwA&list=PLD7AA7B1BC72ACC28&index=10",
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/should.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=qvSvHK9xJuw3cC9Pu8Sx2V%2Bzowjr%2BRF6H6kN9zuhTVdPVvx9jzLh7y0zEAfLGtAl8KtLgJ5UJriMv7jLtGfNqpjQnKL0DifdO8xX2iSH4ujjQBqHDWT0IBvHRXGONYtT%2BrwNtxCzMpUODGwFPuIcBQONrGbutXbHS7uObFMzbcc2gFXfdQTsq5Zk7ZNZrPEMQbqaUoI0Zr0RHNCznOftK3tvl0r%2FM%2BzIuqb%2BMFzB43vpt3JaPA%2Bwq2P0Fj6o0HPCWEJvi1KoPTtO4qucUU006Rrgo253HLCpMG6jpHitb0GJJdWn%2FmCd7r3nlvRlIUOlIYG%2Fnp02s6Chfk0btgrkEw%3D%3D",
    id: "Should I buy a bicycle or a motorcycle?\nI think you should buy a bicycle.\nWhy?\nBicycles are safer than motorcycles.\nYou're probably right.",
    story_id: 1040,
    word_count: 18,
    all_intersection_count: 9,
    new_words_count: 9,
  },
  {
    row_new_words: [
      "cannot",
      "slow",
      "setting",
      "sun",
      "only",
      "choose",
      "how",
      "spend",
      "time",
      "light",
    ],
    words: [
      "you",
      "cannot",
      "slow",
      "the",
      "setting",
      "of",
      "sun",
      "can",
      "only",
      "choose",
      "how",
      "to",
      "spend",
      "your",
      "time",
      "in",
      "light",
    ],
    link: "https://www.familyfriendpoems.com/poem/choose-how-you-spend-your-time",
    storage_link: null,
    id: "You cannot\nslow the setting\nof the sun.\nYou can only choose\nhow to spend\nyour time\nin the light.",
    story_id: 915,
    word_count: 17,
    all_intersection_count: 7,
    new_words_count: 10,
  },
  {
    row_new_words: [
      "enough",
      "been",
      "will",
      "forever",
      "matter",
      "how",
      "feel",
      "lies",
      "may",
      "believe",
    ],
    words: [
      "you",
      "are",
      "enough",
      "have",
      "always",
      "been",
      "and",
      "will",
      "forever",
      "be",
      "no",
      "matter",
      "what",
      "see",
      "how",
      "feel",
      "or",
      "the",
      "lies",
      "may",
      "believe",
    ],
    link: "https://www.familyfriendpoems.com/poem/you-are-enough",
    storage_link: null,
    id: "You are enough\nYou have always been enough, and\nYou will forever be\nEnough\n\nno matter what you see\nhow you feel, or the lies you may\nbelieve",
    story_id: 927,
    word_count: 21,
    all_intersection_count: 11,
    new_words_count: 10,
  },
  {
    row_new_words: [
      "Bob",
      "cooking",
      "spaghetti",
      "Wednesday",
      "cooks",
      "Gary",
      "bathing",
      "his",
      "Friday",
      "bathes",
    ],
    words: [
      "what's",
      "Bob",
      "doing",
      "he's",
      "cooking",
      "spaghetti",
      "why",
      "is",
      "he",
      "that",
      "it's",
      "Wednesday",
      "always",
      "cooks",
      "on",
      "Gary",
      "bathing",
      "his",
      "cat",
      "Friday",
      "bathes",
    ],
    link: "(3)https://www.youtube.com/watch?v=SzL6Ww7xUWc&list=PL31D7CB49ABAAB4DB&index=19",
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/doing.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=YguFniex%2FpYXNP%2BSIjTtml0QLiRbQ7LHo9E0%2B3Gj%2FNO37vqvK%2BVMVHHd3G5xPUEAm5jj3KyvGIdqgULJahErHGHFxvyNXCXoKiitCzcme0Vkww%2FnE%2Fme1TW422cQziczNV1xs%2Bv7toHiwMx6MzdrWRPrGWw6rLDGX1D%2F5SJWmR%2BY9w8HBaPNoIbxoLTYAwh76yeDNUQC%2F89djjst%2Fx24zTvpt1EdskUKjbpqWZOUFJI5%2BhTLkkiP3CELiShZTai5pB6XIAulWBkhIrmx8jsawTCoZBkTf2QuTtSda0bpBd2ilaJq4%2BulrtZZSToYYbLxuu89qluTEQFIzsDQDkNCNQ%3D%3D",
    id: "What's Bob doing?\nHe's cooking spaghetti.\nCooking spaghetti? Why is he doing that?\nIt's Wednesday. He always cooks spaghetti on Wednesday.\n\nWhat's Gary doing?\nHe's bathing his cat.\nBathing his cat?\nWhy is he doing that?\nIt's Friday. He always bathes his cat on Friday.",
    story_id: 997,
    word_count: 21,
    all_intersection_count: 11,
    new_words_count: 10,
  },
  {
    row_new_words: [
      "present",
      "continuous",
      "use",
      "sentences",
      "this",
      "making",
      "video",
      "teaching",
      "speaking",
      "listening",
    ],
    words: [
      "present",
      "continuous",
      "that's",
      "where",
      "you",
      "use",
      "sentences",
      "like",
      "this",
      "I",
      "am",
      "making",
      "a",
      "video",
      "are",
      "watching",
      "teaching",
      "English",
      "learning",
      "speaking",
      "listening",
    ],
    link: "(2)https://www.youtube.com/watch?v=rFdhrR6Dpco",
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/continuous.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=nNpHuMx8L9rGfPLEUZIrLYUh%2BBk8TY0GJHFsJxGYLhmqU4NrOGLJhLd7g3qJRSsS4ObZgX5wBuBEa26O2Zmkcjnc6KDAUIMRGGdsXYCAqgEoTvcGJTK3YuylAoQHQ74VC8Q0T65x8Df8iH1dSFG5NvcRov9vyBIDF0qlsvqurfpA9R%2FmlM3L3ukwDwsAhe1O8Uh4lfNGzlYu9e5O6t1Ls437Tn5pGiU3QQDUOdY%2B%2B1qxbYlsnE8D4Lv%2FhxJjS45zhTcfyu2rpRVtY3jUPMbmYF71i3PnKSyYmJZAyQAYxAN9ampN6WAA7F1tOx84G1ualeTisb7KT5ary5Rq3%2BH9WA%3D%3D",
    id: "Present continuous.\n\nThat's where you use sentences like this:\nI am making a video.\nYou are watching a video.\nI am teaching you English.\nYou are learning English.\nI am speaking.\nYou are listening.",
    story_id: 1033,
    word_count: 21,
    all_intersection_count: 11,
    new_words_count: 10,
  },
  {
    row_new_words: [
      "washing",
      "car",
      "wash",
      "often",
      "washed",
      "yesterday",
      "gonna",
      "tomorrow",
      "really",
      "sure",
    ],
    words: [
      "I",
      "see",
      "you're",
      "washing",
      "your",
      "car",
      "yes",
      "am",
      "do",
      "you",
      "wash",
      "it",
      "very",
      "often",
      "washed",
      "yesterday",
      "I'm",
      "now",
      "and",
      "gonna",
      "tomorrow",
      "really",
      "like",
      "to",
      "sure",
      "every",
      "day",
    ],
    link: "https://www.youtube.com/watch?v=w24IRWsCL48&list=PLD7AA7B1BC72ACC28",
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/washing-1.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=jXQWqVbylqSFLbOBYZrQ0rXJyWtYW9K%2BD5hAGr%2FHJ9%2BgseT2aejDb7s6FLx1RG4n590mrXRZw7eYPF1U2VW%2BNgDOzMGUjjEkqkX%2BA%2FunxoNJLgAwSC7k%2BlAR1wymOuQQ4XfN22Dr6YrmxUqwsoFAF0rhscXF6XGFZkf2x5hdZAMMBLZr31Bf9ivqNGAZZ6g4QiYfPd%2Fs2K2atW83%2B7sjdj5H9ud47%2ByjheHW06casNKirM5WBDYDh3PvMfH0SsviFbaMmO6Q6rY4CwXxuH8id5oHMWSnQWc23iFGzEGigXIadb6FFlGb1hEnmiqdc%2Fpj6pJbRexvAPU9tr1UC3UMaQ%3D%3D",
    id: "I see you're washing your car.\nYes. I am.\nDo you wash it very often?\nYes. I washed it yesterday. I'm washing it now, and I'm gonna wash it tomorrow.\nYou really like to wash your car.\nI sure do. I wash it every day.",
    story_id: 1011,
    word_count: 27,
    all_intersection_count: 17,
    new_words_count: 10,
  },
  {
    row_new_words: [
      "despite",
      "all",
      "cannot",
      "bring",
      "myself",
      "regret",
      "single",
      "moment",
      "spent",
      "loving",
    ],
    words: [
      "and",
      "despite",
      "it",
      "all",
      "I",
      "cannot",
      "bring",
      "myself",
      "to",
      "regret",
      "a",
      "single",
      "moment",
      "spent",
      "loving",
      "you",
    ],
    link: "https://www.familyfriendpoems.com/poem/despite-it-all",
    storage_link: null,
    id: "And despite\nit all,\nI cannot\nbring myself\nto regret\na single\nmoment\nI spent\nloving you.",
    story_id: 930,
    word_count: 16,
    all_intersection_count: 6,
    new_words_count: 10,
  },
  {
    row_new_words: [
      "washing",
      "dishes",
      "bathtub",
      "strange",
      "usually",
      "wash",
      "never",
      "because",
      "sink",
      "broken",
    ],
    words: [
      "what",
      "are",
      "you",
      "doing",
      "I'm",
      "washing",
      "the",
      "dishes",
      "in",
      "bathtub",
      "that's",
      "strange",
      "do",
      "usually",
      "wash",
      "no",
      "I",
      "never",
      "but",
      "today",
      "why",
      "that",
      "because",
      "my",
      "sink",
      "is",
      "broken",
      "sorry",
      "to",
      "hear",
    ],
    link: "https://www.youtube.com/watch?v=SzL6Ww7xUWc&list=PL31D7CB49ABAAB4DB&index=19",
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/washing.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=x163sDjtjuNLWRzg1zFNZ7uiVFkW6%2BiX23sB%2FPRbQ3PMEUT3I8I%2FDghNZTpz%2F%2B97c322JbNaUc2iarz6edmVeHPZx0s86afRzwSSTNpthCzdTxvCLrXmqeVAoCwYy2774BL9Q4FbvNQqpqhAHuB7NnoJggtsFG7K2d4AAOOZPsxQJ4FWOK0qY0FLOb7LaHoEVZCIJxNGjg79a5xOtooIVXCWU5XyoJJyHG4KJsgR4vlnQ4Pg8uqLBPBxH4A5IiXp9SeHQsLd7DommxfeQFJcUsAQ94iqsLQyyuWhv6AZtNOjrKrkA4E%2BUgSOP4P%2B0fJF1WDgBkfJeBNDnyVEzU%2BkhQ%3D%3D",
    id: "What are you doing?\nI'm washing the dishes in the bathtub.\nThat's strange. Do you usually wash the dishes in the bathtub?\nNo. I never wash the dishes in the bathtub, but I'm washing the dishes in the bathtub today.\nWhy are you doing that?\nBecause my sink is broken.\nI'm sorry to hear that.",
    story_id: 995,
    word_count: 30,
    all_intersection_count: 20,
    new_words_count: 10,
  },
  {
    row_new_words: [
      "paint",
      "draw",
      "sing",
      "little",
      "these",
      "things",
      "out",
      "night",
      "homework",
      "because",
      "write",
    ],
    words: [
      "I",
      "can",
      "paint",
      "and",
      "draw",
      "dance",
      "sing",
      "speak",
      "a",
      "little",
      "English",
      "but",
      "my",
      "cat",
      "can't",
      "do",
      "these",
      "things",
      "he",
      "sleep",
      "eat",
      "go",
      "out",
      "every",
      "night",
      "homework",
      "because",
      "read",
      "or",
      "write",
    ],
    link: "https://learnenglishkids.britishcouncil.org/grammar-vocabulary/grammar-chants/i-can-paint",
    storage_link: null,
    id: "I can paint and I can draw\nI can dance and I can sing\nI can speak a little English\nBut my cat can't do these things.\n\nHe can sleep and he can eat\nHe can go out every night\nBut he can't do my homework\nBecause he can't read or write.",
    story_id: 822,
    word_count: 30,
    all_intersection_count: 19,
    new_words_count: 11,
  },
  {
    row_new_words: [
      "loving",
      "school's",
      "team",
      "after",
      "even",
      "bathroom",
      "anyone",
      "passionate",
      "best",
      "dancer",
      "world",
    ],
    words: [
      "loving",
      "dance",
      "she",
      "loves",
      "to",
      "dances",
      "on",
      "her",
      "school's",
      "team",
      "after",
      "school",
      "in",
      "room",
      "the",
      "kitchen",
      "even",
      "bathroom",
      "anyone",
      "can",
      "see",
      "that",
      "is",
      "passionate",
      "people",
      "say",
      "best",
      "dancer",
      "world",
    ],
    link: "https://www.eslfast.com/kidsenglish/ke/ke044.htm",
    storage_link: null,
    id: "Loving dance\n\nShe loves to dance. She dances on her school's team. She dances after school. She loves to dance in her room. She loves to dance in the kitchen. She even loves to dance in the bathroom. Anyone can see that she is passionate. People say she is the best dancer in the world.",
    story_id: 360,
    word_count: 29,
    all_intersection_count: 18,
    new_words_count: 11,
  },
  {
    row_new_words: [
      "you'll",
      "bicycle",
      "liked",
      "old",
      "was",
      "fast",
      "right",
      "faster",
      "apartment",
      "large",
      "larger",
    ],
    words: [
      "I",
      "think",
      "you'll",
      "like",
      "my",
      "new",
      "bicycle",
      "liked",
      "your",
      "old",
      "it",
      "was",
      "fast",
      "that's",
      "right",
      "but",
      "is",
      "faster",
      "apartment",
      "large",
      "larger",
    ],
    link: "(3)https://www.youtube.com/watch?v=GSx9o9uZosQ&list=PLD7AA7B1BC72ACC28&index=9",
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/was1.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=Geq0KZXDpgBOupSib7TNDnHcJPYaCP9wHPBRhLopcTTy%2FFUs5qyi3JnJ83XLTk1O3fne2VpAD4jppCFZKe1v5ZeF%2F1uUK581hZb0H5L4Zvhb5vb6wf1aWxvOxCtLsH39%2F%2FmQ5lHTRgtkNAhKodPn8UPFRSLzTNXpGBX0CL7kYEHGI52eCLM9%2BKdtPHuYGX50i%2FNGZk4ulJegkjjgAZ6ub7ANDkgxYprdbEwD4PQz%2F7IbPPN34iLYvy3NYq6bNsTTiSTTlz4YSIbAL47FZIgrueDgytteM9wLtdV4mZ2VQuLv3XneQqap4kU%2FBF9SEYt7IOU7WNCNlQAKPM2YkFAbeg%3D%3D",
    id: "I think you'll like my new bicycle.\nI liked your old bicycle. It was fast.\nThat's right. But my new bicycle is faster.\n\nI think you'll like my new apartment.\nBut I liked your old apartment. It was large.\nThat's right. But my new apartment is larger.",
    story_id: 1047,
    word_count: 21,
    all_intersection_count: 10,
    new_words_count: 11,
  },
  {
    row_new_words: [
      "goes",
      "sees",
      "roars",
      "long",
      "trunk",
      "turtle",
      "slow",
      "rabbit",
      "soft",
      "fur",
      "gorilla",
    ],
    words: [
      "going",
      "to",
      "the",
      "zoo",
      "she",
      "goes",
      "sees",
      "a",
      "lion",
      "roars",
      "an",
      "elephant",
      "has",
      "long",
      "trunk",
      "turtle",
      "is",
      "slow",
      "rabbit",
      "soft",
      "fur",
      "gorilla",
      "eating",
      "banana",
    ],
    link: "https://www.eslfast.com/kidsenglish/ke/ke001.htm",
    storage_link: null,
    id: "Going to the zoo\n\nShe goes to the zoo. She sees a lion. The lion roars. She sees an elephant. The elephant has a long trunk. She sees a turtle. The turtle is slow. She sees a rabbit. The rabbit has soft fur. She sees a gorilla. The gorilla is eating a banana.",
    story_id: 324,
    word_count: 24,
    all_intersection_count: 13,
    new_words_count: 11,
  },
  {
    row_new_words: [
      "hungarian",
      "romanian",
      "languages",
      "chinese",
      "Spanish",
      "japanese",
      "korean",
      "afraid",
      "portuguese",
      "anything",
      "laryngitis",
    ],
    words: [
      "can",
      "you",
      "speak",
      "hungarian",
      "no",
      "I",
      "can't",
      "but",
      "romanian",
      "what",
      "languages",
      "English",
      "and",
      "chinese",
      "Spanish",
      "japanese",
      "korean",
      "I'm",
      "afraid",
      "portuguese",
      "anything",
      "today",
      "have",
      "laryngitis",
      "sorry",
    ],
    link: "(3)https://www.youtube.com/watch?v=RPDHqfX4PZQ&list=PL31D7CB49ABAAB4DB&index=20",
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/speak.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=TnUpaM8NKRFO7%2FWKjySpg041bL6fWksiCvSuM0hq4j30N7xCabq08YhEtajzCra5S3R6AuODU56QZBKjR1WLo4dx9kkRMlmaXSto%2B8eepOgC%2FFphqB7Fgepo%2FRnGyEdiNN%2FfS%2FNfJJ%2FU3nH3rKn8ymhS0mQ0VFscVSgBkRDfSnXckWWcpssp9psrCOm40pE0%2BUoovKCfRHRNcdCoJa4H9Q47Djl0V7RXjynu4jE34ZB3omY7oXR4cRoZFZ9pkpEAAckgmzmQN%2Fc5Nbq65b9Ximasz%2B0O%2Fy3zd8N4XaxbRnEZmzMuAbyTobUbyCWs%2B%2FwxtB3ta6Vgr74%2Fjupj8oV5gw%3D%3D",
    id: "Can you speak Hungarian?\nNo, I can't. But I can speak Romanian.\n\nWhat languages can you speak?\nI can speak English and Chinese.\n\nI can't speak Chinese, but I can speak Spanish.\n\nI can't speak Spanish, but I can speak Japanese.\n\nJapanese? No. I can't speak Japanese, but I can speak Korean.\n\nNo. I'm afraid I can't speak Korean, but I can speak Portuguese.\n\nI can't speak anything today. I have laryngitis. Sorry.",
    story_id: 1000,
    word_count: 25,
    all_intersection_count: 14,
    new_words_count: 11,
  },
  {
    row_new_words: [
      "treats",
      "children",
      "takes",
      "asks",
      "wrong",
      "tells",
      "everything",
      "at",
      "end",
      "prescription",
      "parents",
    ],
    words: [
      "she",
      "is",
      "a",
      "doctor",
      "treats",
      "children",
      "takes",
      "care",
      "of",
      "them",
      "asks",
      "what",
      "wrong",
      "tells",
      "everything",
      "going",
      "to",
      "be",
      "okay",
      "gives",
      "candy",
      "at",
      "the",
      "end",
      "love",
      "her",
      "prescription",
      "parents",
    ],
    link: "https://www.eslfast.com/kidsenglish2/ke2/ke2051.htm",
    storage_link: null,
    id: "She is a doctor\n\nShe is a doctor. She treats children. She takes care of them. She asks them what is wrong. She tells them everything is going to be okay. She gives them candy at the end. The children love her. She gives the prescription to the parents.",
    story_id: 470,
    word_count: 28,
    all_intersection_count: 17,
    new_words_count: 11,
  },
  {
    row_new_words: [
      "did",
      "washed",
      "floors",
      "all",
      "cleaned",
      "painted",
      "porch",
      "fixed",
      "sink",
      "worked",
      "at",
      "home",
    ],
    words: [
      "what",
      "did",
      "you",
      "do",
      "today",
      "I",
      "washed",
      "my",
      "floors",
      "your",
      "yes",
      "all",
      "day",
      "cleaned",
      "house",
      "painted",
      "porch",
      "fixed",
      "sink",
      "we",
      "worked",
      "at",
      "home",
    ],
    link: "(2)https://www.youtube.com/watch?v=xyvNu254A-Y&list=PL31D7CB49ABAAB4DB&index=24",
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/doing-1.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=drYWU0J2dhyK32ZJiCY9tB0evxB7VhLTNclDMTT4Bu63Xc5f79rO0KtGQEr3847QGA0GXUQG3bGi0Qt1%2FAoee9tg2a%2BFjyBdqgDfTTZVPW8d4F6xGjCxCilTE7a%2B7mNEJO9qeNoHZzAdLkhTTNBGzfYviqm0nPEhU2XeSU2Wpqa5O2Shnpw5gN%2Fr5leQGkQ%2BBYEvJJVpL4EseQS5qBpqSIZxzBCTvFuH0N%2BHnkMxAOu%2Baj5cu4%2F%2BRWlFnTXoMSYnBC0o7GekEiQ0Icu8%2FbE%2B8u4JBkvUV8pcJ3UrEM20y3E7qOu%2F4kS1ic38B4VBfHcz%2FSCKHLCFJyIIAT2qMM2BYQ%3D%3D",
    id: "What did you do today?\nI washed my floors.\nYour floors?\nYes. I washed my floors all day.\n\nWhat did you do today?\nI cleaned my house.\nYour house?\nYes. I cleaned my house all day.\n\nWhat did you do today?\nI painted my porch.\nYour porch?\nYes. I painted my porch all day.\n\nWhat did you do today?\nI fixed my sink.\nYour sink?\nYes. I fixed my sink all day.\n\nI washed my floors.\nI cleaned my house.\nI painted my porch.\nI fixed my sink.\nWe worked at home all day.",
    story_id: 1010,
    word_count: 23,
    all_intersection_count: 11,
    new_words_count: 12,
  },
  {
    row_new_words: [
      "changes",
      "sometimes",
      "lot",
      "barely",
      "life",
      "never",
      "stops",
      "evolves",
      "growing",
      "even",
      "when",
      "best",
    ],
    words: [
      "every",
      "day",
      "changes",
      "you",
      "sometimes",
      "a",
      "lot",
      "barely",
      "but",
      "life",
      "never",
      "stops",
      "it",
      "evolves",
      "and",
      "are",
      "growing",
      "even",
      "when",
      "can't",
      "see",
      "you're",
      "doing",
      "the",
      "best",
      "can",
    ],
    link: "https://www.familyfriendpoems.com/poem/every-day-changes-you",
    storage_link: null,
    id: "every day changes you\nsometimes a lot\nsometimes barely\nbut life never stops\nit evolves\nand you are growing\neven when you can’t see it\nyou’re doing the best you can",
    story_id: 918,
    word_count: 26,
    all_intersection_count: 14,
    new_words_count: 12,
  },
  {
    row_new_words: [
      "smile",
      "when",
      "frown",
      "sad",
      "blush",
      "embarrassed",
      "shout",
      "mad",
      "smiling",
      "frowning",
      "blushing",
      "shouting",
    ],
    words: [
      "I",
      "smile",
      "when",
      "I'm",
      "happy",
      "frown",
      "sad",
      "blush",
      "embarrassed",
      "and",
      "shout",
      "mad",
      "are",
      "you",
      "smiling",
      "yes",
      "frowning",
      "blushing",
      "shouting",
      "we",
      "we're",
    ],
    link: "(6)https://www.youtube.com/watch?v=vaq6pX7q15s&list=PL31D7CB49ABAAB4DB&index=18",
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/adjectives2.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=ban1eYA%2Bt3ow%2B7QZtihCN30crhFaMknW%2FExq3ylHbtydcawFFtv0FCx7vDjfUwGO3Duft8b99KbDpOuZVQvW%2F4ickhiwpMQJjbeW6MIxejJm1euCpzwxwiBKmzhOop6wmiclS3Z2vaOKAGm3dknid6CNxGUngGaZOuDtBvc4eMeG%2BACjwPkW9OcmQKb2zKBNcE9KG9ec3ADqLu5gpvRli2t9TtJ6feOjgTiPAJ7vSxdwOqnr8UhBscQc44I%2FBAJlWckNV5879N6jaYFM4zcom2A0ghkZFyIEURAUwjzJNigyQdXBOovYBekdkTM30HuKtSnyGuWM7hyjwO42Uqn8Rw%3D%3D",
    id: "I smile when I'm happy.\nI frown when I'm sad.\nI blush when I'm embarrassed, and I shout when I'm mad.\nAre you smiling?\nYes. I'm happy.\nAre you frowning? Yes. I'm sad.\nAre you blushing? I'm embarrassed.\nAre you shouting? Yes. I'm mad.\nWe smile when we're happy.\nWe frown when we're sad.\nWe blush when we're embarrassed, and we shout when we're mad.\nWe smile when we're happy.\nWe frown when we're sad.\nWe blush when we're embarrassed, and we shout when we're mad.",
    story_id: 994,
    word_count: 21,
    all_intersection_count: 9,
    new_words_count: 12,
  },
  {
    row_new_words: [
      "fixing",
      "sink",
      "Bob",
      "his",
      "car",
      "Mary",
      "cleaning",
      "our",
      "apartment",
      "children",
      "their",
      "homework",
    ],
    words: [
      "hi",
      "what",
      "are",
      "you",
      "doing",
      "I'm",
      "fixing",
      "my",
      "sink",
      "what's",
      "Bob",
      "he's",
      "his",
      "car",
      "Mary",
      "she's",
      "cleaning",
      "her",
      "room",
      "we're",
      "our",
      "apartment",
      "your",
      "children",
      "they're",
      "their",
      "homework",
    ],
    link: "https://www.youtube.com/watch?v=OLieQ0U3TA4&t=181s",
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/Present%20Continuous.%20Question%20form%204.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=ham8UmHfrF170PgxAC0%2BrVL56xanpD6VhqJueVmvd76C9CEi2xXK0D9HLqOvq14rbLgMtZFgG5O5k4I43lgmcLhRhRaiEGtw4o8Sd3jPRSaejpJRi0YRwfzM1IvO6Vw9sqF6g5iCV6MsvYVaaOvMv0DntjiQlv4qSo03ANhNi9U26DhGcC8YtmiJfIjqjiDj00nbsPywkfixdJBkwjLCHuQT4YpyiAAcOTZ9mCVUeUoJXbNBya8Nf62Y3nwDFEoThxSAGJ7C0TVaeJwwNS6suEevaE4WhQzOg4r8DHBnLryYit2PvURISUTOBTG2KHgEFcjv%2BY66KUPZ9dzIUZjltw%3D%3D",
    id: "Hi.\nWhat are you doing?\nI'm fixing my sink.\nWhat's Bob doing?\nHe's fixing his car.\nWhat's Mary doing?\nShe's cleaning her room.\nWhat are you doing?\nWe're cleaning our apartment.\nWhat are your Children doing?\nThey're doing their homework.",
    story_id: 957,
    word_count: 27,
    all_intersection_count: 15,
    new_words_count: 12,
  },
  {
    row_new_words: [
      "get",
      "time",
      "usually",
      "here",
      "by",
      "8",
      "sometimes",
      "early",
      "never",
      "gets",
      "rarely",
      "right",
    ],
    words: [
      "I",
      "always",
      "get",
      "to",
      "work",
      "on",
      "time",
      "I'm",
      "usually",
      "here",
      "by",
      "8",
      "sometimes",
      "early",
      "never",
      "late",
      "no",
      "he",
      "gets",
      "he's",
      "rarely",
      "right",
    ],
    link: "https://www.youtube.com/watch?v=16kzXiwYeEo&list=PL31D7CB49ABAAB4DB&index=16",
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/never.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=ZGidhtXumoswmTIxIXSAQEZUwlytx5UG39SYp1H5una%2F%2B2IHkq9i702hUWhswhhr9vcaE6lwsKsAB7M6ub2vM9LVybVIyuwnXzGF%2B9bGr3QsNUNiYygb7RUIRNd3wedNkB37HoWQTmtOBs3jqvNJOU7tbsyLzW%2BqLGSFocl9%2F8xIGFFUTRE21ZxipSz1SUUXNLybzQWoEVBPQcdbVKuWG5AcmiKB1jIDt2Jj3jHRXlryanMomaxRvOi1%2B7pCkyDZWNM1zWisLCgPtt2ctcX%2BX%2BoQFzr%2B%2BI%2FZfIv6qPQe7xGyphp3TFTzmtI6SbG6Q7Q6UiaeNLc3vl1%2BkBfC82%2BcQg%3D%3D",
    id: "I always get to work on time.\nI'm usually here by 8.\nI sometimes get here early.\nI never get here late.\nNo. I never get here late.\n\nHe always gets to work on time.\nHe's usually here by 8.\nHe sometimes gets here early.\nHe rarely gets here late.\n\nNo. I never get here late.\nRight. He never gets here late.",
    story_id: 987,
    word_count: 22,
    all_intersection_count: 10,
    new_words_count: 12,
  },
  {
    row_new_words: [
      "did",
      "yesterday",
      "went",
      "beach",
      "oh",
      "nobody",
      "by",
      "myself",
      "yourself",
      "had",
      "great",
      "time",
    ],
    words: [
      "what",
      "did",
      "you",
      "do",
      "yesterday",
      "I",
      "went",
      "to",
      "the",
      "beach",
      "oh",
      "who",
      "go",
      "with",
      "nobody",
      "by",
      "myself",
      "yourself",
      "yes",
      "had",
      "a",
      "great",
      "time",
    ],
    link: "https://www.youtube.com/watch?v=Yf5RXW3zAAk&list=PLD7AA7B1BC72ACC28&index=18",
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/yourself-1.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=H45s39J3xVFjoUYQmJOu%2BpxCyj%2FMCEG1pn%2Frk4JXslscMDB4T1xo7R%2B9wLa2xrs9Wd9qxzUDjbmQYIcBBZYOSxfgUIDrP%2B%2Bi4qUZoL%2BVk7LKLgwaWCmslhvRWqxbVd5q%2BtiQLMx55N9dDdwyYZl8jX8d%2FJWzsOG8y4ALt24%2Fl8iiJx75U4pb7eLYaDoBHF8BVl3lNOLfvhEPZ4svTXKsJ4oCg0%2FwJzluWx3v1P4hYBK6tvpg2Wm%2FJwdXQNTfAgx0BMdXqSD2hLHhSzblsFULE224fIy1cQLnlxfbNduoslK3OGOC2qIMNOke7ptmXC4%2BbXog16DR%2BiwUnGVwnXX19Q%3D%3D",
    id: "What did you do yesterday?\nI went to the beach.\nOh, who did you go to the beach with?\nNobody.\nI went to the beach by myself.\nYou went by yourself?\nYes. I had a great time.",
    story_id: 1051,
    word_count: 23,
    all_intersection_count: 11,
    new_words_count: 12,
  },
  {
    row_new_words: [
      "stick",
      "fetch",
      "when",
      "throw",
      "I'll",
      "yell",
      "come",
      "Walter",
      "bring",
      "back",
      "pup",
      "apparently",
    ],
    words: [
      "if",
      "you",
      "have",
      "a",
      "ball",
      "or",
      "stick",
      "and",
      "dog",
      "can",
      "play",
      "fetch",
      "when",
      "throw",
      "the",
      "I'll",
      "yell",
      "come",
      "on",
      "Walter",
      "bring",
      "it",
      "back",
      "pup",
      "apparently",
      "doesn't",
      "want",
      "to",
      "today",
      "he",
      "just",
      "wants",
      "with",
      "okay",
      "give",
      "me",
    ],
    link: "https://www.youtube.com/shorts/od3sDMh3qus",
    storage_link: "",
    id: "If you have a ball or a stick and a dog, you can play fetch. When you play fetch, you throw the ball or stick. I'll throw the stick and you yell, fetch. Fetch. Come on, Walter. Bring it back. Come on, pup. Come on. Come on, pup. Apparently, Walter doesn't want to play fetch today. He just wants to play with the stick. Okay, you give me the stick. You give me the stick. Bring me the stick. You give me the stick. You give me the stick.",
    story_id: 1086,
    word_count: 36,
    all_intersection_count: 24,
    new_words_count: 12,
  },
  {
    row_new_words: [
      "eggs",
      "peas",
      "bread",
      "beans",
      "cheese",
      "pasta",
      "pie",
      "pizza",
      "chips",
      "mum",
      "because",
      "things",
    ],
    words: [
      "I",
      "don't",
      "like",
      "eggs",
      "or",
      "rice",
      "peas",
      "bread",
      "beans",
      "cheese",
      "pasta",
      "pie",
      "and",
      "pizza",
      "fish",
      "chips",
      "meat",
      "my",
      "mum",
      "likes",
      "because",
      "she",
      "knows",
      "they're",
      "good",
      "for",
      "me",
      "doesn't",
      "the",
      "things",
      "so",
      "what",
      "eat",
    ],
    link: "https://learnenglishkids.britishcouncil.org/grammar-vocabulary/grammar-chants/i-dont-eggs",
    storage_link: null,
    id: "I don't like eggs or rice or peas\nI don't like bread or beans or cheese\nI like pasta, pie and pizza\nFish and chips and meat.\n\nMy mum likes eggs and rice and peas\nBecause she knows they're good for me\nShe doesn't like the things I like\nSo what she likes I eat!",
    story_id: 823,
    word_count: 33,
    all_intersection_count: 21,
    new_words_count: 12,
  },
  {
    row_new_words: [
      "gonna",
      "wife",
      "birthday",
      "necklace",
      "gave",
      "last",
      "year",
      "how",
      "2",
      "years",
      "ago",
      "really",
    ],
    words: [
      "what",
      "are",
      "you",
      "gonna",
      "give",
      "your",
      "wife",
      "for",
      "her",
      "birthday",
      "I",
      "don't",
      "know",
      "can't",
      "a",
      "necklace",
      "gave",
      "last",
      "year",
      "how",
      "about",
      "flowers",
      "no",
      "2",
      "years",
      "ago",
      "well",
      "really",
      "have",
      "to",
      "think",
      "it",
    ],
    link: "https://www.youtube.com/watch?v=_lA-Min1MGk&list=PLD7AA7B1BC72ACC28&index=2",
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/gift.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=JJ0J2p87LUDqyfS17iG5FnBU5VXMNPmXxGljXzYklRUoau72xAsFrw58dPEJopnbpwsq2o0rOTMGx%2FDig95kCNE2S2DosGuyyjPZA5QuVNWnH3J0zRRaRposbsE7ljhzGcguHe0ZnaCcjmR%2FZZpc1K23uCmKpO5Ace6tZCYEgt8qQMmL3XS198jCTdLCvjDuF%2BjteG7Piz%2BZPulPfgFbO9raUwrOH376RDaa52sv3ElDIqMMt0guIKB0s2%2FeAHshh8OMzdcRnz8JWLN64OrWk7mBr4bfCz91E%2BnKHZFY6w4d2FEk6vGseBesmgrzUYtJtmcynE88PJhT4I6YK6v9NA%3D%3D",
    id: "What are you gonna give your wife for her birthday?\nI don't know.\nI can't give her a necklace. I gave her a necklace last year.\nHow about flowers?\nNo. I can't give her flowers. I gave her flowers 2 years ago.\nWell, what are you gonna give her?\nI don't know. I really have to think about it.",
    story_id: 1012,
    word_count: 32,
    all_intersection_count: 20,
    new_words_count: 12,
  },
  {
    row_new_words: [
      "white",
      "legs",
      "pointy",
      "ears",
      "fast",
      "throws",
      "toy",
      "across",
      "tells",
      "get",
      "back",
      "dog's",
      "mouth",
    ],
    words: [
      "a",
      "white",
      "dog",
      "she",
      "has",
      "it",
      "is",
      "short",
      "legs",
      "pointy",
      "ears",
      "runs",
      "very",
      "fast",
      "plays",
      "with",
      "the",
      "throws",
      "toy",
      "across",
      "room",
      "tells",
      "to",
      "go",
      "get",
      "watches",
      "back",
      "her",
      "in",
      "dog's",
      "mouth",
    ],
    link: "https://www.eslfast.com/kidsenglish2/ke2/ke2043.htm",
    storage_link: null,
    id: "A white dog\n\nShe has a dog. It is white. It has short legs. It has pointy ears. It runs very fast. She plays with the dog. She throws a toy across the room. She tells the dog to go get it. The dog runs. She watches it. The dog runs back to her. The toy is in the dog's mouth.",
    story_id: 455,
    word_count: 31,
    all_intersection_count: 18,
    new_words_count: 13,
  },
  {
    row_new_words: [
      "goes",
      "newsstand",
      "reads",
      "cover",
      "story",
      "first",
      "murderer",
      "loose",
      "scary",
      "looks",
      "at",
      "cartoons",
      "funny",
    ],
    words: [
      "she",
      "buys",
      "a",
      "newspaper",
      "goes",
      "to",
      "the",
      "newsstand",
      "wants",
      "buy",
      "know",
      "what",
      "is",
      "going",
      "on",
      "for",
      "week",
      "reads",
      "cover",
      "story",
      "first",
      "it",
      "about",
      "murderer",
      "loose",
      "scary",
      "looks",
      "at",
      "cartoons",
      "funny",
    ],
    link: "https://www.eslfast.com/kidsenglish2/ke2/ke2078.htm",
    storage_link: null,
    id: "She buys a newspaper\n\nShe goes to the newsstand. She wants to buy a newspaper. She wants to know what is going on. She buys the newspaper for the week. She reads the cover story first. It is about a murderer on the loose. It is scary. She looks at the cartoons. It is funny.",
    story_id: 497,
    word_count: 30,
    all_intersection_count: 17,
    new_words_count: 13,
  },
  {
    row_new_words: [
      "May",
      "looking",
      "jacket",
      "here's",
      "this",
      "purple",
      "jackets",
      "popular",
      "year",
      "pair",
      "gloves",
      "these",
      "green",
    ],
    words: [
      "May",
      "I",
      "help",
      "you",
      "yes",
      "please",
      "I'm",
      "looking",
      "for",
      "a",
      "jacket",
      "here's",
      "nice",
      "but",
      "this",
      "is",
      "purple",
      "that's",
      "okay",
      "jackets",
      "are",
      "very",
      "popular",
      "year",
      "can",
      "pair",
      "of",
      "gloves",
      "these",
      "green",
    ],
    link: "(6)https://www.youtube.com/watch?v=HVJNOeDvpME&list=PL31D7CB49ABAAB4DB&index=11",
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/clothing6.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=P763AEOSvd7OaAfR63RreXV2JPNZ09Nl9tONRYesLdgznsjhE2xbjp00LUUVguOpFC7MY%2FZUJAF2Vh1CcA7sAG86G97CmkPCcKosQ2aWo%2FMgiROqZYGCR%2Bn35nJz%2BpTv49iID5h9a18xIb%2FxAof02Mw2ZB%2FK021xtVz1wpq2p6idzTppPaq%2FvBsM2hcc79cHcw3ys8sUimbEf17BTU2Q09PPM8tRxDdWavXIfztqgmBG9TYugnhuSnRQ2UU%2FJ%2BTB6qLLrSfK3RQFTASG2qkcBAuU8rvIqN2wM0AIgnTm%2FeKaV4SMKJBwXGAJOAm%2Bi6UlQBAAxTVmOLzKz%2Bcpbgn1FA%3D%3D",
    id: "May I help you?\nYes, please. I'm looking for a jacket.\nHere's a nice jacket.\nBut this is a purple jacket.\nThat's okay, purple jackets are very popular this year.\n\nCan I help you?\nYes, please. I'm looking for a pair of gloves.\nHere's a nice pair of gloves.\nBut these are green gloves.\nThat's okay. Green gloves are very popular this year.",
    story_id: 984,
    word_count: 30,
    all_intersection_count: 17,
    new_words_count: 13,
  },
  {
    row_new_words: [
      "winter",
      "cold",
      "ice",
      "mug",
      "hot",
      "chocolate",
      "would",
      "long",
      "dark",
      "nights",
      "kids",
      "bundle",
      "up",
    ],
    words: [
      "winter",
      "cold",
      "and",
      "ice",
      "a",
      "mug",
      "of",
      "hot",
      "chocolate",
      "would",
      "be",
      "nice",
      "long",
      "dark",
      "nights",
      "kids",
      "bundle",
      "up",
    ],
    link: "",
    storage_link: null,
    id: "Winter, winter\ncold and ice\nA mug of hot chocolate\nwould be nice\nWinter, winter\nlong, dark nights\nKids bundle up",
    story_id: 900,
    word_count: 18,
    all_intersection_count: 5,
    new_words_count: 13,
  },
  {
    row_new_words: [
      "Jane",
      "called",
      "said",
      "was",
      "dinner",
      "asked",
      "call",
      "back",
      "would",
      "next",
      "snack",
      "when",
      "aren't",
    ],
    words: [
      "always",
      "eating",
      "Jane",
      "called",
      "Lisa",
      "said",
      "she",
      "was",
      "dinner",
      "asked",
      "to",
      "call",
      "her",
      "back",
      "would",
      "the",
      "next",
      "day",
      "lunch",
      "breakfast",
      "a",
      "snack",
      "no",
      "you",
      "me",
      "when",
      "aren't",
    ],
    link: "https://www.eslfast.com/supereasy/se/supereasy128.htm",
    storage_link: null,
    id: 'Always eating\n\nJane called Lisa. Lisa said she was eating. She was eating dinner. Lisa asked Jane to call her back. Jane said she would call Lisa back. She called Lisa back the next day. Lisa said she was eating. She was eating lunch. Lisa asked Jane to call her back. Jane said she would call Lisa back. She called Lisa back the next day. Lisa said she was eating. She was eating breakfast. Lisa asked Jane to call her back. Jane said she would call Lisa back. The next day Jane called Lisa back. Lisa was eating. She was eating a snack. Lisa asked Jane to call her back. Jane said, "No. You call me back. You call me back when you aren\'t eating."',
    story_id: 152,
    word_count: 27,
    all_intersection_count: 14,
    new_words_count: 13,
  },
  {
    row_new_words: [
      "when",
      "something",
      "get",
      "glasses",
      "out",
      "then",
      "put",
      "otherwise",
      "words",
      "done",
      "take",
      "off",
      "away",
    ],
    words: [
      "when",
      "I",
      "want",
      "to",
      "read",
      "something",
      "need",
      "get",
      "my",
      "glasses",
      "out",
      "and",
      "then",
      "put",
      "on",
      "otherwise",
      "can't",
      "see",
      "the",
      "words",
      "I'm",
      "done",
      "reading",
      "take",
      "off",
      "away",
    ],
    link: "https://www.youtube.com/shorts/cSkbwh0H76k",
    storage_link: "",
    id: "When I want to read something, I need to get my glasses out, and then I need to put my glasses on. Otherwise I can't see the words. When I'm done reading, I take my glasses off and then I put my glasses away.",
    story_id: 1096,
    word_count: 26,
    all_intersection_count: 13,
    new_words_count: 13,
  },
  {
    row_new_words: [
      "museum",
      "at",
      "looks",
      "painting",
      "long",
      "time",
      "something",
      "another",
      "man",
      "painter's",
      "information",
      "Ryan",
      "Howard",
    ],
    words: [
      "going",
      "to",
      "the",
      "museum",
      "he",
      "is",
      "at",
      "looks",
      "a",
      "painting",
      "it",
      "of",
      "dog",
      "for",
      "long",
      "time",
      "something",
      "about",
      "so",
      "beautiful",
      "another",
      "man",
      "dancing",
      "painter's",
      "information",
      "name",
      "Ryan",
      "Howard",
    ],
    link: "https://www.eslfast.com/kidsenglish2/ke2/ke2090.htm",
    storage_link: null,
    id: "Going to the museum\n\nHe is at the museum. He looks at a painting. It is a painting of a dog. He looks at it for a long time. Something about it is so beautiful. He looks at another painting. It is a painting of a man dancing. He looks at the painter's information. The painter's name is Ryan Howard.",
    story_id: 502,
    word_count: 28,
    all_intersection_count: 15,
    new_words_count: 13,
  },
  {
    row_new_words: [
      "bicycle",
      "fast",
      "faster",
      "dad",
      "taller",
      "our",
      "TV",
      "big",
      "bigger",
      "strong",
      "stronger",
      "friendly",
      "friendlier",
    ],
    words: [
      "you",
      "know",
      "my",
      "bicycle",
      "is",
      "fast",
      "faster",
      "dad",
      "tall",
      "taller",
      "our",
      "TV",
      "big",
      "so",
      "bigger",
      "brother",
      "strong",
      "think",
      "stronger",
      "dog",
      "friendly",
      "friendlier",
      "see",
    ],
    link: "https://www.youtube.com/watch?v=GSx9o9uZosQ&list=PLD7AA7B1BC72ACC28&index=9",
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/fast.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=Z%2FDxBuM8P9O2PfEWJwCHMeMUzRscDeQvmwo7V0m8h3xmiPTVasU%2BFip0tkj2qH6hhi5ZshLZfZtAVyAo%2BR16K6QeA6vkJoodE4jCI4p4viQ47BF48%2F7wdYoCeIJxxsPHWLpjTBDmPbUJge%2BODIn3PGxRgJqqnV1%2BkwL1RHPZaj%2FHKJNk5Pf7V9hb18yZgWP3c0T0Wwq5Gy0gYmDEURaCebGAKtg6tJtIR4T0gNMwQ2VxfBv6STZObsmEurMIKfSFGjI22HzZfC8ODaRMsBepwoRmaKi5f4bIBPboeFTFazdn4Q%2F3IBe%2FR3y2QiDdUgeMSkgfLJxy9xgRaSkHsEtvIA%3D%3D",
    id: "You know, my bicycle is fast.\nMy bicycle is faster.\nMy dad is tall.\nMy dad is taller.\nOur TV is big.\nSo, our TV is bigger.\nMy brother is strong.\nYou think so? My brother is stronger.\nMy dog is friendly. My dog is friendlier.\nSee you.\nSee you.",
    story_id: 1045,
    word_count: 23,
    all_intersection_count: 10,
    new_words_count: 13,
  },
  {
    row_new_words: [
      "how",
      "much",
      "head",
      "lettuce",
      "cost",
      "95¢",
      "lot",
      "right",
      "expensive",
      "this",
      "pound",
      "dollar",
      "25",
    ],
    words: [
      "how",
      "much",
      "does",
      "a",
      "head",
      "of",
      "lettuce",
      "cost",
      "95¢",
      "that's",
      "lot",
      "money",
      "you're",
      "right",
      "is",
      "very",
      "expensive",
      "this",
      "week",
      "pound",
      "apples",
      "dollar",
      "25",
      "are",
    ],
    link: "(2)https://www.youtube.com/watch?v=SBG0nsPuI6Q&list=PLD7AA7B1BC72ACC28&index=5",
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/cost.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=B%2FGx8ZIk2KhoGSA71vIJk7WiUyVgS5UQ%2BWMebAO5DP1DTkgvB%2BxAi6NmkB3KmXh6osoFMPcLYQg%2F3A6e91zym%2FaH4Hg9oMMxm5TRAXN0doR9mNU1J8XGBd8pz%2B6Uv0vlseaRzlrV8G4DRI4kOA5jAN92rDLacKJqs57FeMXqcRPSEV%2BeGIGTkpZlXgon6hhpqfNTxXmBKeN6yXD5C99nDGq%2BPI2IYVafSFUkHGIqf%2FVSy%2B6GlRq54%2Bylne5tvYVWoNGWvqmipCpc4aFkgIXPTiYaN59fwDoYH0d2QFQ2nYHaVstIyunft3uR68sa3TRNcM6hJ9om7rJd9ySNVXdoZw%3D%3D",
    id: "How much does a head of lettuce cost?\n95¢.\n95¢? That's a lot of money.\nYou're right. Lettuce is very expensive this week.\n\nHow much does a pound of apples cost?\nA dollar 25.\nA dollar 25? That's a lot of money.\nYou're right. Apples are very expensive this week.",
    story_id: 1023,
    word_count: 24,
    all_intersection_count: 11,
    new_words_count: 13,
  },
  {
    row_new_words: [
      "-",
      "father",
      "his",
      "Paul",
      "paris",
      "standing",
      "front",
      "eiffel",
      "tower",
      "this",
      "really",
      "photograph",
      "favorite",
    ],
    words: [
      "-",
      "who",
      "is",
      "he",
      "he's",
      "my",
      "father",
      "what's",
      "his",
      "name",
      "Paul",
      "where",
      "in",
      "paris",
      "doing",
      "standing",
      "front",
      "of",
      "the",
      "eiffel",
      "tower",
      "this",
      "a",
      "really",
      "nice",
      "photograph",
      "thank",
      "you",
      "it's",
      "favorite",
    ],
    link: "https://www.youtube.com/watch?v=KkV9MBLOomI&list=PL31D7CB49ABAAB4DB&index=8",
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/photos.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=F7I1rptA9%2BBb707b%2B%2BhGsMUP6MkOvtr6vXknbHjN5oZyUm53DwXsNlcCxEEjzbCcmEAlv2AdFvQkvs6QeOpmGYkfsxXUL4w%2BFIqaClAmxrrWdCZX1uKG4ZtgHTCjV0OCbsj6%2FvNUYRXWaGE8Kdg%2BLjY5nLPn9sxOeM7%2FZ1XgFykbYGVtK%2BMhPlpDC4evzCEpo2YGrIquSsFlj87habe3bOOa1dFX00kqFezwSpvPzk4yH%2BalD3GUEzLRUg%2BrzlBb%2BNd0fOUTwPm%2BemXLxVfswWDBafnyDKvl05IEdCtmdQy6LQz8C2TmJBsNhwamNGS9ha6IIDeKTecmIIOO3zNsvA%3D%3D",
    id: "- Who is he?\n- He's my father.\n- What's his name?\n- His name is Paul.\n- Where is he?\n- He's in Paris.\n- What's he doing?\n- He's standing in front of the Eiffel Tower.\n- This is a really nice photograph.\n- Thank you.\n- It's my favorite photograph of my father.",
    story_id: 974,
    word_count: 30,
    all_intersection_count: 17,
    new_words_count: 13,
  },
  {
    row_new_words: [
      "did",
      "John",
      "yesterday",
      "went",
      "movies",
      "oh",
      "nobody",
      "by",
      "himself",
      "tina",
      "played",
      "cards",
      "herself",
    ],
    words: [
      "what",
      "did",
      "John",
      "do",
      "yesterday",
      "he",
      "went",
      "to",
      "the",
      "movies",
      "oh",
      "who",
      "go",
      "with",
      "nobody",
      "by",
      "himself",
      "tina",
      "she",
      "played",
      "cards",
      "play",
      "herself",
    ],
    link: "(2)https://www.youtube.com/watch?v=Yf5RXW3zAAk&list=PLD7AA7B1BC72ACC28&index=18",
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/did.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=fgVT8XGndaJwI%2BhmMfhxvUlzzFU4FkKE1hBfYUCjZEeJiIgJ%2BuBtfPVNzXCmBOclsvMpedPjS8Ptaza%2FST679RV%2F5QDh5Lyxga21ojHyfkRVjTI8bcilp0XLe3Gr5yLlynbQ3R01srVoB8I05rx2P0U5Zy8FsIsSW5WNjcs%2FXteLKhdYvcqycXMHM39xWzrsf1YC0EVxoB2AujQSoVnxuJ%2BVNzgOjYxY%2BtVvkEshJEAS8bgXiOQBzMo9tfDutr8o3vK6aPlNVRWoad1K1Ddg9FEnnm9nccxheCNPKye%2BewqUNYlv5f7cbUTodUq5Al6XRFSxnDjgNiLKq9RupJuovQ%3D%3D",
    id: "What did John do yesterday?\nHe went to the movies.\nOh, who did he go to the movies with?\nNobody. He went to the movies by himself.\n\nWhat did Tina do yesterday?\nShe played cards.\nOh, who did she play cards with?\nNobody. She played cards by herself.",
    story_id: 1052,
    word_count: 23,
    all_intersection_count: 10,
    new_words_count: 13,
  },
  {
    row_new_words: [
      "simple",
      "things",
      "bring",
      "joy",
      "being",
      "fills",
      "soul",
      "everything",
      "freedom",
      "kindness",
      "smile",
      "sets",
      "heart",
      "fire",
    ],
    words: [
      "it's",
      "the",
      "simple",
      "things",
      "that",
      "bring",
      "me",
      "joy",
      "being",
      "with",
      "you",
      "fills",
      "my",
      "soul",
      "everything",
      "money",
      "can't",
      "buy",
      "like",
      "love",
      "and",
      "freedom",
      "kindness",
      "a",
      "smile",
      "sets",
      "heart",
      "on",
      "fire",
    ],
    link: "https://www.familyfriendpoems.com/poem/its-the-simple-things",
    storage_link: null,
    id: "It’s the simple things\nthat bring me joy\nit’s being with you\nthat fills my soul\nit’s everything\nthat money can’t buy\nlike love\nand freedom\nand kindness\nand a smile\nthat sets my heart\non fire",
    story_id: 929,
    word_count: 29,
    all_intersection_count: 15,
    new_words_count: 14,
  },
  {
    row_new_words: [
      "stanley",
      "cook",
      "japanese",
      "food",
      "Sunday",
      "when",
      "cooks",
      "Friday",
      "excuse",
      "ma'am",
      "question",
      "chinese",
      "monday",
      "Wednesday",
    ],
    words: [
      "does",
      "stanley",
      "cook",
      "japanese",
      "food",
      "on",
      "Sunday",
      "no",
      "he",
      "doesn't",
      "when",
      "cooks",
      "Friday",
      "excuse",
      "me",
      "yes",
      "ma'am",
      "I",
      "have",
      "a",
      "question",
      "chinese",
      "monday",
      "Wednesday",
      "see",
      "thank",
      "you",
    ],
    link: "(3)https://www.youtube.com/watch?v=X9QluYxyV00",
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/Present%20Simple%203.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=Lg38UZccajDvbAhQP4Md2Ex4VraeTUvtyH%2BEcVZKsXSFctLqnbCEAj0oH4iLQXTJreZ5PeTS4E9ul7y218iAOGZbOatOyTK0QX9xWMM6Xa9kbCvypWsTVAIwvGvWuocBw%2FuthGL%2BmSPVSl1tC0pYX7K%2FENRIXsyDV5RRqHyUWeHJh9Hj3nvH2y5eZgh%2Bt0qIh1EnLEOLGFkj8naHIP3CIA9N9okwp3pOdmvnoHEqfNXuna6AXHKu8aOg6w5HO4kMDq89NKjClPFaLlF4JnjwHUWVW8Tg7F58AfFjyAgp6bHjr6oUSjGyTOqcVgey1pq9vI35XHH7h02FNSjuOwGP9A%3D%3D",
    id: "Does Stanley cook Japanese food on Sunday?\nNo, he doesn't.\nWhen does he cook Japanese food?\nHe cooks Japanese food on Friday.\n\nExcuse me?\nYes, ma'am?\nI have a question. Does Stanley cook Chinese food on Monday?\nNo, he doesn't. When does he cook Chinese food?\nHe cooks Chinese food on Wednesday.\nI see. Thank you.",
    story_id: 962,
    word_count: 27,
    all_intersection_count: 13,
    new_words_count: 14,
  },
  {
    row_new_words: [
      "twelve",
      "o'clock",
      "morning",
      "one",
      "two",
      "three",
      "four",
      "five",
      "six",
      "seven",
      "eight",
      "nine",
      "ten",
      "eleven",
    ],
    words: [
      "it's",
      "twelve",
      "o'clock",
      "in",
      "the",
      "morning",
      "one",
      "two",
      "three",
      "four",
      "five",
      "six",
      "am",
      "seven",
      "eight",
      "nine",
      "ten",
      "eleven",
    ],
    link: "https://www.englishcentral.com/video/39524",
    storage_link: null,
    id: "It's twelve o'clock in the morning.\nIt's one o'clock in the morning.\nIt's two o'clock in the morning.\nIt's three o'clock in the morning.\nIt's four o'clock in the morning.\nIt's five o'clock in the morning.\nIt's six a.m.\nIt's seven a.m.\nIt's eight a.m.\nIt's nine a.m.\nIt's ten a.m.\nIt's eleven a.m.",
    story_id: 939,
    word_count: 18,
    all_intersection_count: 4,
    new_words_count: 14,
  },
  {
    row_new_words: [
      "puts",
      "shoes",
      "leaves",
      "apartment",
      "look",
      "at",
      "houses",
      "feeling",
      "wind",
      "face",
      "gets",
      "sees",
      "back",
      "home",
    ],
    words: [
      "she",
      "likes",
      "running",
      "puts",
      "her",
      "shoes",
      "on",
      "leaves",
      "apartment",
      "runs",
      "to",
      "school",
      "look",
      "at",
      "other",
      "houses",
      "the",
      "feeling",
      "of",
      "wind",
      "face",
      "gets",
      "sees",
      "teacher",
      "does",
      "not",
      "like",
      "that",
      "back",
      "home",
    ],
    link: "https://www.eslfast.com/kidsenglish2/ke2/ke2037.htm",
    storage_link: null,
    id: "She likes running\n\nShe puts her running shoes on. She leaves her apartment. She runs to her school. She likes to look at other houses. She likes the feeling of the wind on her face. She gets to the school. She sees her teacher. She does not like that teacher. She runs back home.",
    story_id: 463,
    word_count: 30,
    all_intersection_count: 16,
    new_words_count: 14,
  },
  {
    row_new_words: [
      "milk",
      "desk",
      "near",
      "edge",
      "child",
      "looks",
      "at",
      "both",
      "towards",
      "faster",
      "tips",
      "over",
      "all",
      "floor",
    ],
    words: [
      "glass",
      "of",
      "milk",
      "on",
      "the",
      "desk",
      "a",
      "is",
      "it",
      "near",
      "edge",
      "child",
      "looks",
      "at",
      "wants",
      "to",
      "drink",
      "cat",
      "too",
      "they",
      "both",
      "walk",
      "towards",
      "faster",
      "jumps",
      "tips",
      "over",
      "all",
      "floor",
    ],
    link: "https://www.eslfast.com/kidsenglish/ke/ke047.htm",
    storage_link: null,
    id: "Glass of milk on the desk\n\nA glass of milk is on the desk. It is near the edge. The child looks at the milk. The child wants to drink it. The cat looks at the milk. The cat wants to drink it too. They both walk towards the desk. The cat is faster. It jumps on the desk. The glass of milk tips over. The milk is all over the floor.",
    story_id: 370,
    word_count: 29,
    all_intersection_count: 15,
    new_words_count: 14,
  },
  {
    row_new_words: [
      "writes",
      "essay",
      "taking",
      "class",
      "write",
      "penguins",
      "finds",
      "information",
      "internet",
      "first",
      "sentence",
      "riddle",
      "last",
      "answer",
    ],
    words: [
      "she",
      "writes",
      "an",
      "essay",
      "is",
      "taking",
      "English",
      "class",
      "has",
      "to",
      "write",
      "the",
      "on",
      "penguins",
      "finds",
      "information",
      "from",
      "internet",
      "books",
      "her",
      "first",
      "sentence",
      "a",
      "riddle",
      "about",
      "last",
      "answer",
    ],
    link: "https://www.eslfast.com/kidsenglish2/ke2/ke2009.htm",
    storage_link: null,
    id: "She writes an essay\n\nShe is taking an English class. She has to write an essay. The essay is on penguins. She finds information on penguins from the Internet. She finds information on penguins from books. Her first sentence is a riddle about penguins. Her last sentence is the answer to the riddle.",
    story_id: 435,
    word_count: 27,
    all_intersection_count: 13,
    new_words_count: 14,
  },
  {
    row_new_words: [
      "trip",
      "Mark",
      "his",
      "mom",
      "takes",
      "borrow",
      "free",
      "enters",
      "there",
      "many",
      "pirates",
      "science",
      "borrows",
      "all",
    ],
    words: [
      "a",
      "trip",
      "to",
      "the",
      "library",
      "Mark",
      "needs",
      "book",
      "he",
      "does",
      "not",
      "have",
      "money",
      "his",
      "mom",
      "takes",
      "him",
      "can",
      "borrow",
      "books",
      "for",
      "free",
      "enters",
      "there",
      "are",
      "so",
      "many",
      "about",
      "animals",
      "pirates",
      "science",
      "borrows",
      "them",
      "all",
    ],
    link: "https://www.eslfast.com/kidsenglish/ke/ke017.htm",
    storage_link: null,
    id: "A trip to the library\n\nMark needs a book. He does not have money. His mom takes him to the library. Mark can borrow books for free. Mark enters the library. There are so many books. There are books about animals. There are books about pirates. There are books about science. Mark borrows them all.",
    story_id: 333,
    word_count: 34,
    all_intersection_count: 20,
    new_words_count: 14,
  },
  {
    row_new_words: [
      "ugly",
      "boots",
      "his",
      "brown",
      "spots",
      "thinks",
      "unique",
      "plain",
      "make",
      "special",
      "mom",
      "tells",
      "get",
      "says",
    ],
    words: [
      "ugly",
      "boots",
      "people",
      "think",
      "his",
      "are",
      "they",
      "brown",
      "have",
      "yellow",
      "spots",
      "he",
      "does",
      "not",
      "care",
      "what",
      "other",
      "loves",
      "thinks",
      "that",
      "unique",
      "too",
      "plain",
      "for",
      "him",
      "the",
      "make",
      "special",
      "mom",
      "tells",
      "to",
      "get",
      "new",
      "says",
      "no",
    ],
    link: "https://www.eslfast.com/kidsenglish2/ke2/ke2027.htm",
    storage_link: null,
    id: "Ugly boots\n\nPeople think his boots are ugly. They are brown. They have yellow spots. He does not care what other people think. He loves his boots. He thinks that they are unique. Other boots are too plain for him. The yellow spots make the boots special. His mom tells him to get new boots. He says no.",
    story_id: 446,
    word_count: 35,
    all_intersection_count: 21,
    new_words_count: 14,
  },
  {
    row_new_words: [
      "aunt",
      "uncle",
      "visits",
      "his",
      "different",
      "each",
      "skinny",
      "fat",
      "only",
      "quiet",
      "loud",
      "still",
      "when",
      "fun",
    ],
    words: [
      "aunt",
      "and",
      "uncle",
      "he",
      "visits",
      "his",
      "they",
      "are",
      "very",
      "different",
      "from",
      "each",
      "other",
      "is",
      "tall",
      "skinny",
      "fat",
      "short",
      "likes",
      "vegetables",
      "only",
      "eats",
      "meat",
      "quiet",
      "loud",
      "still",
      "love",
      "like",
      "it",
      "when",
      "always",
      "has",
      "fun",
      "with",
    ],
    link: "https://www.eslfast.com/kidsenglish/ke/ke064.htm",
    storage_link: null,
    id: "Aunt and uncle\n\nHe visits his aunt and uncle. They are very different from each other. His aunt is tall and skinny. His uncle is fat and short. His aunt likes vegetables. His uncle only eats meat. His aunt is quiet. His uncle is loud. They still love each other. They like it when he visits. He always has fun with his aunt and uncle.",
    story_id: 380,
    word_count: 34,
    all_intersection_count: 20,
    new_words_count: 14,
  },
  {
    row_new_words: [
      "believes",
      "should",
      "killed",
      "hard",
      "out",
      "most",
      "tells",
      "his",
      "parents",
      "become",
      "vegetarians",
      "laugh",
      "at",
      "up",
    ],
    words: [
      "he",
      "does",
      "not",
      "eat",
      "meat",
      "is",
      "a",
      "vegetarian",
      "believes",
      "that",
      "animals",
      "should",
      "be",
      "killed",
      "it",
      "hard",
      "for",
      "him",
      "to",
      "go",
      "out",
      "with",
      "people",
      "most",
      "like",
      "eating",
      "tells",
      "his",
      "parents",
      "become",
      "vegetarians",
      "they",
      "laugh",
      "at",
      "can't",
      "give",
      "up",
    ],
    link: "https://www.eslfast.com/kidsenglish2/ke2/ke2079.htm",
    storage_link: null,
    id: "He does not eat meat\n\nHe is a vegetarian. He does not eat meat. He believes that animals should not be killed. It is hard for him to go out with people. Most people like eating meat. He tells his parents to become vegetarians. They laugh at him. They can't give up meat.",
    story_id: 498,
    word_count: 37,
    all_intersection_count: 23,
    new_words_count: 14,
  },
  {
    row_new_words: [
      "one",
      "flue",
      "sick",
      "goes",
      "medicine",
      "still",
      "after",
      "his",
      "mom",
      "tells",
      "lies",
      "all",
      "rests",
      "flu",
    ],
    words: [
      "no",
      "one",
      "likes",
      "the",
      "flue",
      "he",
      "is",
      "sick",
      "goes",
      "to",
      "doctor",
      "gives",
      "him",
      "some",
      "medicine",
      "still",
      "after",
      "week",
      "his",
      "mom",
      "tells",
      "go",
      "sleep",
      "lies",
      "in",
      "bed",
      "all",
      "day",
      "rests",
      "drinks",
      "chicken",
      "soup",
      "and",
      "eats",
      "vegetables",
      "flu",
    ],
    link: "https://www.eslfast.com/kidsenglish/ke/ke039.htm",
    storage_link: null,
    id: "No one likes the flue\n\nHe is sick. He goes to the doctor. The doctor gives him some medicine. He is still sick after one week. His mom tells him to go to sleep. He lies in bed all day. He rests. He drinks chicken soup and eats vegetables. He is still sick. No one likes the flu.",
    story_id: 355,
    word_count: 36,
    all_intersection_count: 22,
    new_words_count: 14,
  },
  {
    row_new_words: [
      "pet",
      "Kate",
      "sees",
      "wags",
      "its",
      "tail",
      "collar",
      "takes",
      "home",
      "washes",
      "names",
      "Toby",
      "vet",
      "healthy",
      "each",
    ],
    words: [
      "a",
      "new",
      "pet",
      "Kate",
      "is",
      "walking",
      "she",
      "sees",
      "dog",
      "the",
      "wags",
      "its",
      "tail",
      "likes",
      "it",
      "has",
      "no",
      "collar",
      "takes",
      "home",
      "washes",
      "names",
      "him",
      "Toby",
      "to",
      "vet",
      "healthy",
      "walks",
      "every",
      "day",
      "they",
      "love",
      "each",
      "other",
    ],
    link: "https://www.eslfast.com/kidsenglish/ke/ke011.htm",
    storage_link: null,
    id: 'A new pet\n\nKate is walking. She sees a dog. The dog wags its tail. Kate likes the dog. It has no collar. Kate takes it home. She washes the dog. She names him "Toby." She takes Toby to the vet. Toby is healthy. Kate walks Toby every day. They love each other.',
    story_id: 341,
    word_count: 34,
    all_intersection_count: 19,
    new_words_count: 15,
  },
  {
    row_new_words: [
      "teaches",
      "third-graders",
      "says",
      "morning",
      "asks",
      "students",
      "how",
      "at",
      "job",
      "get",
      "lot",
      "teachers",
      "should",
      "more",
      "hard",
    ],
    words: [
      "a",
      "nice",
      "teacher",
      "she",
      "is",
      "teaches",
      "third-graders",
      "says",
      "good",
      "morning",
      "every",
      "day",
      "asks",
      "her",
      "students",
      "how",
      "they",
      "are",
      "doing",
      "the",
      "love",
      "at",
      "job",
      "does",
      "not",
      "get",
      "lot",
      "of",
      "money",
      "teachers",
      "should",
      "more",
      "work",
      "so",
      "hard",
    ],
    link: "https://www.eslfast.com/kidsenglish2/ke2/ke2002.htm",
    storage_link: null,
    id: 'A nice teacher\n\nShe is a teacher. She teaches third-graders. She says "good morning" every day. She asks her students how they are doing. The students love her. She is good at her job. She does not get a lot of money. Teachers should get more money. They work so hard.',
    story_id: 421,
    word_count: 35,
    all_intersection_count: 20,
    new_words_count: 15,
  },
  {
    row_new_words: [
      "use",
      "this",
      "hay",
      "elevator",
      "here",
      "talk",
      "top",
      "moment",
      "we'll",
      "under",
      "underneath",
      "right",
      "down",
      "over",
      "back",
    ],
    words: [
      "let's",
      "use",
      "this",
      "hay",
      "elevator",
      "here",
      "to",
      "talk",
      "about",
      "on",
      "and",
      "top",
      "of",
      "in",
      "a",
      "moment",
      "we'll",
      "under",
      "underneath",
      "right",
      "now",
      "I",
      "am",
      "the",
      "but",
      "if",
      "climb",
      "down",
      "go",
      "over",
      "so",
      "I'm",
      "it",
      "can",
      "back",
    ],
    link: "(10)https://www.youtube.com/watch?v=_UwT0tC5JTk",
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/10prepositionsofplacebob.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=Dklb8k6kpS7uKjmRtgwJbmwSGvDfbwm3P4ysVbbE0eu89TgOVvGtegOR%2FU7V3PK24dFJ5ApwqdaE422Ws5GTSQm4fr4O0PI0vpFALnBwqhxDWmH6N%2B90krrshpI912Ab9MERSTEZq4EhBqTYPI42NjYK8qOAFSwv%2Bn70XA6kmhzTGvPPYsd8CDW%2BLOT3DNU%2FVTZQEGqbLT0GpAFtg%2FbUhz9Lm%2BUmDUaZYa%2F%2FcbqB6fjT900ll4n4xb99mQsvzmcuIhlPR3Rnvjc08ugc%2Fx%2FSS%2Bn%2Bvvghh1GNQkYrKesE6ZfOaOWT3vVmpVBt6Wz7E45BgBinUrhu0Kl%2BXGLZiXRWPQ%3D%3D",
    id: "Let's use this hay elevator here to talk about on and on top of and in a moment, we'll talk about under and underneath. Right now, I am on the hay elevator. I am on top of the hay elevator. But if I climb down and if I go over here, now, I am under the hay elevator. I am underneath the hay elevator. So right now, I'm under the hay elevator. I'm underneath it. If I go over here, I can go back on the hay elevator. I can go back on top of it.",
    story_id: 1265,
    word_count: 35,
    all_intersection_count: 20,
    new_words_count: 15,
  },
  {
    row_new_words: [
      "Ms",
      "taylor",
      "would",
      "talk",
      "I'd",
      "donald's",
      "course",
      "schedule",
      "next",
      "year",
      "should",
      "latin",
      "more",
      "useful",
      "than",
    ],
    words: [
      "so",
      "Ms",
      "taylor",
      "what",
      "would",
      "you",
      "like",
      "to",
      "talk",
      "about",
      "today",
      "I'd",
      "donald's",
      "course",
      "schedule",
      "for",
      "next",
      "year",
      "alright",
      "should",
      "he",
      "study",
      "English",
      "or",
      "latin",
      "I",
      "think",
      "why",
      "do",
      "say",
      "that",
      "is",
      "more",
      "useful",
      "than",
    ],
    link: "(2)https://www.youtube.com/watch?v=1dW7V33qLwA&list=PLD7AA7B1BC72ACC28&index=10",
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/should2.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=dtQ3bbag%2FLpts1lCD3DhT1Y1cO6lHvQxfy6fHqCXXYMLEyiFzxcDHK335uIkzftSd5fM5gIL3sK%2BXQrSwyLYqwBqYy4LglH79MlxtG3yxz2b%2BXK0nVU2Vv8fEADfIa91hdr4OpFz21%2BVblJCd%2BngLVNrvbYULNYwBe91aZJ8iiK%2Bj1YflGIDU%2BxpVQpTS7hf3LTjjMnoCX1ezaapoVD4WZYbA11mcnENN%2Bv5RXEMDR%2F3dNLer8cJJ3lcrGM6%2Bxm%2FsoyVsfV%2B2a12Hy6saqMvcqQK9LaistVOdwNsljtSh4Sh4%2FwTaijMrrKRAN85PHHdSCF2u8Hj1GUsrptZGU0KQw%3D%3D",
    id: "So, Ms. Taylor, what would you like to talk about today?\nI'd like to talk about Donald's course schedule for next year.\nAlright.\nShould he study English or Latin?\nI think he should study English.\nWhy do you say that?\nEnglish is more useful than Latin.",
    story_id: 1041,
    word_count: 35,
    all_intersection_count: 20,
    new_words_count: 15,
  },
  {
    row_new_words: [
      "parent-teacher",
      "conference",
      "mom",
      "Lisa's",
      "smiles",
      "all",
      "sit",
      "down",
      "talks",
      "grades",
      "smart",
      "also",
      "behavior",
      "friendly",
      "everyone",
    ],
    words: [
      "parent-teacher",
      "conference",
      "Lisa",
      "and",
      "her",
      "mom",
      "go",
      "to",
      "school",
      "they",
      "meet",
      "with",
      "Lisa's",
      "teacher",
      "the",
      "smiles",
      "all",
      "sit",
      "down",
      "talks",
      "about",
      "grades",
      "she",
      "is",
      "doing",
      "very",
      "well",
      "smart",
      "also",
      "behavior",
      "friendly",
      "everyone",
      "happy",
    ],
    link: "https://www.eslfast.com/kidsenglish/ke/ke068.htm",
    storage_link: null,
    id: "Parent-teacher conference\n\nLisa and her mom go to school. They meet with Lisa's teacher. The teacher smiles. They all sit down. The teacher talks about Lisa's grades. She is doing very well. She is very smart. The teacher also talks about Lisa's behavior. She is friendly to everyone. Her mom is happy.",
    story_id: 384,
    word_count: 33,
    all_intersection_count: 18,
    new_words_count: 15,
  },
  {
    row_new_words: [
      "little",
      "baby",
      "oh",
      "small",
      "one",
      "will",
      "big",
      "while",
      "laugh",
      "grows",
      "tell",
      "this",
      "whole",
      "heart",
      "way",
    ],
    words: [
      "little",
      "baby",
      "oh",
      "so",
      "small",
      "one",
      "day",
      "you",
      "will",
      "be",
      "big",
      "and",
      "tall",
      "I",
      "watch",
      "while",
      "laugh",
      "play",
      "my",
      "love",
      "for",
      "grows",
      "every",
      "tell",
      "this",
      "with",
      "whole",
      "heart",
      "just",
      "the",
      "way",
      "are",
    ],
    link: "https://www.familyfriendpoems.com/poem/mothers-short-poem-to-her-little-baby",
    storage_link: null,
    id: "Little baby, oh so small\nOne day you will be big and tall\nI watch you while you laugh and play\nMy love for you grows every day\nI tell you this with my whole heart\nI love you just the way you are",
    story_id: 914,
    word_count: 32,
    all_intersection_count: 17,
    new_words_count: 15,
  },
  {
    row_new_words: [
      "how",
      "much",
      "milk",
      "little",
      "here",
      "thanks",
      "many",
      "cookies",
      "few",
      "would",
      "nothat's",
      "fine",
      "take",
      "sugar",
      "oh",
    ],
    words: [
      "how",
      "much",
      "milk",
      "do",
      "you",
      "want",
      "not",
      "too",
      "just",
      "a",
      "little",
      "okay",
      "here",
      "are",
      "thanks",
      "many",
      "cookies",
      "few",
      "would",
      "like",
      "some",
      "coffee",
      "yes",
      "please",
      "is",
      "that",
      "nothat's",
      "fine",
      "take",
      "in",
      "your",
      "but",
      "that's",
      "sugar",
      "so",
      "what's",
      "new",
      "with",
      "about",
      "oh",
    ],
    link: "https://www.youtube.com/watch?v=WzZbCGxryIk&list=PLD7AA7B1BC72ACC28&index=4",
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/muchmany.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=vVz%2FIuuw4Dk2D%2FWmGI%2Fs3k1lfcDsF24y5BIEQtJCQ5pocOXp9eJdKSo%2B2e9ZhArQwdVY%2B8SEPySSFqgsFnDN%2Br0lXr2P3%2FpSq0BPDhwxfgRRSL1y9h4KSqZxrmRPcecc9bi%2F60H7ghBzTCPuJGglqAPX2g%2Fo24iPmpbke8cH%2BjvSdb9OaKlze2C2i%2FfRusObixPpfxTeiAQ8gzIOEByjFj%2FSs%2Bdaab%2B74Hq%2BhcCVyI2VTQ5MTMUT64rPon97uD4QLUhxeZ8svU6SpxqwlBnsH4mZcZPgeLXnsLm0vPqlaXC8jriTgAFoW5%2FRTUGwNxfk7U8KkHeRCA0PKZoQSbsdzg%3D%3D",
    id: "How much milk do you want?\nNot too much, just a little.\nOkay. Here you are.\nThanks.\n\nHow many cookies do you want?\nNot too many, just a few.\nOkay. Here you are.\nThanks.\n\nWould you like some coffee?\nYes, please. Just a little.\nIs that too much?\nNo.That's fine. Thanks.\nDo you take milk in your coffee?\nYes, please. But not too much.\nThat's fine.\nSugar?\nJust a little, please.\nSo what's new with you?\nNot too much. How about you?\nOh, not too much.",
    story_id: 1016,
    word_count: 40,
    all_intersection_count: 25,
    new_words_count: 15,
  },
  {
    row_new_words: [
      "when",
      "use",
      "present",
      "continuous",
      "first",
      "situation",
      "would",
      "describe",
      "things",
      "right",
      "sitting",
      "chair",
      "spot",
      "hopping",
      "moment",
    ],
    words: [
      "so",
      "when",
      "do",
      "you",
      "use",
      "the",
      "present",
      "continuous",
      "well",
      "first",
      "situation",
      "where",
      "would",
      "is",
      "to",
      "describe",
      "things",
      "that",
      "are",
      "doing",
      "right",
      "now",
      "I'm",
      "sitting",
      "on",
      "a",
      "chair",
      "running",
      "spot",
      "hopping",
      "want",
      "in",
      "moment",
    ],
    link: "(1)https://www.youtube.com/watch?v=rFdhrR6Dpco",
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/1thepresentcontinuous.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=kXCH1Dh%2FglWW4%2B%2FQWc8LdNnalxDaSZWKdduJHVfL%2BZESiLSZBfDb6tXQL4jU7tU0VLCYZtivHw8cEHUITxzPEXxZOzVqiXuxgwrijp9S2eUXHscPEFyceUup3trq2DgVhn4EXPngH8gbrPXnQJJJr3xl8LIw2ipMjUFQ%2FONNd0wIuZzO3%2BSUzZmh98zr%2FrE%2FS0sDpd0pdXUo3PNZfbB433qAmVZKNeRomiO4RNlr7lL%2Fw4HlIVDgcjOmXgNRwwjGie5uBFnsvA%2FcxayJ5ZYvNNf9UiutoS0kgH%2Bu8JJJQiZlGXp5xykDxNyFOP04Ia5Jnj17v7RnzVpNyFElW93%2B8Q%3D%3D",
    id: "So when do you use the present continuous? Well, the first situation where you would use the present continuous is to describe the things that you are doing right now. So I'm sitting on a chair, I'm running on the spot, I'm hopping. So when you want to describe things that you are doing in the moment, things that you are doing right now, you would use the present continuous.",
    story_id: 1278,
    word_count: 33,
    all_intersection_count: 18,
    new_words_count: 15,
  },
  {
    row_new_words: [
      "taking",
      "toy",
      "everywhere",
      "takes",
      "during",
      "recess",
      "out",
      "his",
      "backpack",
      "when",
      "goes",
      "swim",
      "practice",
      "coach",
      "did",
    ],
    words: [
      "taking",
      "the",
      "toy",
      "everywhere",
      "he",
      "has",
      "a",
      "plays",
      "with",
      "takes",
      "it",
      "to",
      "school",
      "during",
      "recess",
      "out",
      "of",
      "his",
      "backpack",
      "and",
      "when",
      "goes",
      "eat",
      "swim",
      "practice",
      "wants",
      "coach",
      "did",
      "not",
      "let",
      "him",
    ],
    link: "https://www.eslfast.com/kidsenglish2/ke2/ke2034.htm",
    storage_link: null,
    id: "Taking the toy everywhere\n\nHe has a toy. He plays with the toy. He takes the toy everywhere. He takes it to school. During recess, he takes it out of his backpack and plays with it. He takes it when he goes out to eat. He takes it when he goes to swim practice. He wants to swim with his toy. His swim coach did not let him.",
    story_id: 460,
    word_count: 31,
    all_intersection_count: 16,
    new_words_count: 15,
  },
  {
    row_new_words: [
      "how",
      "drive",
      "Katherine",
      "turn",
      "16",
      "excited",
      "will",
      "able",
      "herself",
      "friends",
      "movies",
      "grocery",
      "stores",
      "even",
      "states",
    ],
    words: [
      "learning",
      "how",
      "to",
      "drive",
      "Katherine",
      "is",
      "about",
      "turn",
      "16",
      "she",
      "excited",
      "will",
      "be",
      "able",
      "school",
      "herself",
      "and",
      "her",
      "friends",
      "movies",
      "grocery",
      "stores",
      "even",
      "other",
      "states",
    ],
    link: "https://www.eslfast.com/gradedread1/gr/gr1089.htm",
    storage_link: null,
    id: "Learning how to drive\n\nKatherine is about to turn 16. She is excited about learning to drive. She will be able to drive to school. She will be able to drive herself and her friends to movies. She will be able to drive to grocery stores. She will even be able to drive to other states.",
    story_id: 306,
    word_count: 25,
    all_intersection_count: 10,
    new_words_count: 15,
  },
  {
    row_new_words: [
      "Lord",
      "prayer",
      "oh",
      "dear",
      "heart",
      "aching",
      "mind",
      "isn't",
      "clear",
      "left",
      "hurts",
      "soul",
      "without",
      "feel",
      "whole",
    ],
    words: [
      "Lord",
      "hear",
      "my",
      "prayer",
      "oh",
      "so",
      "dear",
      "heart",
      "is",
      "aching",
      "mind",
      "isn't",
      "clear",
      "love",
      "has",
      "left",
      "me",
      "it",
      "hurts",
      "soul",
      "without",
      "that",
      "I",
      "don't",
      "feel",
      "whole",
    ],
    link: "https://www.familyfriendpoems.com/poem/my-love-is-gone-3",
    storage_link: null,
    id: "Lord, hear my prayer oh so dear.\nMy heart is aching; my mind isn't clear.\nMy love has left me; it hurts my soul.\nWithout that love, I don't feel whole.",
    story_id: 920,
    word_count: 26,
    all_intersection_count: 11,
    new_words_count: 15,
  },
  {
    row_new_words: [
      "killing",
      "ant",
      "cannot",
      "kill",
      "person",
      "against",
      "law",
      "will",
      "jail",
      "someone",
      "else's",
      "one",
      "ants",
      "food",
      "invade",
    ],
    words: [
      "killing",
      "an",
      "ant",
      "you",
      "cannot",
      "kill",
      "a",
      "person",
      "that",
      "is",
      "against",
      "the",
      "law",
      "will",
      "go",
      "to",
      "jail",
      "someone",
      "else's",
      "dog",
      "too",
      "can",
      "no",
      "one",
      "likes",
      "ants",
      "eat",
      "your",
      "food",
      "invade",
      "house",
      "not",
    ],
    link: "https://www.eslfast.com/kidsenglish2/ke2/ke2042.htm",
    storage_link: null,
    id: "Killing an ant\n\nYou cannot kill a person. That is against the law. You will go to jail. You cannot kill someone else's dog. That is against the law, too. You can kill an ant. No one likes ants. Ants eat your food. Ants invade your house. Killing ants is not against the law.",
    story_id: 454,
    word_count: 32,
    all_intersection_count: 17,
    new_words_count: 15,
  },
  {
    row_new_words: [
      "lot",
      "pants",
      "two",
      "pairs",
      "jeans",
      "one",
      "light",
      "blue",
      "navy",
      "formal",
      "khaki",
      "gray",
      "casual",
      "hearts",
      "wears",
    ],
    words: [
      "a",
      "lot",
      "of",
      "pants",
      "she",
      "has",
      "two",
      "pairs",
      "jeans",
      "one",
      "them",
      "is",
      "light",
      "blue",
      "the",
      "other",
      "navy",
      "formal",
      "khaki",
      "gray",
      "casual",
      "flowers",
      "on",
      "it",
      "hearts",
      "wears",
      "every",
      "day",
    ],
    link: "https://www.eslfast.com/kidsenglish/ke/ke099.htm",
    storage_link: null,
    id: "A lot of pants\n\nShe has a lot of pants. She has two pairs of jeans. One of them is light blue. The other one is navy blue. She has two pairs of formal pants. One of them is khaki. The other one is gray. She has two pairs of casual pants. One of them has flowers on it. The other one has hearts on it. She wears pants every day.",
    story_id: 418,
    word_count: 28,
    all_intersection_count: 13,
    new_words_count: 15,
  },
  {
    row_new_words: [
      "makes",
      "speech",
      "make",
      "birds",
      "nervous",
      "talking",
      "front",
      "mess",
      "up",
      "fun",
      "tells",
      "scared",
      "relax",
      "all",
      "students",
    ],
    words: [
      "she",
      "makes",
      "a",
      "speech",
      "has",
      "to",
      "make",
      "about",
      "birds",
      "is",
      "nervous",
      "does",
      "not",
      "like",
      "talking",
      "in",
      "front",
      "of",
      "people",
      "want",
      "mess",
      "up",
      "fun",
      "her",
      "tells",
      "teacher",
      "scared",
      "the",
      "relax",
      "all",
      "students",
      "are",
    ],
    link: "https://www.eslfast.com/kidsenglish2/ke2/ke2071.htm",
    storage_link: null,
    id: "She makes a speech\n\nShe has to make a speech about birds. She is nervous. She does not like talking in front of people. She does not want to mess up. She does not want people to make fun of her. She tells her teacher she is scared. The teacher tells her to relax. All the students are scared.",
    story_id: 490,
    word_count: 32,
    all_intersection_count: 17,
    new_words_count: 15,
  },
  {
    row_new_words: [
      "were",
      "before",
      "said",
      "smart",
      "admired",
      "smarts",
      "funny",
      "laughed",
      "at",
      "jokes",
      "loved",
      "spoke",
      "those",
      "three",
      "words",
    ],
    words: [
      "you",
      "were",
      "beautiful",
      "before",
      "they",
      "said",
      "are",
      "smart",
      "admired",
      "your",
      "smarts",
      "funny",
      "laughed",
      "at",
      "jokes",
      "loved",
      "spoke",
      "those",
      "three",
      "words",
    ],
    link: "https://www.familyfriendpoems.com/poem/you-already-were",
    storage_link: null,
    id: "You were beautiful\nbefore they said, you are beautiful\n\nYou were smart\nbefore they admired your smarts\n\nYou were funny\nbefore they laughed at your jokes\n\nYou were loved\nbefore they spoke those three words",
    story_id: 931,
    word_count: 20,
    all_intersection_count: 5,
    new_words_count: 15,
  },
  {
    row_new_words: [
      "there",
      "strength",
      "haven't",
      "even",
      "begun",
      "find",
      "often",
      "how",
      "life",
      "won't",
      "strong",
      "until",
      "world",
      "forces",
      "look",
    ],
    words: [
      "there",
      "is",
      "a",
      "strength",
      "in",
      "you",
      "haven't",
      "even",
      "begun",
      "to",
      "find",
      "and",
      "that's",
      "often",
      "how",
      "life",
      "works",
      "won't",
      "know",
      "strong",
      "are",
      "until",
      "the",
      "world",
      "forces",
      "look",
    ],
    link: "https://www.familyfriendpoems.com/poem/the-strength-in-you",
    storage_link: null,
    id: "There is\na strength in you\nyou haven’t even\nbegun to find.\n\nAnd that’s often\nhow life works.\n\nYou won’t know\nhow strong you are\nuntil the world\nforces you\nto look.",
    story_id: 917,
    word_count: 26,
    all_intersection_count: 11,
    new_words_count: 15,
  },
  {
    row_new_words: [
      "everything",
      "four",
      "inches",
      "long",
      "shiny",
      "pink",
      "eraser",
      "one",
      "end",
      "even",
      "uses",
      "write",
      "letters",
      "homework",
      "tests",
    ],
    words: [
      "a",
      "pencil",
      "for",
      "everything",
      "she",
      "has",
      "it",
      "is",
      "four",
      "inches",
      "long",
      "shiny",
      "and",
      "pink",
      "an",
      "eraser",
      "on",
      "one",
      "end",
      "even",
      "her",
      "name",
      "uses",
      "to",
      "write",
      "letters",
      "do",
      "homework",
      "tests",
    ],
    link: "https://www.eslfast.com/kidsenglish/ke/ke053.htm",
    storage_link: null,
    id: "A pencil for everything\n\nShe has a pencil. It is four inches long. It is shiny and pink. It has an eraser on one end. It even has her name on it. She uses her pencil for everything. She uses it to write letters. She uses it to do her homework. She uses it on her tests.",
    story_id: 376,
    word_count: 29,
    all_intersection_count: 14,
    new_words_count: 15,
  },
  {
    row_new_words: [
      "returning",
      "dress",
      "brings",
      "back",
      "store",
      "big",
      "smaller",
      "size",
      "returns",
      "gets",
      "sees",
      "another",
      "pretty",
      "cheaper",
      "than",
      "dresses",
    ],
    words: [
      "returning",
      "the",
      "dress",
      "she",
      "brings",
      "her",
      "back",
      "to",
      "store",
      "it",
      "is",
      "too",
      "big",
      "needs",
      "a",
      "smaller",
      "size",
      "does",
      "not",
      "have",
      "returns",
      "gets",
      "money",
      "sees",
      "another",
      "likes",
      "very",
      "pretty",
      "buys",
      "cheaper",
      "than",
      "other",
      "dresses",
    ],
    link: "https://www.eslfast.com/kidsenglish2/ke2/ke2057.htm",
    storage_link: null,
    id: "Returning the dress\n\nShe brings her dress back to the store. It is too big. She needs a smaller size. The store does not have a smaller size. She returns it. She gets her money back. She sees another dress she likes. It is very pretty. She buys it. It is cheaper than the other dresses.",
    story_id: 483,
    word_count: 33,
    all_intersection_count: 17,
    new_words_count: 16,
  },
  {
    row_new_words: [
      "lover",
      "Patricia",
      "romance",
      "novels",
      "stories",
      "poems",
      "history",
      "quickly",
      "reads",
      "3",
      "fantasy",
      "science",
      "fiction",
      "never",
      "those",
      "unless",
    ],
    words: [
      "a",
      "book",
      "lover",
      "Patricia",
      "loves",
      "reading",
      "books",
      "she",
      "likes",
      "romance",
      "novels",
      "short",
      "stories",
      "poems",
      "history",
      "can",
      "read",
      "quickly",
      "reads",
      "3",
      "week",
      "doesn't",
      "like",
      "fantasy",
      "or",
      "science",
      "fiction",
      "never",
      "those",
      "unless",
      "has",
      "to",
    ],
    link: "https://www.eslfast.com/gradedread1/gr/gr1072.htm",
    storage_link: null,
    id: "A book lover\n\nPatricia loves reading books. She likes romance novels. She likes short stories. She likes poems. She likes history books. She can read quickly. She reads 3 books a week. She doesn't like fantasy or science fiction novels. She never reads those unless she has to.",
    story_id: 296,
    word_count: 32,
    all_intersection_count: 16,
    new_words_count: 16,
  },
  {
    row_new_words: [
      "when",
      "put",
      "this",
      "shirt",
      "because",
      "buttons",
      "button",
      "up",
      "at",
      "end",
      "take",
      "off",
      "unbutton",
      "sweater",
      "zip",
      "unzip",
    ],
    words: [
      "when",
      "I",
      "put",
      "on",
      "this",
      "shirt",
      "because",
      "it",
      "has",
      "buttons",
      "need",
      "to",
      "button",
      "up",
      "the",
      "at",
      "end",
      "of",
      "day",
      "want",
      "take",
      "off",
      "unbutton",
      "sweater",
      "a",
      "zipper",
      "zip",
      "unzip",
    ],
    link: "https://www.youtube.com/shorts/A9VjViMlyh4",
    storage_link: "",
    id: "When I put on this shirt because it has buttons, I need to button up the shirt at the end of the day, when I want to take off the shirt, I need to unbutton the shirt when I put on this sweater because it has a zipper, I need to zip up the sweater at the end of the day, when I need to take off the sweater, I need to unzip the sweater.",
    story_id: 1092,
    word_count: 28,
    all_intersection_count: 12,
    new_words_count: 16,
  },
  {
    row_new_words: [
      "how",
      "much",
      "salt",
      "should",
      "put",
      "little",
      "many",
      "onions",
      "salad",
      "few",
      "pepper",
      "stew",
      "eggs",
      "omelette",
      "one",
      "two",
    ],
    words: [
      "how",
      "much",
      "salt",
      "should",
      "I",
      "put",
      "in",
      "the",
      "soup",
      "just",
      "a",
      "little",
      "not",
      "too",
      "many",
      "onions",
      "salad",
      "few",
      "pepper",
      "stew",
      "eggs",
      "omelette",
      "one",
      "or",
      "two",
    ],
    link: "(4)https://www.youtube.com/watch?v=WzZbCGxryIk&list=PLD7AA7B1BC72ACC28&index=4",
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/muchmany-4.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=SlpnSzj4%2BzrSfhetCbT8pve%2BsTnZMxajFdt4rBI8fjsMYZIoZBElVa6UDjNUPuvkfUcWhhTOQXO8GtlWglFd032VlgfYUmcx431lgYXTzMVVscdm6bNC5xWKB2HaA555Aoj8Aw8ujhGe%2FkQW1topS7rYzB%2Bp8xNBXkjCUzx3aLCq542w9DCJ0hd0YLAxiHsLIGKk42bhMpRi1QHDHfDcWdXWIvDKC0RsJBKG%2BPyxLAAn8ltWSk1tysqkFfw7wxVtJz3W4FefG7EYErRJuRhaq8VhNHNj5girvZ8C5uECGY3epWrty0ld%2Fg1PquvRCgJsepSDNrsWZ1jc1%2B4SVUcTSA%3D%3D",
    id: "How much salt should I put in the soup?\nJust a little, not too much.\nHow many onions should I put in the salad?\nJust a few, not too many.\nHow much pepper should I put in the stew?\nJust a little, not too much.\nHow many eggs should I put in the omelette?\nJust a few, not too many.\nSalt in the soup, pepper in the stew.\nEggs in the omelette.\nJust a few.\nJust a little, not too much.\nNot too many, just a few.\nJust a few, not too many, not too many.\nJust one or two.",
    story_id: 1020,
    word_count: 25,
    all_intersection_count: 9,
    new_words_count: 16,
  },
  {
    row_new_words: [
      "Incy",
      "Wincy",
      "spider",
      "climbed",
      "up",
      "spout",
      "down",
      "came",
      "rain",
      "washed",
      "poor",
      "out",
      "sunshine",
      "dried",
      "all",
      "again",
    ],
    words: [
      "Incy",
      "Wincy",
      "spider",
      "climbed",
      "up",
      "the",
      "water",
      "spout",
      "down",
      "came",
      "rain",
      "and",
      "washed",
      "poor",
      "out",
      "sunshine",
      "dried",
      "all",
      "again",
    ],
    link: "https://learnenglishkids.britishcouncil.org/listen-watch/songs/incy-wincy-spider",
    storage_link: null,
    id: "Incy Wincy spider\n\nIncy Wincy spider\nClimbed up the water spout\nDown came the rain\nAnd washed poor Incy out\nOut came the sunshine\nand dried up all the rain\nAnd Incy Wincy spider\nClimbed up the spout again.\n\nIncy Wincy spider\nClimbed up the water spout\nDown came the rain\nAnd washed poor Incy out\nOut came the sunshine\nand dried up all the rain\nAnd Incy Wincy spider\nClimbed up the spout again.",
    story_id: 872,
    word_count: 19,
    all_intersection_count: 3,
    new_words_count: 16,
  },
  {
    row_new_words: [
      "lot",
      "ants",
      "slice",
      "drops",
      "crumb",
      "smell",
      "crawl",
      "towards",
      "notices",
      "kill",
      "gets",
      "puts",
      "inside",
      "opens",
      "window",
      "lets",
    ],
    words: [
      "a",
      "lot",
      "of",
      "ants",
      "she",
      "eats",
      "slice",
      "cake",
      "drops",
      "crumb",
      "the",
      "can",
      "smell",
      "it",
      "they",
      "crawl",
      "towards",
      "notices",
      "does",
      "not",
      "want",
      "to",
      "kill",
      "them",
      "gets",
      "cup",
      "puts",
      "inside",
      "opens",
      "window",
      "lets",
      "go",
    ],
    link: "https://www.eslfast.com/kidsenglish/ke/ke018.htm",
    storage_link: null,
    id: "A lot of ants\n\nShe eats a slice of cake. She drops a crumb. The ants can smell it. They crawl towards the crumb. She notices the ants. She does not want to kill them. She gets a cup. She puts the ants inside. She opens the window. She lets the ants go.",
    story_id: 334,
    word_count: 32,
    all_intersection_count: 16,
    new_words_count: 16,
  },
  {
    row_new_words: [
      "waited",
      "until",
      "fall",
      "also",
      "call",
      "autumn",
      "make",
      "this",
      "english",
      "lesson",
      "hiking",
      "because",
      "best",
      "time",
      "year",
      "opinion",
    ],
    words: [
      "so",
      "I",
      "waited",
      "until",
      "fall",
      "or",
      "what",
      "we",
      "also",
      "call",
      "autumn",
      "to",
      "make",
      "this",
      "english",
      "lesson",
      "about",
      "hiking",
      "because",
      "is",
      "the",
      "best",
      "time",
      "of",
      "year",
      "in",
      "my",
      "opinion",
      "go",
    ],
    link: "(5)https://www.youtube.com/watch?v=hrGRog5BTjc&list=PLQut38RVINETJDG9hzlMPp62gDRCPKmrH&index=32",
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/5LetLearnEnglishonaHikeBob.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=x%2B1MFRAXIrEAAAsHY6zPnECq0UcSy5ytv1epR%2Fdm%2BEXKDjJNpiq3ncMe%2B6oKopRQ0pzWMBgYCrpv9HFd3Foy55b9xZkLnXbpWVJiKemKkE7loLj4YzJe2ZR4%2FdT8a%2Bsw4t19vrEeXZppmLbP5GODFIXgs2z7mvD1ZYvCDMprMmyA%2FcypR%2FQDBHFPMk0R9BR7rJ8XIMIIGuB6TadKOGWoBW3CmFeoyFqi54ySmZtt7F0FjUyqstlqDGfrQNYY0CAti%2BXgz2rI7asYkPwfgCv2Emx6lFDToQ4tOJUeJ1513thujENSCRSHV9HsspDPK4ATnnlX9mRQQz%2BdrQNqLTRQ9Q%3D%3D",
    id: "So I waited until fall, or what we also call autumn, to make this english lesson about hiking. I waited because this is the best time of year, in my opinion, to go hiking.",
    story_id: 1446,
    word_count: 29,
    all_intersection_count: 13,
    new_words_count: 16,
  },
  {
    row_new_words: [
      "goes",
      "nurse",
      "out",
      "around",
      "falls",
      "down",
      "hurts",
      "cries",
      "gets",
      "up",
      "looks",
      "friendly",
      "lollipop",
      "bandage",
      "back",
      "class",
    ],
    words: [
      "she",
      "goes",
      "to",
      "the",
      "nurse",
      "out",
      "play",
      "runs",
      "around",
      "falls",
      "down",
      "it",
      "hurts",
      "cries",
      "gets",
      "up",
      "is",
      "nice",
      "looks",
      "friendly",
      "gives",
      "her",
      "a",
      "lollipop",
      "tastes",
      "good",
      "bandage",
      "okay",
      "now",
      "walks",
      "back",
      "class",
    ],
    link: "https://www.eslfast.com/kidsenglish/ke/ke003.htm",
    storage_link: null,
    id: "She goes to the nurse\n\nShe goes out to play. She runs around. She falls down. It hurts. She cries. She gets up. She goes to the nurse. The nurse is nice. She looks friendly. The nurse gives her a lollipop. It tastes good. The nurse gives her a bandage. She is okay now. She walks back to class.",
    story_id: 326,
    word_count: 32,
    all_intersection_count: 16,
    new_words_count: 16,
  },
  {
    row_new_words: [
      "first",
      "song",
      "sits",
      "car",
      "dad",
      "turns",
      "radio",
      "taps",
      "feet",
      "sways",
      "head",
      "laughs",
      "at",
      "over",
      "different",
      "quietly",
    ],
    words: [
      "the",
      "first",
      "song",
      "she",
      "sits",
      "in",
      "car",
      "her",
      "dad",
      "turns",
      "on",
      "radio",
      "a",
      "plays",
      "taps",
      "feet",
      "sways",
      "head",
      "laughs",
      "at",
      "he",
      "likes",
      "too",
      "is",
      "over",
      "different",
      "does",
      "not",
      "like",
      "new",
      "quietly",
    ],
    link: "https://www.eslfast.com/kidsenglish/ke/ke006.htm",
    storage_link: null,
    id: "The first song\n\nShe sits in the car. Her dad turns on the radio. A song plays. She taps her feet. She sways her head. Her dad laughs at her. He likes the song too. The song is over. The radio plays a different song. She does not like the new song. She sits quietly.",
    story_id: 329,
    word_count: 31,
    all_intersection_count: 15,
    new_words_count: 16,
  },
  {
    row_new_words: [
      "smells",
      "hungry",
      "something",
      "coming",
      "leaves",
      "living-room",
      "goes",
      "sees",
      "plate",
      "hot",
      "gets",
      "fork",
      "knife",
      "cuts",
      "piece",
      "sticks",
    ],
    words: [
      "chicken",
      "smells",
      "good",
      "he",
      "is",
      "hungry",
      "something",
      "it",
      "coming",
      "from",
      "the",
      "kitchen",
      "leaves",
      "living-room",
      "goes",
      "to",
      "sees",
      "a",
      "plate",
      "of",
      "hot",
      "gets",
      "fork",
      "and",
      "knife",
      "cuts",
      "piece",
      "sticks",
      "in",
      "eats",
    ],
    link: "https://www.eslfast.com/kidsenglish2/ke2/ke2018.htm",
    storage_link: null,
    id: "Chicken smells good\n\nHe is hungry. He smells something good. It is coming from the kitchen. He leaves the living-room. He goes to the kitchen. He sees a plate of chicken. It is hot. He gets a fork and knife. He cuts a piece of chicken. He sticks the fork in it. He eats the chicken.",
    story_id: 430,
    word_count: 30,
    all_intersection_count: 14,
    new_words_count: 16,
  },
  {
    row_new_words: [
      "hot",
      "toppings",
      "there",
      "at",
      "baseball",
      "game",
      "one",
      "his",
      "dad",
      "himself",
      "ketchup",
      "mustard",
      "relish",
      "onions",
      "thinks",
      "sweet",
    ],
    words: [
      "hot",
      "dog",
      "toppings",
      "there",
      "are",
      "dogs",
      "at",
      "the",
      "baseball",
      "game",
      "he",
      "buys",
      "one",
      "for",
      "his",
      "dad",
      "and",
      "himself",
      "likes",
      "ketchup",
      "mustard",
      "on",
      "but",
      "no",
      "relish",
      "or",
      "onions",
      "thinks",
      "is",
      "too",
      "sweet",
    ],
    link: "https://www.eslfast.com/gradedread1/gr/gr1018.htm",
    storage_link: null,
    id: "Hot dog toppings\n\nThere are hot dogs at the baseball game. He buys one for his dad and one for himself. He likes ketchup and mustard on his hot dog, but no relish or onions. His dad likes onions and mustard on his hot dog, but no ketchup or relish. He thinks ketchup is too sweet.",
    story_id: 235,
    word_count: 31,
    all_intersection_count: 15,
    new_words_count: 16,
  },
  {
    row_new_words: [
      "moving",
      "out",
      "another",
      "state",
      "excited",
      "also",
      "sad",
      "place",
      "life",
      "leave",
      "family",
      "friends",
      "will",
      "miss",
      "lot",
      "things",
    ],
    words: [
      "moving",
      "out",
      "she",
      "is",
      "to",
      "another",
      "state",
      "excited",
      "also",
      "sad",
      "be",
      "in",
      "a",
      "new",
      "place",
      "meet",
      "people",
      "start",
      "life",
      "leave",
      "her",
      "family",
      "friends",
      "will",
      "miss",
      "lot",
      "of",
      "things",
    ],
    link: "https://www.eslfast.com/kidsenglish2/ke2/ke2100.htm",
    storage_link: null,
    id: "Moving out\n\nShe is moving to another state. She is excited. She is also sad. She is excited to be in a new place. She is excited to meet new people. She is excited to start a new life. She is sad to leave her family. She is sad to leave her friends. She will miss a lot of things.",
    story_id: 519,
    word_count: 28,
    all_intersection_count: 12,
    new_words_count: 16,
  },
  {
    row_new_words: [
      "Johnny",
      "getting",
      "dressed",
      "any",
      "I'll",
      "glad",
      "get",
      "by",
      "himself",
      "Cathy",
      "fixing",
      "bicycle",
      "mom",
      "fix",
      "herself",
      "oh",
    ],
    words: [
      "what's",
      "Johnny",
      "doing",
      "he's",
      "getting",
      "dressed",
      "does",
      "he",
      "need",
      "any",
      "help",
      "I'll",
      "be",
      "glad",
      "to",
      "him",
      "no",
      "that's",
      "okay",
      "can",
      "get",
      "by",
      "himself",
      "Cathy",
      "she's",
      "fixing",
      "her",
      "bicycle",
      "she",
      "mom",
      "fix",
      "herself",
      "oh",
    ],
    link: "https://www.youtube.com/watch?v=PQCB85N52QE&list=PLD7AA7B1BC72ACC28&index=24",
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/pronoun.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=x1hhjZnrvY3F4NA2gHE9kKYseGabk%2Fr%2BM9SxaCAvnGo8RVj24VAcjAxZX5kxMYbaOgSxk28WkpOic%2FkF5SpaTRQiq%2FHrO%2FAm1BrUCr9YAJ8rm9j1SVhcTQmJUyhQYX6%2BNOEYlyFKMVd1WCJ7we0qqjcZ%2BQzAMSj9%2F7NgxVhFxqcTZyxuSbZurBpOz2xJGLY0eE35hPnhRlXpMtLGXaMKqXQQq5iB3S5GO7p%2F0Okv2z12KrzYcD%2BEGRXN4ZHv6LcaCARXHn8Z2v0I86Ff0%2BL7E4PAHHlGzDnCsGzhtnI9o86xKKGtvtSCDsW9BMCpVU50EsH8DmltAdZMsfqg%2BSOCCA%3D%3D",
    id: "What's Johnny doing?\nHe's getting dressed.\nDoes he need any help?\nI'll be glad to help him.\nNo, that's okay. He can get dressed by himself.\n\nWhat's Cathy doing?\nShe's fixing her bicycle.\nDoes she need any help? I'll be glad to help her.\nNo, that's okay, mom. She can fix her bicycle by herself.\nOh, okay.",
    story_id: 1068,
    word_count: 33,
    all_intersection_count: 17,
    new_words_count: 16,
  },
  {
    row_new_words: [
      "lessons",
      "sound",
      "look",
      "taking",
      "class",
      "5",
      "students",
      "all",
      "beginners",
      "skilled",
      "violinist",
      "been",
      "20",
      "years",
      "excited",
      "come",
    ],
    words: [
      "violin",
      "lessons",
      "Betty",
      "loves",
      "the",
      "sound",
      "of",
      "she",
      "likes",
      "look",
      "it",
      "too",
      "is",
      "taking",
      "now",
      "her",
      "class",
      "has",
      "5",
      "other",
      "students",
      "they",
      "are",
      "all",
      "beginners",
      "teacher",
      "a",
      "skilled",
      "violinist",
      "he",
      "been",
      "playing",
      "for",
      "20",
      "years",
      "excited",
      "to",
      "come",
      "every",
      "week",
    ],
    link: "https://www.eslfast.com/gradedread1/gr/gr1063.htm",
    storage_link: null,
    id: "Violin lessons\n\nBetty loves the sound of the violin. She likes the look of it too. She is taking violin lessons now. Her violin class has 5 other students. They are all beginners. The teacher is a skilled violinist. He has been playing violin for 20 years. Betty is excited to come to the class every week.",
    story_id: 280,
    word_count: 40,
    all_intersection_count: 24,
    new_words_count: 16,
  },
  {
    row_new_words: [
      "breaking",
      "up",
      "boyfriend",
      "lazy",
      "never",
      "anything",
      "job",
      "TV",
      "video",
      "games",
      "break",
      "hurt",
      "his",
      "feelings",
      "plans",
      "out",
    ],
    words: [
      "breaking",
      "up",
      "she",
      "does",
      "not",
      "like",
      "her",
      "boyfriend",
      "he",
      "is",
      "lazy",
      "never",
      "anything",
      "for",
      "have",
      "a",
      "job",
      "go",
      "to",
      "school",
      "just",
      "watches",
      "TV",
      "and",
      "plays",
      "video",
      "games",
      "wants",
      "break",
      "with",
      "him",
      "want",
      "hurt",
      "his",
      "feelings",
      "plans",
      "out",
      "what",
      "going",
      "say",
    ],
    link: "https://www.eslfast.com/kidsenglish2/ke2/ke2063.htm",
    storage_link: null,
    id: "Breaking up\n\nShe does not like her boyfriend. He is lazy. He never does anything for her. He does not have a job. He does not go to school. He just watches TV and plays video games. She wants to break up with him. She does not want to hurt his feelings. She plans out what she is going to say.",
    story_id: 475,
    word_count: 40,
    all_intersection_count: 24,
    new_words_count: 16,
  },
  {
    row_new_words: [
      "something",
      "use",
      "shoulders",
      "shrug",
      "asks",
      "question",
      "answer",
      "thing",
      "rhymes",
      "hug",
      "when",
      "this",
      "someone",
      "put",
      "arms",
      "around",
    ],
    words: [
      "if",
      "you",
      "don't",
      "know",
      "something",
      "can",
      "use",
      "your",
      "shoulders",
      "to",
      "shrug",
      "the",
      "teacher",
      "asks",
      "a",
      "question",
      "and",
      "answer",
      "just",
      "say",
      "I",
      "other",
      "thing",
      "do",
      "that",
      "rhymes",
      "with",
      "is",
      "hug",
      "that's",
      "when",
      "go",
      "like",
      "this",
      "someone",
      "put",
      "arms",
      "around",
      "them",
      "so",
      "they",
    ],
    link: "(5)https://www.youtube.com/watch?v=vA-uEPEHU_M&list=PLQut38RVINERTw9It9-fkKY8jgNEJ5AHC",
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/shrug.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=QD%2BdgtUIi6q8764pKu5Th1mZQiiouExIEP6pFVXXOcBPCOdq3%2FGh4v3hTiGaolYGqSePrkpWoVHrMTkI2bHL2hVCebOJ8LiPkTrCDUFMs96CRyNyNzsiW2Ew76KCrM%2Bh%2BmyFOR0w7tJ5mBURpS9fyitLe8yugsX0ewsJAGqb%2FtbYr43ePTLKxy4%2FAOMIsxAiB6qrrLkjHshQoZhLvErWgnjfU%2F%2F75VXeogIQweUPvJsB62qsqA9%2FP3%2F5QnKUwQCwFpjCfT7OFYipOIO9QNhFn0TYix32pIJcDJC4%2FfeVrsemdv4bYmjDK3ytB5%2FKCCj3F8QYpjIxcAHmoDRsrBq54g%3D%3D",
    id: "If you don't know something, you can use your shoulders to shrug. If the teacher asks you a question and you don't know the answer, you can just say, I don't know. The other thing you can do that rhymes with shrug is hug. That's when you go like this to someone and you just put your arms around them so that they know that you like.",
    story_id: 1116,
    word_count: 41,
    all_intersection_count: 25,
    new_words_count: 16,
  },
  {
    row_new_words: [
      "touch",
      "big",
      "German",
      "Shepherd",
      "around",
      "town",
      "strangers",
      "up",
      "pet",
      "without",
      "asking",
      "gets",
      "mad",
      "touching",
      "pulls",
      "away",
    ],
    words: [
      "don't",
      "touch",
      "the",
      "dog",
      "she",
      "has",
      "a",
      "big",
      "it",
      "is",
      "German",
      "Shepherd",
      "loves",
      "her",
      "walks",
      "around",
      "town",
      "strangers",
      "always",
      "go",
      "up",
      "to",
      "they",
      "pet",
      "without",
      "asking",
      "gets",
      "mad",
      "does",
      "not",
      "like",
      "people",
      "touching",
      "pulls",
      "away",
      "from",
    ],
    link: "https://www.eslfast.com/kidsenglish2/ke2/ke2045.htm",
    storage_link: null,
    id: "Don't touch the dog\n\nShe has a big dog. It is a German Shepherd. She loves her dog. She walks it around town. Strangers always go up to her dog. They pet it. They pet it without asking. She gets mad. She does not like people touching her dog. She pulls her dog away from people.",
    story_id: 457,
    word_count: 36,
    all_intersection_count: 20,
    new_words_count: 16,
  },
  {
    row_new_words: [
      "shout",
      "classroom",
      "throw",
      "pens",
      "try",
      "polite",
      "homework",
      "night",
      "arrive",
      "time",
      "each",
      "friendly",
      "when",
      "class",
      "playground",
      "fun",
    ],
    words: [
      "please",
      "don't",
      "shout",
      "in",
      "the",
      "classroom",
      "and",
      "throw",
      "your",
      "pens",
      "about",
      "always",
      "try",
      "to",
      "be",
      "polite",
      "do",
      "homework",
      "every",
      "night",
      "arrive",
      "on",
      "time",
      "each",
      "day",
      "friendly",
      "when",
      "you",
      "play",
      "class",
      "jump",
      "or",
      "run",
      "but",
      "playground",
      "have",
      "some",
      "fun",
    ],
    link: "https://learnenglishkids.britishcouncil.org/grammar-vocabulary/grammar-chants/please-dont-shout",
    storage_link: null,
    id: "Please don't shout\n\nIn the classroom, please don't shout\nAnd please don't throw your pens about\nAlways try to be polite\nAnd do your homework every night.\n\nPlease arrive on time each day\nAnd please be friendly when you play\nIn the class, don't jump or run\nBut in the playground, have some fun!",
    story_id: 838,
    word_count: 38,
    all_intersection_count: 22,
    new_words_count: 16,
  },
  {
    row_new_words: [
      "as",
      "there's",
      "bear",
      "patch",
      "lawn",
      "right",
      "here",
      "seed",
      "sprinkle",
      "spread",
      "more",
      "this",
      "little",
      "bit",
      "hope",
      "grows",
    ],
    words: [
      "so",
      "as",
      "you",
      "can",
      "see",
      "there's",
      "a",
      "bear",
      "patch",
      "in",
      "my",
      "lawn",
      "right",
      "here",
      "I",
      "have",
      "some",
      "grass",
      "seed",
      "I'm",
      "going",
      "to",
      "sprinkle",
      "spread",
      "is",
      "more",
      "like",
      "this",
      "where",
      "do",
      "little",
      "bit",
      "but",
      "and",
      "hope",
      "that",
      "it",
      "grows",
    ],
    link: "(2)https://youtu.be/VH-t4I7Cepg?si=6KHRPaTs5DXUORKk",
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/2learn30englishactionverbsbob.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=byU4cP1tVfZqA04a6KuXD1LUvY5OlIIuIdtahHn4viu8o7hUkxcXubaGVYbWqCRLMXeDwS1KP5SaSe4xC4HWTotAT5s%2FTbccGD3ehcgr9KSmZAQ6BnxMH75D4eXXOW45u8R3gTIx%2FcqJ1IEUhtxlpnpwKimRC3oiZstofDiFRVBUDv%2FG1bc7ZPds69Dw7coiKfzWG3CKdGv0Fc8uhCePK616uTyolvJVbBwB7xeriwCU%2BGXZZtH84Hi9a1o1LXpO9MLsUXFkiwo3ibvk2tuDb0qzZDd36SnvOGQdLSGMvjFXg53D1V756qBTj8MzuCGBXWGV37FYMMsgx1xhRiU7Lw%3D%3D",
    id: "So as you can see, there's a bear patch in my lawn right here. So I have some grass seed. I'm going to sprinkle some grass seed here. I'm going to spread some grass seed. Sprinkle is more like this, where you do a little bit spread is more like this, where you do a bit more. But I'm going to sprinkle some grass seed here. I'm going to spread some grass seed here and hope that it grows.",
    story_id: 1327,
    word_count: 38,
    all_intersection_count: 22,
    new_words_count: 16,
  },
  {
    row_new_words: [
      "taking",
      "tests",
      "test",
      "tomorrow",
      "instead",
      "video",
      "games",
      "next",
      "takes",
      "hard",
      "anything",
      "turns",
      "his",
      "blank",
      "scolds",
      "F",
    ],
    words: [
      "taking",
      "tests",
      "he",
      "has",
      "a",
      "test",
      "tomorrow",
      "does",
      "not",
      "want",
      "to",
      "study",
      "instead",
      "plays",
      "video",
      "games",
      "the",
      "next",
      "day",
      "takes",
      "it",
      "is",
      "very",
      "hard",
      "know",
      "anything",
      "turns",
      "in",
      "his",
      "blank",
      "teacher",
      "scolds",
      "him",
      "and",
      "gives",
      "an",
      "F",
    ],
    link: "https://www.eslfast.com/kidsenglish/ke/ke033.htm",
    storage_link: null,
    id: 'Taking tests\n\nHe has a test tomorrow. He does not want to study. Instead, he plays video games. The next day, he takes the test. It is very hard. He does not know anything. He turns in his test blank. The teacher scolds him and gives him an "F."',
    story_id: 363,
    word_count: 37,
    all_intersection_count: 21,
    new_words_count: 16,
  },
  {
    row_new_words: [
      "lonely",
      "everything",
      "alone",
      "TV",
      "by",
      "herself",
      "even",
      "family",
      "at",
      "restaurants",
      "school's",
      "basketball",
      "team",
      "talk",
      "players",
      "game",
    ],
    words: [
      "the",
      "lonely",
      "girl",
      "she",
      "is",
      "does",
      "everything",
      "alone",
      "watches",
      "TV",
      "by",
      "herself",
      "not",
      "even",
      "like",
      "watching",
      "with",
      "her",
      "family",
      "eats",
      "at",
      "restaurants",
      "on",
      "school's",
      "basketball",
      "team",
      "talk",
      "to",
      "other",
      "players",
      "just",
      "plays",
      "game",
    ],
    link: "https://www.eslfast.com/kidsenglish2/ke2/ke2064.htm",
    storage_link: null,
    id: "The lonely girl\n\nShe is lonely. She does everything alone. She watches TV by herself. She does not even like watching TV with her family. She even eats alone at restaurants. She is on her school's basketball team. She does not talk to the other players. She just plays the game.",
    story_id: 476,
    word_count: 33,
    all_intersection_count: 17,
    new_words_count: 16,
  },
  {
    row_new_words: [
      "busy",
      "washing",
      "hair",
      "roger",
      "cleaning",
      "his",
      "Julie",
      "fixing",
      "bicycle",
      "our",
      "exercises",
      "Tom",
      "Susan",
      "painting",
      "their",
      "garage",
    ],
    words: [
      "are",
      "you",
      "busy",
      "yes",
      "I",
      "am",
      "I'm",
      "washing",
      "my",
      "hair",
      "is",
      "roger",
      "he",
      "he's",
      "cleaning",
      "his",
      "kitchen",
      "Julie",
      "she",
      "she's",
      "fixing",
      "her",
      "bicycle",
      "we",
      "we're",
      "doing",
      "our",
      "exercises",
      "Tom",
      "and",
      "Susan",
      "they",
      "they're",
      "painting",
      "their",
      "garage",
    ],
    link: "(2)https://www.youtube.com/watch?v=OLieQ0U3TA4&t=181s",
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/Present%20Continuous.%20Question%20form%205.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=mGwyDKiG2j8jvfnQGvR4n0S2y%2FC4Zogxw8deYnlqgjLB7xSNXJw%2FFOHfUgWUlaJlrB0w3PHXgUNAzK6tASaTYhQ%2BKBLJ%2B6jVmvFUygbvC3DjDtW%2BuqcXZwUXF%2FR8JGsmd3YZmEfJEp0%2FaGfiiJ2mcWRIGy8FUlY7sRYqRKY%2Be%2FK%2BukUk1KJSqRoqdwVkJB%2BvrLvi6HPwNSCW0OUT9N3dm2MrgEl5ax1xdc%2FqDpYFTR%2FEe0ot%2FsF%2BVB7%2B5HIsoNMpsOXkbSu%2FpEWeo1Z1bq5PR8jboVV7SRWaYD8RQdwSBsEpph1GAig8c6Bmsfteo9ecHxLVpl01GSHfsbW7ZG7XBQ%3D%3D",
    id: "Are you busy?\nYes, I am.\nI'm washing my hair.\nIs Roger busy.\nYes, he is. He's cleaning his kitchen.\nIs Julie busy. Yes, she is. She's fixing her bicycle\nAre you busy? Yes, we are.\nWe're doing our exercises.\nAre Tom and Susan busy.\nYes, they are. They're painting their garage.",
    story_id: 958,
    word_count: 36,
    all_intersection_count: 20,
    new_words_count: 16,
  },
  {
    row_new_words: [
      "snack",
      "shop",
      "Kevin",
      "hungry",
      "goes",
      "sees",
      "potato",
      "chips",
      "much",
      "trying",
      "sweets",
      "avoid",
      "cavities",
      "healthy",
      "messy",
      "25",
      "cents",
    ],
    words: [
      "the",
      "snack",
      "shop",
      "Kevin",
      "is",
      "hungry",
      "he",
      "goes",
      "to",
      "sees",
      "potato",
      "chips",
      "doesn't",
      "like",
      "that",
      "much",
      "candy",
      "trying",
      "not",
      "eat",
      "sweets",
      "avoid",
      "cavities",
      "a",
      "banana",
      "likes",
      "bananas",
      "they",
      "are",
      "healthy",
      "it's",
      "messy",
      "buys",
      "for",
      "25",
      "cents",
    ],
    link: "https://www.eslfast.com/gradedread1/gr/gr1023.htm",
    storage_link: null,
    id: "The snack shop\n\nKevin is hungry. He goes to the snack shop. He sees potato chips. He doesn't like potato chips that much. He sees candy. He is trying not to eat sweets to avoid cavities. He sees a banana. He likes bananas. They are healthy. It's not messy to eat. He buys a banana for 25 cents.",
    story_id: 247,
    word_count: 36,
    all_intersection_count: 19,
    new_words_count: 17,
  },
  {
    row_new_words: [
      "I've",
      "got",
      "big",
      "red",
      "bike",
      "ride",
      "when",
      "brother's",
      "one",
      "paint",
      "blue",
      "sister's",
      "green",
      "keep",
      "clean",
      "our",
      "bikes",
    ],
    words: [
      "I've",
      "got",
      "a",
      "big",
      "red",
      "bike",
      "I",
      "can",
      "ride",
      "it",
      "when",
      "like",
      "my",
      "brother's",
      "one",
      "too",
      "and",
      "now",
      "he",
      "wants",
      "to",
      "paint",
      "blue",
      "sister's",
      "that's",
      "green",
      "she",
      "likes",
      "keep",
      "very",
      "clean",
      "we",
      "love",
      "our",
      "bikes",
      "they're",
      "nice",
      "new",
      "have",
      "you",
    ],
    link: "https://learnenglishkids.britishcouncil.org/grammar-vocabulary/grammar-chants/ive-got-big-red-bike",
    storage_link: null,
    id: "I've got a big red bike\nI can ride it when I like\nMy brother's got a red one too\nAnd now he wants to paint it blue.\n\nMy sister's got a bike that's green\nShe likes to keep it very clean\nWe love our bikes, they're nice and new\nHave you got a nice bike too?",
    story_id: 828,
    word_count: 40,
    all_intersection_count: 23,
    new_words_count: 17,
  },
  {
    row_new_words: [
      "make",
      "hot",
      "cold",
      "pizza",
      "opens",
      "refrigerator",
      "reaches",
      "frozen",
      "puts",
      "microwave",
      "heats",
      "five",
      "minutes",
      "his",
      "gets",
      "bottle",
      "warm",
    ],
    words: [
      "make",
      "it",
      "hot",
      "cold",
      "he",
      "wants",
      "to",
      "eat",
      "some",
      "pizza",
      "opens",
      "the",
      "refrigerator",
      "reaches",
      "for",
      "frozen",
      "is",
      "be",
      "puts",
      "in",
      "microwave",
      "heats",
      "five",
      "minutes",
      "his",
      "sister",
      "drink",
      "water",
      "she",
      "gets",
      "a",
      "bottle",
      "of",
      "from",
      "kitchen",
      "warm",
    ],
    link: "https://www.eslfast.com/kidsenglish/ke/ke087.htm",
    storage_link: null,
    id: "Make it hot, make it cold\n\nHe wants to eat some pizza. He opens the refrigerator. He reaches for the frozen pizza. It is cold. He wants it to be hot. He puts it in the microwave. He heats the pizza for five minutes. His sister wants to drink cold water. She gets a bottle of water from the kitchen. It is warm. She puts it in the refrigerator.",
    story_id: 399,
    word_count: 36,
    all_intersection_count: 19,
    new_words_count: 17,
  },
  {
    row_new_words: [
      "practicing",
      "drawing",
      "practice",
      "saturday",
      "Sunday",
      "shows",
      "things",
      "how",
      "draw",
      "objects",
      "get",
      "better",
      "parents",
      "tell",
      "artist",
      "as",
      "job",
    ],
    words: [
      "practicing",
      "drawing",
      "I",
      "am",
      "practice",
      "every",
      "saturday",
      "and",
      "Sunday",
      "my",
      "teacher",
      "shows",
      "me",
      "new",
      "things",
      "know",
      "how",
      "to",
      "draw",
      "people",
      "animals",
      "objects",
      "now",
      "always",
      "want",
      "get",
      "better",
      "so",
      "day",
      "parents",
      "tell",
      "that",
      "a",
      "good",
      "artist",
      "they",
      "think",
      "can",
      "do",
      "it",
      "as",
      "job",
    ],
    link: "https://www.eslfast.com/kidsenglish/ke/ke031.htm",
    storage_link: null,
    id: "Practicing drawing\n\nI am practicing drawing. I practice drawing every Saturday and Sunday. My teacher shows me new things. I know how to draw people, animals, and objects now. I always want to get better, so I practice every day. My parents always tell me that I am a good artist. They think I can do it as a job.",
    story_id: 354,
    word_count: 42,
    all_intersection_count: 25,
    new_words_count: 17,
  },
  {
    row_new_words: [
      "lot",
      "line",
      "went",
      "amusement",
      "there",
      "rides",
      "scary",
      "kids",
      "food",
      "choices",
      "cotton",
      "corn",
      "many",
      "saturday",
      "off",
      "lines",
      "everything",
    ],
    words: [
      "a",
      "lot",
      "of",
      "people",
      "in",
      "line",
      "they",
      "went",
      "to",
      "an",
      "amusement",
      "park",
      "there",
      "are",
      "rides",
      "some",
      "scary",
      "and",
      "for",
      "kids",
      "food",
      "choices",
      "is",
      "cotton",
      "candy",
      "corn",
      "dogs",
      "so",
      "many",
      "it",
      "saturday",
      "off",
      "work",
      "school",
      "lines",
      "everything",
    ],
    link: "https://www.eslfast.com/kidsenglish2/ke2/ke2068.htm",
    storage_link: null,
    id: "A lot of people in line\n\nThey went to an amusement park. There are a lot of rides. Some are scary and some are for kids. There are a lot of food choices. There is cotton candy. There are corn dogs. There are so many people. It is Saturday. People are off work and school. There are lines for everything.",
    story_id: 480,
    word_count: 36,
    all_intersection_count: 19,
    new_words_count: 17,
  },
  {
    row_new_words: [
      "unhealthy",
      "fat",
      "only",
      "red",
      "chips",
      "his",
      "parents",
      "ban",
      "fattening",
      "foods",
      "cook",
      "healthy",
      "meals",
      "oatmeal",
      "carrots",
      "salad",
      "dinner",
    ],
    words: [
      "an",
      "unhealthy",
      "boy",
      "he",
      "is",
      "fat",
      "only",
      "eats",
      "red",
      "meat",
      "chips",
      "and",
      "candy",
      "his",
      "parents",
      "worry",
      "about",
      "him",
      "they",
      "ban",
      "from",
      "eating",
      "fattening",
      "foods",
      "cook",
      "healthy",
      "meals",
      "now",
      "on",
      "eat",
      "oatmeal",
      "for",
      "breakfast",
      "fish",
      "carrots",
      "vegetables",
      "lunch",
      "chicken",
      "salad",
      "dinner",
    ],
    link: "https://www.eslfast.com/kidsenglish/ke/ke041.htm",
    storage_link: null,
    id: "An unhealthy boy\n\nHe is fat. He only eats red meat, chips, and candy. His parents worry about him. They ban him from eating fattening foods. They cook healthy meals only from now on. They eat oatmeal for breakfast. They eat fish, carrots, and vegetables for lunch. They eat chicken and salad for dinner.",
    story_id: 357,
    word_count: 40,
    all_intersection_count: 23,
    new_words_count: 17,
  },
  {
    row_new_words: [
      "right",
      "because",
      "behind",
      "something",
      "probably",
      "pretty",
      "easy",
      "figure",
      "out",
      "especially",
      "wave",
      "move",
      "over",
      "here",
      "though",
      "front",
      "back",
    ],
    words: [
      "you",
      "can't",
      "see",
      "me",
      "right",
      "now",
      "because",
      "I",
      "am",
      "behind",
      "something",
      "it's",
      "probably",
      "pretty",
      "easy",
      "to",
      "figure",
      "out",
      "what",
      "especially",
      "if",
      "wave",
      "the",
      "tree",
      "move",
      "over",
      "here",
      "though",
      "in",
      "front",
      "of",
      "and",
      "is",
      "go",
      "back",
    ],
    link: "(12)https://www.youtube.com/watch?v=_UwT0tC5JTk",
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/12prepositionsofplacebob.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=xS8G%2F1W69r4%2F9kA11zJ%2BYNLj%2Ful3KLHhW0zdZE%2FEs2UYVfW2Tg4arK%2Bf2y7RCMlWpf7ivtfoq1nYF9rWjhrhpIdh1e28FTN3SMQWY8SgQmr20obcf7kdkDtUFDdodB1x%2FHZllfY02W0Yg9B6gGIrznKQ8AcEht4eIsG1X8hRF3q0UnVdS%2F4Pns3%2BwWu9n2o6CZ8ZWGr3t28YFj%2B4N4g6gnbD2B3WXZaib43Jals6A3zy6Cq0TTh0hiVfNS2BUyTPx30cs48JgPbNqDWaeFZcBuR4XZYTRxMx3NvX1Dud3DgoPClH%2F9%2FCxCIWlgZFaU9Vv1GYe4HXIvgRVBJ77q%2BJUQ%3D%3D",
    id: "You can't see me right now because I am behind something. It's probably pretty easy to figure out what I am behind, especially if I wave. Right now, I am behind the tree. If I move over here, though, I am now in front of the tree. I am in front of the tree and the tree is behind me. If I go back over here, though, now I am behind the tree and the tree is in front of me.",
    story_id: 1267,
    word_count: 35,
    all_intersection_count: 18,
    new_words_count: 17,
  },
  {
    row_new_words: [
      "18th",
      "birthday",
      "18",
      "lot",
      "cigarettes",
      "bad",
      "though",
      "full-time",
      "throws",
      "party",
      "herself",
      "come",
      "gets",
      "gifts",
      "cousin",
      "pack",
      "says",
    ],
    words: [
      "the",
      "18th",
      "birthday",
      "she",
      "is",
      "now",
      "18",
      "can",
      "do",
      "a",
      "lot",
      "buy",
      "cigarettes",
      "are",
      "bad",
      "for",
      "you",
      "though",
      "work",
      "full-time",
      "throws",
      "party",
      "herself",
      "of",
      "people",
      "come",
      "gets",
      "gifts",
      "her",
      "cousin",
      "gives",
      "pack",
      "says",
      "does",
      "not",
      "want",
      "it",
    ],
    link: "https://www.eslfast.com/kidsenglish2/ke2/ke2089.htm",
    storage_link: null,
    id: "The 18th birthday\n\nShe is now 18. She can do a lot now. She can buy cigarettes. Cigarettes are bad for you, though. She can work full-time. She throws a party for herself. A lot of people come. She gets a lot of gifts. Her cousin gives her a pack of cigarettes. She says she does not want it.",
    story_id: 501,
    word_count: 37,
    all_intersection_count: 20,
    new_words_count: 17,
  },
  {
    row_new_words: [
      "had",
      "yearly",
      "checkup",
      "did",
      "told",
      "little",
      "heavy",
      "must",
      "lose",
      "weight",
      "stop",
      "ice",
      "cream",
      "mustn't",
      "as",
      "much",
      "before",
    ],
    words: [
      "I",
      "had",
      "my",
      "yearly",
      "checkup",
      "today",
      "what",
      "did",
      "the",
      "doctor",
      "say",
      "she",
      "told",
      "me",
      "I'm",
      "a",
      "little",
      "too",
      "heavy",
      "and",
      "must",
      "lose",
      "some",
      "weight",
      "do",
      "you",
      "have",
      "to",
      "stop",
      "eating",
      "ice",
      "cream",
      "no",
      "but",
      "mustn't",
      "eat",
      "as",
      "much",
      "before",
    ],
    link: "(3)https://www.youtube.com/watch?v=DR75vAgVbqk&list=PLD7AA7B1BC72ACC28&index=20",
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/doctor6.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=I6zPoo8VEFfNpAdjnmVaN96wpT5eFy8t7HFbGYh89dYHW3IGK6VePpOfEOBSMSujwH6x6SAi3shI7nHQo%2BJbN%2BuN5zOGoaItS0vDxMbK5lOEPQQ0fUGPGkNZHdIRWoZ3yMYgkyAyEC8P3ZtJjTds5UvA7%2FkzwiClxx9w3v%2B1Y0mvmE2ml5ZS2MZAVDMKtS48QXvr4k78bVcosnl00osowEBnkQr2AZX5bYA4A6XfYAEnaP45vBi0wNRGIss5kKg%2F7fRSjddWtx3NsSrJahMxX3FBXj%2BHqKUHQ3nfCqlZQ5lc5m8cmNbfN6t%2B3GipGL%2FBotYfDlDp1aT9t8AtWlwf7A%3D%3D",
    id: " I had my yearly checkup today.\nWhat did the doctor say?\nShe told me I'm a little too heavy and I must lose some weight.\nDo you have to stop eating ice cream?\nNo, but I mustn't eat as much ice cream as I did before.",
    story_id: 1057,
    word_count: 39,
    all_intersection_count: 22,
    new_words_count: 17,
  },
  {
    row_new_words: [
      "pouch",
      "interesting",
      "kangaroo's",
      "baby",
      "goes",
      "into",
      "safe",
      "there",
      "James",
      "would",
      "put",
      "food",
      "video",
      "games",
      "inside",
      "kangaroos",
      "different",
    ],
    words: [
      "the",
      "pouch",
      "of",
      "a",
      "kangaroo",
      "is",
      "an",
      "interesting",
      "animal",
      "it",
      "has",
      "kangaroo's",
      "baby",
      "goes",
      "into",
      "safe",
      "there",
      "James",
      "would",
      "like",
      "to",
      "have",
      "he",
      "not",
      "put",
      "in",
      "food",
      "and",
      "video",
      "games",
      "inside",
      "people",
      "kangaroos",
      "are",
      "different",
    ],
    link: "https://www.eslfast.com/kidsenglish/ke/ke063.htm",
    storage_link: null,
    id: "The pouch of a kangaroo\n\nA kangaroo is an interesting animal. It has a pouch. A kangaroo's baby goes into the pouch. It is safe there. James would like to have a pouch. He would not put a baby in there. He would put food and video games inside. People and kangaroos are different.",
    story_id: 379,
    word_count: 35,
    all_intersection_count: 18,
    new_words_count: 17,
  },
  {
    row_new_words: [
      "when",
      "car",
      "might",
      "enough",
      "situation",
      "get",
      "loan",
      "bank",
      "even",
      "dealership",
      "will",
      "lend",
      "then",
      "pay",
      "back",
      "maybe",
      "brand-new",
    ],
    words: [
      "now",
      "when",
      "you",
      "go",
      "to",
      "buy",
      "a",
      "car",
      "might",
      "not",
      "have",
      "enough",
      "money",
      "in",
      "that",
      "situation",
      "what",
      "need",
      "do",
      "is",
      "get",
      "loan",
      "the",
      "bank",
      "or",
      "even",
      "dealership",
      "will",
      "lend",
      "so",
      "can",
      "and",
      "then",
      "pay",
      "them",
      "back",
      "maybe",
      "brand-new",
      "but",
      "don't",
      "you're",
      "going",
      "it",
      "from",
    ],
    link: "(3)https://www.youtube.com/watch?v=hDGK1BCeP74&list=PLQut38RVINETJDG9hzlMPp62gDRCPKmrH&index=6",
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/3enoughmoneyforcarcarloan.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=pTSMX9CIT5NDeJAHB%2BUhVB7hob%2B9F5N3dXDivBvYTQGB5Z2N1q2nKg%2FSnQjbsMzYL5yRPpHzJOJDvAPT%2FTwmEZdeUFNOrpIeg0I%2B0pL6Tm2KIungTIxY4V7U64IPdqHJ3ps7FpRSBgXSHG8zO6rzUV%2BG%2Fynz0TAR1055VvBA9ubSDx4xJv0Nh%2B8ffAuWjJ91KQ9VV56L4260rBz8Fz7pf7PHTkmaRq9yYo33yQNbUGyzdOcq1PCGMTwU0JlBQfa7tZTuE2d2ScK0wwVmwVbfP%2FIgEpKc2NT5%2BjmXTV3WjujMrTDBdiw6%2B1uE3X1MkDDldKzDrOhNY32utS3No%2FySHA%3D%3D",
    id: "Now, when you go to buy a car, you might not have enough money. In that situation, what you might need to do is you might need to get a car loan. A car loan is when the bank or even the car dealership will lend you money so that you can buy the car, and then you need to pay them back. So maybe you have enough money. Maybe you have enough money to buy a brand-new car, but maybe you don't. In that situation, you're going to need a car loan, and you're going to need to get it from the car dealership or from the bank.",
    story_id: 1172,
    word_count: 44,
    all_intersection_count: 27,
    new_words_count: 17,
  },
  {
    row_new_words: [
      "there's",
      "something",
      "wrong",
      "washing",
      "machine",
      "anything",
      "machines",
      "anybody",
      "really",
      "should",
      "look",
      "phone",
      "sure",
      "you'll",
      "find",
      "somebody",
      "fix",
    ],
    words: [
      "there's",
      "something",
      "wrong",
      "with",
      "my",
      "washing",
      "machine",
      "I'm",
      "sorry",
      "I",
      "can't",
      "help",
      "you",
      "don't",
      "know",
      "anything",
      "about",
      "machines",
      "do",
      "anybody",
      "who",
      "can",
      "me",
      "not",
      "really",
      "should",
      "look",
      "in",
      "the",
      "phone",
      "book",
      "sure",
      "you'll",
      "find",
      "somebody",
      "fix",
      "it",
    ],
    link: "https://www.youtube.com/watch?v=kgWaWG1d06o&list=PLD7AA7B1BC72ACC28&index=25",
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/something.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=b7sYvxekJn5%2F2Ky7uIgwElEEuP%2BmPV0PEB4YedzvNBGnux5%2BNH9i%2B8MEkpBdA%2F8JLcT33aOjMpY2ny9V6QHz%2FBiLVhcEjNygrcoOkC8yHLl2GVwDSJEU2VgG3noMtEnXN08PgjrKAsOz%2FNyo8Y2lc8M2e%2BPUGMNjqL0iytFUAZXKXjXYZ0tX1E8Qv9lScGvQ1W4xkHqQybVduCbpN4hruP0cjWPLpfwN%2FsoeC5tn94jhtiHIsSKiiw5Kw6LrU0e6Em7grN%2FM9pDq0tsEl%2B9fX0lMksd4TGY4fwFPv0EPfDHG01AgUhzjLaFKZxZcN7lR8yW3BBmnGY%2Fd3nSbxmicyg%3D%3D",
    id: "There's something wrong with my washing machine.\nI'm sorry. I can't help you. I don't know anything about washing machines.\nDo you know anybody who can help me?\nNot really. You should look in the phone book. I'm sure you'll find somebody who can fix it.",
    story_id: 1072,
    word_count: 37,
    all_intersection_count: 20,
    new_words_count: 17,
  },
  {
    row_new_words: [
      "bring",
      "must",
      "keep",
      "leash",
      "goes",
      "poop",
      "scoop",
      "up",
      "when",
      "clean",
      "after",
      "their",
      "makes",
      "much",
      "nicer",
      "experience",
      "clean up after",
    ],
    words: [
      "so",
      "if",
      "you",
      "bring",
      "your",
      "dog",
      "to",
      "the",
      "park",
      "must",
      "keep",
      "on",
      "a",
      "leash",
      "and",
      "goes",
      "poop",
      "scoop",
      "up",
      "I",
      "like",
      "it",
      "when",
      "people",
      "clean",
      "after",
      "their",
      "dogs",
      "makes",
      "for",
      "much",
      "nicer",
      "experience",
      "clean up after",
    ],
    link: "(11)gxMIeHmm3Rg",
    storage_link: null,
    id: "So if you bring your dog to the park, you must keep your dog on a leash. And if your dog goes poop, you must scoop up the poop. I like it when people clean up after their dogs. It makes for a much nicer park experience.",
    story_id: 1545,
    word_count: 34,
    all_intersection_count: 17,
    new_words_count: 17,
  },
  {
    row_new_words: [
      "present",
      "his",
      "girlfriend",
      "favorite",
      "gets",
      "wrapping",
      "paper",
      "puts",
      "top",
      "folds",
      "tapes",
      "down",
      "writes",
      "bow",
      "wrapped",
      "gift",
      "bag",
    ],
    words: [
      "he",
      "buys",
      "a",
      "present",
      "for",
      "his",
      "girlfriend",
      "it",
      "is",
      "her",
      "favorite",
      "book",
      "gets",
      "wrapping",
      "paper",
      "puts",
      "the",
      "on",
      "top",
      "of",
      "folds",
      "tapes",
      "down",
      "writes",
      "name",
      "bow",
      "wrapped",
      "in",
      "gift",
      "bag",
    ],
    link: "https://www.eslfast.com/kidsenglish2/ke2/ke2059.htm",
    storage_link: null,
    id: "He buys a present\n\nHe buys a present for his girlfriend. It is her favorite book. He gets wrapping paper. He puts the book on top of the paper. He folds the paper. He tapes the paper down. He writes her name on it. He puts a bow on the wrapped book. He puts the present in a gift bag.",
    story_id: 485,
    word_count: 30,
    all_intersection_count: 13,
    new_words_count: 17,
  },
  {
    row_new_words: [
      "scary",
      "spider",
      "sees",
      "black",
      "floor",
      "scared",
      "moving",
      "asks",
      "dad",
      "kill",
      "killing",
      "gets",
      "puts",
      "top",
      "inside",
      "lets",
      "out",
    ],
    words: [
      "a",
      "scary",
      "spider",
      "she",
      "sees",
      "black",
      "on",
      "the",
      "kitchen",
      "floor",
      "is",
      "scared",
      "not",
      "moving",
      "asks",
      "her",
      "dad",
      "to",
      "kill",
      "doesn't",
      "like",
      "killing",
      "he",
      "gets",
      "cup",
      "puts",
      "top",
      "of",
      "inside",
      "later",
      "lets",
      "out",
    ],
    link: "https://www.eslfast.com/gradedread1/gr/gr1040.htm",
    storage_link: null,
    id: "A scary spider\n\nShe sees a black spider on the kitchen floor. She is scared. The spider is not moving. She asks her dad to kill the spider. Her dad doesn't like killing the spider. He gets a cup. He puts the cup on top of the spider. The spider is inside the cup. Later her dad lets the spider out.",
    story_id: 257,
    word_count: 32,
    all_intersection_count: 15,
    new_words_count: 17,
  },
  {
    row_new_words: [
      "bored",
      "tries",
      "fun",
      "yarn",
      "scratches",
      "his",
      "tummy",
      "takes",
      "nap",
      "milk",
      "still",
      "sees",
      "cheese",
      "steals",
      "angry",
      "chases",
      "having",
    ],
    words: [
      "a",
      "cat",
      "and",
      "mouse",
      "the",
      "is",
      "bored",
      "he",
      "tries",
      "to",
      "have",
      "fun",
      "plays",
      "with",
      "yarn",
      "scratches",
      "his",
      "tummy",
      "takes",
      "nap",
      "drinks",
      "milk",
      "still",
      "sees",
      "eating",
      "cheese",
      "steals",
      "angry",
      "chases",
      "having",
    ],
    link: "https://www.eslfast.com/kidsenglish/ke/ke013.htm",
    storage_link: null,
    id: "A cat and a mouse\n\nThe cat is bored. He tries to have fun. He plays with yarn. He scratches his tummy. He takes a nap. He drinks milk. He is still bored. He sees a mouse. The mouse is eating cheese. The cat steals the cheese. The mouse is angry. The mouse chases the cat. The cat is having fun.",
    story_id: 343,
    word_count: 30,
    all_intersection_count: 13,
    new_words_count: 17,
  },
  {
    row_new_words: [
      "come",
      "close",
      "draw",
      "these",
      "phrases",
      "use",
      "describe",
      "things",
      "longer",
      "happening",
      "summer",
      "definitely",
      "coming",
      "drawing",
      "tomorrow",
      "I'll",
      "teaching",
    ],
    words: [
      "to",
      "come",
      "a",
      "close",
      "and",
      "draw",
      "these",
      "are",
      "phrases",
      "we",
      "use",
      "describe",
      "things",
      "that",
      "no",
      "longer",
      "happening",
      "so",
      "summer",
      "is",
      "definitely",
      "coming",
      "it's",
      "drawing",
      "tomorrow",
      "I'll",
      "need",
      "start",
      "teaching",
    ],
    link: "(10)https://www.youtube.com/watch?v=Xz6EEVVz1IA&list=PLQut38RVINETJDG9hzlMPp62gDRCPKmrH&index=3",
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/tocometoclosetodrawtoclose.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=OTVfzEu1mQXdK%2FCbQv%2Bhz0nLQE4rP2MvPyK%2F72u6ORKmRS67khOHAG6gCt4Z4Mp0bqRSoH9WADHX1XE1U2QgYgcSSVpgX798L6KpJmo8tCrvSJIEjCNXMgnJlRJQmAwXoh4zNi53Avrm4ANHpaNuUNuqypfkewCR6hoJq8%2FPUQkIPan8UUopqus5we9dyONf4vk02ghhNxWJm3I5ddNdK8COu5xpTNJbRW6oG8JHSu7fa59R3aSjRCzrX1Qu2A2QS8HG3lFK32WdIYvGf9sPWBqq3mOaa4PyeT3jjksN1YLAZJ1HyV9HMlENUMTtThOsX1ql5ntfNHp%2B0jbG6R%2BeEA%3D%3D",
    id: "To come to a close and to draw to a close. These are phrases we use to describe things that are no longer happening. So summer is definitely coming to a close. It's drawing to a close. And tomorrow I'll need to start teaching.",
    story_id: 1145,
    word_count: 29,
    all_intersection_count: 12,
    new_words_count: 17,
  },
  {
    row_new_words: [
      "giving",
      "food",
      "car",
      "waits",
      "front",
      "move",
      "traffic",
      "bad",
      "man",
      "street",
      "knocks",
      "window",
      "opens",
      "asks",
      "sandwich",
      "another",
      "driver",
    ],
    words: [
      "giving",
      "food",
      "she",
      "is",
      "in",
      "the",
      "car",
      "waits",
      "for",
      "front",
      "to",
      "move",
      "traffic",
      "bad",
      "a",
      "man",
      "on",
      "street",
      "knocks",
      "her",
      "window",
      "opens",
      "he",
      "asks",
      "money",
      "has",
      "no",
      "gives",
      "him",
      "sandwich",
      "so",
      "happy",
      "another",
      "driver",
    ],
    link: "https://www.eslfast.com/kidsenglish2/ke2/ke2074.htm",
    storage_link: null,
    id: "Giving food\n\nShe is in the car. She waits for the car in front to move. The traffic is bad. A man on the street knocks on her window. She opens the window. He asks for money. She has no money. She gives him a sandwich. He is so happy. He asks another driver for money.",
    story_id: 493,
    word_count: 34,
    all_intersection_count: 17,
    new_words_count: 17,
  },
  {
    row_new_words: [
      "bad",
      "manners",
      "never",
      "shows",
      "any",
      "respect",
      "mother",
      "present",
      "grandparents",
      "come",
      "asks",
      "leave",
      "classmate",
      "something",
      "steals",
      "dad",
      "favor",
    ],
    words: [
      "bad",
      "manners",
      "she",
      "has",
      "never",
      "shows",
      "any",
      "respect",
      "her",
      "mother",
      "buys",
      "a",
      "present",
      "does",
      "not",
      "say",
      "thank",
      "you",
      "grandparents",
      "come",
      "to",
      "house",
      "asks",
      "them",
      "leave",
      "classmate",
      "something",
      "wants",
      "steals",
      "it",
      "dad",
      "for",
      "favor",
      "do",
    ],
    link: "https://www.eslfast.com/kidsenglish/ke/ke079.htm",
    storage_link: null,
    id: "Bad manners\n\nShe has bad manners. She never shows any respect. Her mother buys her a present. She does not say thank you. Her grandparents come to her house. She asks them to leave. Her classmate has something she wants. She steals it. Her dad asks her for a favor. She does not do it.",
    story_id: 398,
    word_count: 34,
    all_intersection_count: 17,
    new_words_count: 17,
  },
  {
    row_new_words: [
      "will",
      "mister",
      "henderson",
      "back",
      "soon",
      "he'll",
      "20",
      "minutes",
      "smith",
      "here",
      "she'll",
      "half",
      "hour",
      "train",
      "arrive",
      "it'll",
      "5",
    ],
    words: [
      "will",
      "mister",
      "henderson",
      "be",
      "back",
      "soon",
      "yes",
      "he",
      "he'll",
      "in",
      "about",
      "20",
      "minutes",
      "doctor",
      "smith",
      "here",
      "she",
      "she'll",
      "half",
      "an",
      "hour",
      "the",
      "train",
      "arrive",
      "it",
      "it'll",
      "5",
    ],
    link: "https://www.youtube.com/watch?v=5Nzr30SYy3g&list=PLD7AA7B1BC72ACC28&index=7",
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/will-2.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=ru%2FybiDnjZCguUgkR%2FSMSNe%2FIF7UMR40M1PhGwS9h%2BDniq4WXEEEmTOIwUOgBpyk9lU0YVzCQE8Hla576aNwCqHj2tslmZz1nGCY4YzxypilzavCZCOaku%2BOmjw6xPkVksX6XedzMn3W4i9FQ7OOBqE2crDWp6Q0LxkS%2B1GxRAeo9%2BYfH%2FUj8v%2Bo%2FEI%2FXKEWbzCl%2BKMYLY5WdC%2Bb0ICCjm08GYPBhq6W721hHzgu55C6uWKH0IzO4QSpPLlGtojo2uFD0fmJoLk6Iqfk0Wgn%2F8150MtYZHtCcI2w2clhigq468V%2BDTiqLiOeJ9doqKPDCGzYf%2FztbC0au%2BajyF7gig%3D%3D",
    id: "Will mister Henderson be back soon?\nYes. He will.\nHe'll be back in about 20 minutes.\n\nWill doctor Smith be here soon?\nYes. She will. She'll be here in half an hour.\n\nWill the train arrive soon?\nYes, it will. It'll arrive in 5 minutes.",
    story_id: 1027,
    word_count: 27,
    all_intersection_count: 10,
    new_words_count: 17,
  },
  {
    row_new_words: [
      "chili",
      "sauce",
      "lover",
      "noodles",
      "plain",
      "adds",
      "tries",
      "lot",
      "better",
      "put",
      "everything",
      "puts",
      "french",
      "fries",
      "egg",
      "rolls",
      "even",
    ],
    words: [
      "chili",
      "sauce",
      "lover",
      "he",
      "eats",
      "noodles",
      "it",
      "is",
      "plain",
      "adds",
      "tries",
      "the",
      "tastes",
      "a",
      "lot",
      "better",
      "starts",
      "to",
      "put",
      "in",
      "everything",
      "puts",
      "on",
      "french",
      "fries",
      "soup",
      "egg",
      "rolls",
      "even",
      "rice",
    ],
    link: "https://www.eslfast.com/kidsenglish/ke/ke100.htm",
    storage_link: null,
    id: "Chili sauce lover\n\nHe eats noodles. It is plain. He adds chili sauce. He tries the noodles. It tastes a lot better. He starts to put chili sauce in everything. He puts chili sauce on french fries. He puts chili sauce in soup. He puts chili sauce on egg rolls. He even puts chili sauce on rice.",
    story_id: 419,
    word_count: 30,
    all_intersection_count: 13,
    new_words_count: 17,
  },
  {
    row_new_words: [
      "getting",
      "ready",
      "gets",
      "at",
      "puts",
      "his",
      "science",
      "textbook",
      "backpack",
      "pencils",
      "pens",
      "eraser",
      "snack",
      "might",
      "get",
      "hungry",
      "notebook",
    ],
    words: [
      "getting",
      "ready",
      "to",
      "study",
      "he",
      "gets",
      "at",
      "the",
      "library",
      "puts",
      "his",
      "science",
      "textbook",
      "in",
      "backpack",
      "pencils",
      "and",
      "pens",
      "an",
      "eraser",
      "a",
      "snack",
      "might",
      "get",
      "hungry",
      "notebook",
      "is",
    ],
    link: "https://www.eslfast.com/kidsenglish2/ke2/ke2006.htm",
    storage_link: null,
    id: "Getting ready to study\n\nHe gets ready to study at the library. He puts his science textbook in his backpack. He puts his pencils and pens in his backpack. He puts an eraser in his backpack. He puts a snack in his backpack. He might get hungry. He puts a notebook in his backpack. He is ready to study!",
    story_id: 425,
    word_count: 27,
    all_intersection_count: 10,
    new_words_count: 17,
  },
  {
    row_new_words: [
      "keeps",
      "finds",
      "street",
      "there",
      "collar",
      "takes",
      "into",
      "his",
      "washes",
      "veterinarian",
      "sick",
      "will",
      "die",
      "soon",
      "surgery",
      "much",
      "decides",
    ],
    words: [
      "he",
      "keeps",
      "a",
      "dog",
      "finds",
      "on",
      "the",
      "street",
      "there",
      "is",
      "no",
      "collar",
      "likes",
      "dogs",
      "takes",
      "it",
      "into",
      "his",
      "house",
      "washes",
      "to",
      "veterinarian",
      "sick",
      "will",
      "die",
      "soon",
      "surgery",
      "not",
      "help",
      "that",
      "much",
      "decides",
      "let",
      "go",
    ],
    link: "https://www.eslfast.com/kidsenglish2/ke2/ke2044.htm",
    storage_link: null,
    id: "He keeps a dog\n\nHe finds a dog on the street. There is no collar. He likes dogs. He takes it into his house. He washes it. He takes it to the veterinarian. The dog is sick. It will die soon. The surgery will not help that much. He decides to let the dog go.",
    story_id: 456,
    word_count: 34,
    all_intersection_count: 17,
    new_words_count: 17,
  },
  {
    row_new_words: [
      "waiting",
      "kids",
      "class",
      "teaching",
      "math",
      "tired",
      "sun",
      "slides",
      "also",
      "swings",
      "stare",
      "at",
      "clock",
      "moves",
      "slowly",
      "continue",
      "wait",
    ],
    words: [
      "waiting",
      "the",
      "kids",
      "are",
      "in",
      "class",
      "teacher",
      "is",
      "teaching",
      "math",
      "tired",
      "they",
      "want",
      "to",
      "play",
      "see",
      "sun",
      "like",
      "slides",
      "also",
      "swings",
      "don't",
      "stare",
      "at",
      "clock",
      "moves",
      "slowly",
      "continue",
      "wait",
    ],
    link: "https://www.eslfast.com/kidsenglish/ke/ke004.htm",
    storage_link: null,
    id: "Waiting\n\nThe kids are in class. The teacher is teaching math. The kids are tired. They want to play. They want to see the sun. They like slides. They also like swings. They don't like math. They stare at the clock. The clock moves slowly. The kids continue to wait.",
    story_id: 327,
    word_count: 29,
    all_intersection_count: 12,
    new_words_count: 17,
  },
  {
    row_new_words: [
      "you'll",
      "rocking",
      "chair",
      "liked",
      "old",
      "was",
      "comfortable",
      "right",
      "more",
      "really",
      "here",
      "try",
      "mom",
      "this",
      "absolutely",
      "computer",
      "powerful",
    ],
    words: [
      "I",
      "think",
      "you'll",
      "like",
      "my",
      "new",
      "rocking",
      "chair",
      "but",
      "liked",
      "your",
      "old",
      "it",
      "was",
      "comfortable",
      "that's",
      "right",
      "is",
      "more",
      "really",
      "here",
      "try",
      "you're",
      "mom",
      "this",
      "absolutely",
      "computer",
      "powerful",
    ],
    link: "(4)https://www.youtube.com/watch?v=GSx9o9uZosQ&list=PLD7AA7B1BC72ACC28&index=9",
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/was2.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=ao4rn3suZQLTSsEBPCVQpwbQoPZI5HwtSe2U3j%2FjqRssiy2Cc3b26EJF57yXu9HW7ZYqGdIL%2B%2FZQ4d%2BBRx%2Bxr0HkoL1B926%2FvPyCaejFWj0NhXbz%2B%2F%2BGP6WHrNU1osltgFXPXmhWsfeY7FzNexbFYExdcKY71hYQRpxAa48bdf6zKvGO2nrGyAJ%2FAWrQ4SDJdHf%2FgDsJEPqLdsW2XTd00D6jC7tXJCyI84lYEBS79VDISdP1C41uLXUkIj4rj56YCIeXCCn0fhcXMzJxMG2Y0OqZP04bTIn4EMOokiQ%2F8GXbwhwbz9ZQ57yklWIt%2FRGxIN9Ixo6Jb%2FIQFZz7MslPjA%3D%3D",
    id: "I think you'll like my new rocking chair.\nBut I liked your old rocking chair. It was comfortable.\nThat's right. But my new rocking chair is more comfortable.\nReally?\nHere, try it.\nYou're right, mom. This new rocking chair is more comfortable. Absolutely.\n\nI think you'll like my new computer.\nBut I liked your old computer. It was powerful.\nThat's right. But my new computer is more powerful.",
    story_id: 1048,
    word_count: 28,
    all_intersection_count: 11,
    new_words_count: 17,
  },
  {
    row_new_words: [
      "bicycle",
      "left",
      "out",
      "storm",
      "protected",
      "rust",
      "begins",
      "form",
      "unless",
      "slowly",
      "away",
      "provide",
      "little",
      "kindness",
      "when",
      "sky",
      "grey",
    ],
    words: [
      "love",
      "is",
      "like",
      "a",
      "bicycle",
      "left",
      "out",
      "in",
      "the",
      "storm",
      "if",
      "it's",
      "not",
      "protected",
      "rust",
      "begins",
      "to",
      "form",
      "so",
      "unless",
      "you",
      "want",
      "your",
      "slowly",
      "away",
      "provide",
      "little",
      "kindness",
      "when",
      "sky",
      "grey",
    ],
    link: "https://www.familyfriendpoems.com/poem/dont-neglect-your-love",
    storage_link: null,
    id: "Love is like a bicycle\nLeft out in the storm\nIf it's not protected\nRust begins to form\n\nSo unless you want your bicycle\nTo slowly rust away,\nProvide a little kindness\nWhen the sky begins to grey.",
    story_id: 919,
    word_count: 31,
    all_intersection_count: 14,
    new_words_count: 17,
  },
  {
    row_new_words: [
      "lemons",
      "sour",
      "stuff",
      "peels",
      "skin",
      "off",
      "lemon",
      "bites",
      "into",
      "spits",
      "out",
      "seeds",
      "mom",
      "says",
      "weird",
      "most",
      "another",
      "food",
    ],
    words: [
      "she",
      "eats",
      "lemons",
      "likes",
      "sour",
      "stuff",
      "are",
      "yellow",
      "peels",
      "the",
      "skin",
      "off",
      "lemon",
      "bites",
      "into",
      "it",
      "tastes",
      "good",
      "spits",
      "out",
      "seeds",
      "not",
      "her",
      "mom",
      "says",
      "is",
      "weird",
      "most",
      "people",
      "eat",
      "with",
      "another",
      "food",
    ],
    link: "https://www.eslfast.com/kidsenglish2/ke2/ke2017.htm",
    storage_link: null,
    id: "She eats lemons\n\nShe likes sour stuff. Lemons are sour. Lemons are yellow. She peels the skin off the lemon. She bites into the lemon. It tastes good. She spits out the seeds. The seeds are not good. Her mom says she is weird. Most people eat lemon with another food.",
    story_id: 429,
    word_count: 33,
    all_intersection_count: 15,
    new_words_count: 18,
  },
  {
    row_new_words: [
      "reach",
      "this",
      "branch",
      "here",
      "set",
      "up",
      "step",
      "ladder",
      "then",
      "when",
      "done",
      "though",
      "should",
      "take",
      "down",
      "after",
      "use",
      "using",
    ],
    words: [
      "so",
      "I",
      "can't",
      "reach",
      "this",
      "branch",
      "here",
      "but",
      "if",
      "set",
      "up",
      "my",
      "step",
      "ladder",
      "then",
      "can",
      "when",
      "I'm",
      "done",
      "though",
      "should",
      "take",
      "down",
      "after",
      "use",
      "it",
      "using",
    ],
    link: "(14)https://youtu.be/VH-t4I7Cepg?si=6KHRPaTs5DXUORKk",
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/14learn30englishactionverbsbob.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=zYIZdVWehOLe8BnsmNF91VeAH7YJwxosGS4DppFqScavNS9%2Bkcj2fmflpVILwLgg9mV4fbIShhBUtmwqA%2Fc%2F33Ys2r0yFQQxo20Iwuyc8lKfeFf11StyQLtZo0%2ByGjdOiPp2%2BEUn84m6NxpBTDvpQdbQSVf7ZUm%2B3u2FTh324zTOt3mCrAv2XltaU9bKo6QPd0f%2FzCairsmRqYasfdH9p1sDUAtRTfOLVGDdJm9nWFeNc26GLCRXgDyk5bArVVvEKXEhSCA7nH%2BYUFF6m5xwEfAn4UWmkUQADb1RYhf2reOy98Djvep61RmgNXI4DyMp9mQwh5S0bnJZ8IfeUE4URA%3D%3D",
    id: "So I can't reach this branch here. But if I set up my step ladder, then I can reach this branch. So I can set up my step ladder so I can reach this branch when I'm done. Though I should take down my step ladder after I use my step ladder, I take it down when I'm done using it.",
    story_id: 1339,
    word_count: 27,
    all_intersection_count: 9,
    new_words_count: 18,
  },
  {
    row_new_words: [
      "germs",
      "wash",
      "hands",
      "after",
      "toilet",
      "wet",
      "use",
      "soap",
      "carefully",
      "count",
      "20",
      "as",
      "make",
      "sure",
      "turn",
      "tap",
      "off",
      "dry",
    ],
    words: [
      "say",
      "no",
      "to",
      "germs",
      "wash",
      "your",
      "hands",
      "after",
      "you",
      "go",
      "the",
      "toilet",
      "wet",
      "with",
      "water",
      "use",
      "soap",
      "carefully",
      "count",
      "20",
      "as",
      "make",
      "sure",
      "turn",
      "tap",
      "off",
      "dry",
      "well",
    ],
    link: "https://learnenglishkids.britishcouncil.org/read-write/reading-practice/level-1-reading/bathroom-sign",
    storage_link: null,
    id: "Say NO! To germs. Wash your hands after you go to the toilet!\n\nWet your hands with water.\nUse soap.\nWash your hands carefully.\nCount to 20 as you wash.\nMake sure you turn the tap off!\nDry your hands well.",
    story_id: 880,
    word_count: 28,
    all_intersection_count: 10,
    new_words_count: 18,
  },
  {
    row_new_words: [
      "luck",
      "charm",
      "ugly",
      "shirt",
      "when",
      "wears",
      "things",
      "happen",
      "wear",
      "something",
      "important",
      "happening",
      "days",
      "tests",
      "impress",
      "someone",
      "feeling",
      "sad",
    ],
    words: [
      "good",
      "luck",
      "charm",
      "she",
      "has",
      "an",
      "ugly",
      "shirt",
      "it",
      "is",
      "her",
      "when",
      "wears",
      "things",
      "happen",
      "likes",
      "to",
      "wear",
      "something",
      "important",
      "happening",
      "on",
      "days",
      "tests",
      "wants",
      "impress",
      "someone",
      "feeling",
      "sad",
    ],
    link: "https://www.eslfast.com/kidsenglish2/ke2/ke2065.htm",
    storage_link: null,
    id: "Good luck charm\n\nShe has an ugly shirt. It is her good luck charm. When she wears it, good things happen. She likes to wear it when something important is happening. She wears it on days when she has tests. She wears it when she wants to impress someone. She wears it when she is feeling sad.",
    story_id: 477,
    word_count: 29,
    all_intersection_count: 11,
    new_words_count: 18,
  },
  {
    row_new_words: [
      "-",
      "saw",
      "yesterday",
      "didn't",
      "really",
      "when",
      "at",
      "4",
      "o'clock",
      "were",
      "jogging",
      "through",
      "wasn't",
      "was",
      "tennis",
      "guess",
      "made",
      "mistake",
    ],
    words: [
      "-",
      "I",
      "saw",
      "you",
      "yesterday",
      "but",
      "didn't",
      "see",
      "me",
      "really",
      "when",
      "at",
      "about",
      "4",
      "o'clock",
      "were",
      "jogging",
      "through",
      "the",
      "park",
      "that",
      "wasn't",
      "was",
      "playing",
      "tennis",
      "guess",
      "made",
      "a",
      "mistake",
    ],
    link: "(2)https://www.youtube.com/watch?v=jkOXNxoMClA",
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/Past%20Continuous%202.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=lAgobq8ryMGCEUIBZDDHqPz7ebl4jbq%2B1K9nib3B5jPAzfWoMTRgt%2BC9rAUH%2BzlVJAnWOKDZKXLzq%2BufKSr0aZ%2B%2BTY9pEfRePClJlq2Vj1aCjgD2vfzF%2FBOSLyOHnuqhgu%2BxwI9Yglc1tBiJFlYT82KjmRbG117K16zzN9kuN9qyQ7jKUPROfK2lGTQZXe%2BvESOL%2BWuSiuMB57B5ETjYYXXr%2FfDnxRkfxL1QWxR62brLdCw0kcbZbpsTWaMCKyX8Tg55sghCFEf%2BiD%2BFn96CH9JlPfmxc6GAbKhhqo6%2BPek13RjIxW7D%2BIxQu1EY4q%2B%2FNtL%2BLq2tgfGk9KJBLGSUnQ%3D%3D",
    id: "- I saw you yesterday, but you didn't see me. Really?\n- When?\n- At about 4 o'clock. You were jogging through the park.\n- That wasn't me. Yesterday, at 4 o'clock, I was playing tennis.\n- I guess I made a mistake.",
    story_id: 967,
    word_count: 29,
    all_intersection_count: 11,
    new_words_count: 18,
  },
  {
    row_new_words: [
      "fire",
      "smells",
      "smoke",
      "sees",
      "coming",
      "out",
      "looks",
      "bad",
      "burning",
      "down",
      "calls",
      "local",
      "firefighters",
      "come",
      "ten",
      "minutes",
      "get",
      "hose",
    ],
    words: [
      "a",
      "house",
      "on",
      "fire",
      "he",
      "is",
      "walking",
      "to",
      "work",
      "smells",
      "smoke",
      "sees",
      "coming",
      "out",
      "of",
      "it",
      "looks",
      "bad",
      "the",
      "burning",
      "down",
      "calls",
      "local",
      "firefighters",
      "come",
      "in",
      "ten",
      "minutes",
      "they",
      "get",
      "people",
      "hose",
      "with",
      "water",
    ],
    link: "https://www.eslfast.com/gradedread1/gr/gr1087.htm",
    storage_link: null,
    id: "A house on fire\n\nHe is walking to work. He smells smoke. He sees smoke coming out of a house. It looks bad. The house is burning down. He calls the local firefighters. The firefighters come in ten minutes. They get the people in the house out. They hose down the house with water.",
    story_id: 304,
    word_count: 34,
    all_intersection_count: 16,
    new_words_count: 18,
  },
  {
    row_new_words: [
      "brush",
      "bus",
      "hey",
      "everybody",
      "find",
      "toothbrush",
      "toothpaste",
      "fun",
      "teeth",
      "all",
      "won't",
      "show",
      "how",
      "move",
      "round",
      "brushed",
      "at",
      "back",
    ],
    words: [
      "brush",
      "bus",
      "hey",
      "everybody",
      "it's",
      "the",
      "find",
      "your",
      "toothbrush",
      "and",
      "toothpaste",
      "let's",
      "have",
      "fun",
      "teeth",
      "all",
      "them",
      "now",
      "won't",
      "you",
      "show",
      "me",
      "how",
      "move",
      "round",
      "brushed",
      "at",
      "back",
      "yes",
    ],
    link: "https://learnenglishkids.britishcouncil.org/listen-watch/songs/brush-bus",
    storage_link: null,
    id: "Brush bus\n\nHey everybody it’s the brush bus\nFind your toothbrush and your toothpaste\nLet’s have fun and brush.\n\nBrush your teeth everybody\nLet’s all brush them now\nBrush your teeth everybody\nWon’t you show me how?\nBrush your teeth everybody\nLet’s all brush them now\nBrush your teeth everybody\nWon’t you show me how?\n\nMove your brush round and round\nMove your brush round and round\nMove your brush round and round\nMove your brush round and round.\n\nBrush your teeth everybody\nLet’s all brush them now\nBrush your teeth everybody\nWon’t you show me how?\nBrush your teeth everybody\nLet’s all brush them now\nBrush your teeth everybody\nWon’t you show me how?\n\nAll brushed at the back, brush at the back\nAll brushed at the back, brush at the back\nAll brushed at the back, brush at the back\nAll brushed at the back, yes, you brush at the back.",
    story_id: 853,
    word_count: 29,
    all_intersection_count: 11,
    new_words_count: 18,
  },
  {
    row_new_words: [
      "saving",
      "hears",
      "meow",
      "coming",
      "looks",
      "closely",
      "at",
      "sees",
      "cute",
      "white",
      "stuck",
      "up",
      "brings",
      "down",
      "cat's",
      "owner",
      "$15",
      "own",
    ],
    words: [
      "saving",
      "a",
      "cat",
      "she",
      "hears",
      "meow",
      "it",
      "is",
      "coming",
      "from",
      "the",
      "tree",
      "looks",
      "closely",
      "at",
      "sees",
      "cute",
      "white",
      "stuck",
      "climbs",
      "up",
      "brings",
      "down",
      "cat's",
      "owner",
      "happy",
      "gives",
      "her",
      "$15",
      "buys",
      "own",
      "with",
      "money",
    ],
    link: "https://www.eslfast.com/kidsenglish2/ke2/ke2041.htm",
    storage_link: null,
    id: "Saving a cat\n\nShe hears a \"meow.\" It is coming from the tree. She looks closely at the tree. She sees a cute, white cat. It is stuck. She climbs up the tree. She brings the cat down. The cat's owner is happy. The cat's owner gives her $15. She buys her own cat with the money.",
    story_id: 453,
    word_count: 33,
    all_intersection_count: 15,
    new_words_count: 18,
  },
  {
    row_new_words: [
      "fluffy",
      "rabbit",
      "pet",
      "his",
      "rabbit's",
      "female",
      "white",
      "black",
      "eyes",
      "soft",
      "playful",
      "weighs",
      "seven",
      "pounds",
      "hop",
      "around",
      "carrots",
      "five",
    ],
    words: [
      "fluffy",
      "the",
      "rabbit",
      "he",
      "has",
      "a",
      "pet",
      "it",
      "is",
      "his",
      "rabbit's",
      "name",
      "female",
      "she",
      "white",
      "black",
      "eyes",
      "very",
      "soft",
      "playful",
      "weighs",
      "seven",
      "pounds",
      "likes",
      "to",
      "hop",
      "around",
      "house",
      "eat",
      "carrots",
      "gives",
      "five",
      "day",
    ],
    link: "https://www.eslfast.com/kidsenglish/ke/ke055.htm",
    storage_link: null,
    id: "Fluffy the rabbit\n\nHe has a pet. It is a rabbit. His rabbit's name is Fluffy. Fluffy is female. She is white. She has black eyes. She is very soft. She is playful. She weighs seven pounds. She likes to hop around the house. She likes to eat carrots. He gives Fluffy five carrots a day.",
    story_id: 378,
    word_count: 33,
    all_intersection_count: 15,
    new_words_count: 18,
  },
  {
    row_new_words: [
      "waits",
      "mail",
      "carrier",
      "stands",
      "outside",
      "looks",
      "left",
      "right",
      "package",
      "mom",
      "arriving",
      "sees",
      "wearing",
      "blue",
      "shirt",
      "waves",
      "signs",
      "into",
    ],
    words: [
      "she",
      "waits",
      "for",
      "the",
      "mail",
      "carrier",
      "stands",
      "outside",
      "house",
      "looks",
      "to",
      "left",
      "right",
      "a",
      "package",
      "from",
      "her",
      "mom",
      "is",
      "arriving",
      "sees",
      "he",
      "wearing",
      "blue",
      "shirt",
      "waves",
      "hello",
      "gives",
      "signs",
      "it",
      "runs",
      "into",
    ],
    link: "https://www.eslfast.com/kidsenglish2/ke2/ke2094.htm",
    storage_link: null,
    id: "She waits for the mail carrier\n\nShe stands outside the house. She looks to the left. She looks to the right. She waits for the mail carrier. A package from her mom is arriving. She sees the mail carrier. He is wearing a blue shirt. He waves hello. He gives her the package. She signs it. She runs into her house.",
    story_id: 513,
    word_count: 32,
    all_intersection_count: 14,
    new_words_count: 18,
  },
  {
    row_new_words: [
      "clothes",
      "get",
      "fit",
      "into",
      "old",
      "anymore",
      "goes",
      "mall",
      "dresses",
      "skirts",
      "jackets",
      "pants",
      "shirts",
      "lot",
      "shopping",
      "bags",
      "wait",
      "wear",
    ],
    words: [
      "she",
      "needs",
      "new",
      "clothes",
      "to",
      "get",
      "does",
      "not",
      "fit",
      "into",
      "her",
      "old",
      "anymore",
      "goes",
      "the",
      "mall",
      "buys",
      "some",
      "dresses",
      "skirts",
      "jackets",
      "pants",
      "shirts",
      "has",
      "a",
      "lot",
      "of",
      "shopping",
      "bags",
      "can't",
      "wait",
      "wear",
    ],
    link: "https://www.eslfast.com/kidsenglish2/ke2/ke2024.htm",
    storage_link: null,
    id: "She needs new clothes\n\nShe needs to get new clothes. She does not fit into her old clothes anymore. She goes to the mall. She buys some dresses. She buys some skirts. She buys some jackets. She buys some pants. She buys some shirts. She has a lot of shopping bags. She can't wait to wear her new clothes.",
    story_id: 443,
    word_count: 32,
    all_intersection_count: 14,
    new_words_count: 18,
  },
  {
    row_new_words: [
      "digs",
      "dig",
      "his",
      "favorite",
      "thing",
      "goes",
      "into",
      "owner's",
      "takes",
      "necklace",
      "puts",
      "mouth",
      "backyard",
      "hole",
      "paws",
      "inside",
      "covers",
      "dirt",
    ],
    words: [
      "the",
      "dog",
      "that",
      "digs",
      "likes",
      "to",
      "dig",
      "it",
      "is",
      "his",
      "favorite",
      "thing",
      "do",
      "he",
      "goes",
      "into",
      "owner's",
      "house",
      "takes",
      "a",
      "necklace",
      "puts",
      "in",
      "mouth",
      "runs",
      "backyard",
      "hole",
      "with",
      "paws",
      "inside",
      "covers",
      "dirt",
    ],
    link: "https://www.eslfast.com/kidsenglish/ke/ke058.htm",
    storage_link: null,
    id: "The dog that digs\n\nThe dog likes to dig. It is his favorite thing to do. He goes into his owner's house. He takes a necklace. He puts the necklace in his mouth. He runs to the backyard. He digs a hole with his paws. He puts the necklace inside. He covers the hole with dirt.",
    story_id: 387,
    word_count: 32,
    all_intersection_count: 14,
    new_words_count: 18,
  },
  {
    row_new_words: [
      "loving",
      "William",
      "his",
      "parents",
      "got",
      "when",
      "was",
      "5",
      "years",
      "old",
      "12",
      "wags",
      "its",
      "tail",
      "meets",
      "licks",
      "part",
      "family",
    ],
    words: [
      "a",
      "loving",
      "dog",
      "William",
      "loves",
      "his",
      "parents",
      "got",
      "him",
      "the",
      "when",
      "he",
      "was",
      "5",
      "years",
      "old",
      "is",
      "now",
      "12",
      "very",
      "it",
      "always",
      "wags",
      "its",
      "tail",
      "meets",
      "people",
      "likes",
      "to",
      "give",
      "licks",
      "too",
      "part",
      "of",
      "family",
    ],
    link: "https://www.eslfast.com/gradedread1/gr/gr1037.htm",
    storage_link: null,
    id: "A loving dog\n\nWilliam loves his dog. His parents got him the dog when he was 5 years old. William is now 12 years old. The dog is very loving. It always wags its tail when it meets people. The dog likes to give people licks too. The dog is a part of the family.",
    story_id: 268,
    word_count: 35,
    all_intersection_count: 17,
    new_words_count: 18,
  },
  {
    row_new_words: [
      "little",
      "ladybug",
      "sunny",
      "goes",
      "backyard",
      "sits",
      "feels",
      "something",
      "crawl",
      "leg",
      "red",
      "black",
      "dots",
      "thinks",
      "pretty",
      "back",
      "inside",
      "still",
    ],
    words: [
      "a",
      "little",
      "ladybug",
      "it",
      "is",
      "nice",
      "sunny",
      "day",
      "she",
      "goes",
      "to",
      "her",
      "backyard",
      "sits",
      "on",
      "the",
      "grass",
      "feels",
      "something",
      "crawl",
      "leg",
      "red",
      "with",
      "black",
      "dots",
      "thinks",
      "pretty",
      "walks",
      "back",
      "inside",
      "house",
      "still",
    ],
    link: "https://www.eslfast.com/gradedread1/gr/gr1039.htm",
    storage_link: null,
    id: "A little ladybug\n\nIt is a nice, sunny day. She goes to her backyard. She sits on the grass. She feels something crawl on her leg. It is a ladybug! A ladybug is red with black dots. She thinks the ladybug is pretty. She walks back inside the house. The ladybug is still on her leg.",
    story_id: 256,
    word_count: 32,
    all_intersection_count: 14,
    new_words_count: 18,
  },
  {
    row_new_words: [
      "-",
      "this",
      "task",
      "was",
      "appointed",
      "find",
      "way",
      "one",
      "will",
      "then",
      "must",
      "afraid",
      "even",
      "smallest",
      "person",
      "change",
      "course",
      "future",
    ],
    words: [
      "-",
      "this",
      "task",
      "was",
      "appointed",
      "to",
      "you",
      "and",
      "if",
      "do",
      "not",
      "find",
      "a",
      "way",
      "no",
      "one",
      "will",
      "then",
      "I",
      "know",
      "what",
      "must",
      "it's",
      "just",
      "I'm",
      "afraid",
      "it",
      "even",
      "the",
      "smallest",
      "person",
      "can",
      "change",
      "course",
      "of",
      "future",
    ],
    link: "https://www.youtube.com/watch?v=oRjMmvgwX8k",
    storage_link: null,
    id: "- This task was appointed to you, and if you do not find a way, no one will.\n- Then I know what I must do. It's just... I'm afraid to do it.\n- Even the smallest person can change the course of the future.",
    story_id: 942,
    word_count: 36,
    all_intersection_count: 18,
    new_words_count: 18,
  },
  {
    row_new_words: [
      "bucket",
      "out",
      "dump",
      "ground",
      "use",
      "verb",
      "um",
      "sure",
      "did",
      "though",
      "because",
      "kind",
      "I'll",
      "fill",
      "this",
      "pail",
      "again",
      "up",
    ],
    words: [
      "now",
      "I",
      "have",
      "water",
      "in",
      "the",
      "bucket",
      "but",
      "what",
      "if",
      "want",
      "out",
      "of",
      "can",
      "dump",
      "on",
      "ground",
      "or",
      "use",
      "verb",
      "to",
      "um",
      "I'm",
      "not",
      "sure",
      "why",
      "did",
      "that",
      "though",
      "because",
      "we",
      "kind",
      "need",
      "I'll",
      "fill",
      "this",
      "pail",
      "again",
      "up",
    ],
    link: "(4)https://youtu.be/VH-t4I7Cepg?si=6KHRPaTs5DXUORKk",
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/4learn30englishactionverbsbob.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=OGNJH0hboljrfSPnILzpr9zuL0w1M3EJYnOGIkqnl9O7frT1v84gds6G4SrtGlunWcVlErcPsu2HfPATNh66TCylUhIOiU3ZKaBHXECAcgvjIo2BkT1%2BRPzPdI7eSx3wbmkqgHtUCHyEb%2FYgS7coGM7gzHWb%2B6%2FEQugzN5J88mz5WRD2a8UlS42Zoub3ZtShytCOzS9XgYjSO0lHrxpzeiXcKCUQnyq52UnqT80ESfAEUAknBAme%2FxC3TJt40kv2SLQslT7IYIixWM1LybceCE3CzI%2FfViObENtVPzvP8Hch0OJCKw2%2Fl9h0xOm1upRQDMjHzh7x87BT1uuYhVAYiw%3D%3D",
    id: "Now I have water in the bucket. But what if I want the water out of the bucket? I can dump the water on the ground. Or I can use the verb to dump out. I can dump the water. Or I can dump out the water. Um, I'm not sure why I did that, though, because we kind of need that water now. I'll have to fill this pail again. I'll have to fill up this pail again.",
    story_id: 1329,
    word_count: 39,
    all_intersection_count: 21,
    new_words_count: 18,
  },
  {
    row_new_words: [
      "offered",
      "car",
      "said",
      "would",
      "called",
      "up",
      "free",
      "asked",
      "thinking",
      "how",
      "long",
      "didn't",
      "more",
      "charity",
      "man",
      "at",
      "picked",
      "next",
    ],
    words: [
      "do",
      "you",
      "want",
      "it",
      "or",
      "not",
      "she",
      "offered",
      "her",
      "car",
      "to",
      "brother",
      "he",
      "said",
      "would",
      "think",
      "about",
      "let",
      "me",
      "called",
      "him",
      "up",
      "a",
      "week",
      "later",
      "my",
      "for",
      "free",
      "asked",
      "I'm",
      "thinking",
      "how",
      "long",
      "have",
      "didn't",
      "know",
      "some",
      "more",
      "charity",
      "the",
      "man",
      "at",
      "yes",
      "don't",
      "no",
      "I",
      "who",
      "has",
      "picked",
      "next",
      "day",
    ],
    link: "https://www.eslfast.com/supereasy/se/supereasy138.htm",
    storage_link: null,
    id: 'Do you want it or not?\n\nShe offered her car to her brother. He said he would think about it. "Let me think about it," he said. She called him up a week later. "Do you want my car for free?" she asked. He said, "I\'m thinking about it." She asked, "How long do you have to think about it?" He said he didn\'t know. "Let me think about it some more," he said. She called up a charity. She said, "Do you want my car for free?" The man at the charity said yes. She asked, "You don\'t have to think about it?" He said, "No, I don\'t have to think about it. Who has to think about a free car?" He picked up her car the next day.',
    story_id: 154,
    word_count: 51,
    all_intersection_count: 33,
    new_words_count: 18,
  },
  {
    row_new_words: [
      "lazy",
      "fox",
      "was",
      "brown",
      "quick",
      "said",
      "asked",
      "owner",
      "feeds",
      "takes",
      "out",
      "any",
      "ambition",
      "Lassie",
      "saved",
      "people's",
      "lives",
      "save",
    ],
    words: [
      "a",
      "lazy",
      "dog",
      "the",
      "fox",
      "was",
      "brown",
      "quick",
      "you",
      "are",
      "said",
      "yes",
      "I",
      "am",
      "why",
      "asked",
      "not",
      "my",
      "owner",
      "feeds",
      "me",
      "takes",
      "out",
      "for",
      "walks",
      "loves",
      "eat",
      "go",
      "sleep",
      "I'm",
      "happy",
      "but",
      "don't",
      "have",
      "any",
      "ambition",
      "to",
      "do",
      "what",
      "just",
      "Lassie",
      "she",
      "saved",
      "people's",
      "lives",
      "too",
      "save",
      "let",
    ],
    link: "https://www.eslfast.com/supereasy/se/supereasy171.htm",
    storage_link: null,
    id: 'A lazy dog\n\nThe fox was brown. The fox was quick. The dog was brown. The dog was lazy. "You are a lazy dog," said the fox. "Yes, I am a lazy dog," said the dog. "Why are you a lazy dog?" asked the fox. "Why not?" asked the dog. "My owner feeds me. My owner takes me out for walks. My owner loves me. I eat. I go out for walks. I sleep. I\'m a lazy, happy dog." "But don\'t you have any ambition?" asked the fox. "Ambition? Ambition to do what? I\'m just a dog," said the dog. "Lassie was a dog. She saved people\'s lives," said the fox. "I\'m too lazy to save people\'s lives," said the dog. "Let Lassie save people\'s lives."',
    story_id: 195,
    word_count: 48,
    all_intersection_count: 30,
    new_words_count: 18,
  },
  {
    row_new_words: [
      "excited",
      "mail",
      "waits",
      "mailman",
      "package",
      "supposed",
      "come",
      "sits",
      "by",
      "window",
      "sees",
      "goes",
      "outside",
      "says",
      "goodbye",
      "each",
      "back",
      "his",
    ],
    words: [
      "excited",
      "about",
      "the",
      "mail",
      "boy",
      "waits",
      "for",
      "mailman",
      "a",
      "package",
      "is",
      "supposed",
      "to",
      "come",
      "today",
      "he",
      "sits",
      "by",
      "window",
      "sees",
      "walking",
      "goes",
      "outside",
      "says",
      "hello",
      "gives",
      "him",
      "they",
      "say",
      "goodbye",
      "each",
      "other",
      "back",
      "his",
      "house",
    ],
    link: "https://www.eslfast.com/kidsenglish/ke/ke050.htm",
    storage_link: null,
    id: "Excited about the mail\n\nThe boy waits for the mailman. A package is supposed to come today. He sits by the window. He sees the mailman walking. He goes outside. He says hello to the mailman. The mailman gives him the package. They say goodbye to each other. The boy goes back to his house.",
    story_id: 373,
    word_count: 35,
    all_intersection_count: 17,
    new_words_count: 18,
  },
  {
    row_new_words: [
      "his",
      "Brian",
      "three",
      "first",
      "one",
      "black",
      "second",
      "brown",
      "third",
      "gray",
      "big",
      "medium-sized",
      "small",
      "all",
      "at",
      "enjoy",
      "seem",
      "when",
    ],
    words: [
      "he",
      "walks",
      "his",
      "dogs",
      "Brian",
      "has",
      "three",
      "the",
      "first",
      "one",
      "is",
      "black",
      "second",
      "brown",
      "third",
      "gray",
      "dog",
      "very",
      "big",
      "medium-sized",
      "small",
      "loves",
      "all",
      "of",
      "them",
      "with",
      "at",
      "park",
      "enjoy",
      "it",
      "they",
      "always",
      "seem",
      "happy",
      "when",
      "are",
      "walking",
    ],
    link: "https://www.eslfast.com/kidsenglish/ke/ke052.htm",
    storage_link: null,
    id: "He walks his dogs\n\nBrian has three dogs. The first one is black. The second one is brown. The third one is gray. The black dog is very big. The brown dog is medium-sized. The gray dog is very small. Brian loves all of them. He walks with his dogs at the park The dogs enjoy it. They always seem happy when they are walking.",
    story_id: 375,
    word_count: 37,
    all_intersection_count: 19,
    new_words_count: 18,
  },
  {
    row_new_words: [
      "-",
      "Jack",
      "this",
      "jim",
      "calling",
      "miami",
      "vacation",
      "how's",
      "weather",
      "sunny",
      "isn't",
      "raining",
      "hot",
      "cold",
      "having",
      "time",
      "terrible",
      "here",
    ],
    words: [
      "-",
      "hi",
      "Jack",
      "this",
      "is",
      "jim",
      "I'm",
      "calling",
      "from",
      "miami",
      "what",
      "are",
      "you",
      "doing",
      "in",
      "on",
      "vacation",
      "how's",
      "the",
      "weather",
      "it",
      "sunny",
      "no",
      "isn't",
      "it's",
      "raining",
      "hot",
      "cold",
      "having",
      "a",
      "good",
      "time",
      "not",
      "terrible",
      "here",
      "sorry",
      "to",
      "hear",
      "that",
    ],
    link: "(2)https://www.youtube.com/watch?v=yszaiqhK1Aw&list=PL31D7CB49ABAAB4DB&index=7",
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/wheather2.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=Vu4BJHVFl5fL668rJCyj5xo4q4gLhDmHTnGCc3Fhk%2BgEq%2BqGQbK8YYvy0zz0idhGXeEpLg%2F8UA6Gd4q3zrHj33wkwBZw6Eqfe63IzLfDzCesyjFqPB9W%2BVjA46F2BCnsAZqtdpdr5ebN8skYRPSDE56p9x0vp8qNT7MDDge1BaKejpHmjdetJ%2FFkmHdh%2BtFylHZvQkjo77lqOFOYvGhKG0fb5N15A8JSLH0OCUPMw0P5LNAPyewkiFRlAWon4riNZTVT0Lc2oloX3ZGNW9WNL41TLLoAHbom6fBJn3XlWqY1JXptQb5DcCbq4s3hvJy22CaHIPUHg3OujzBjlID8yQ%3D%3D",
    id: "- Hi, Jack. This is Jim. I'm calling from Miami.\n- From Miami? What are you doing in Miami?\n- I'm on vacation.\n- How's the weather in Miami? Is it sunny?\n- No. It isn't. It's raining.\n- Is it hot?\n- No. It isn't. It's cold.\n- Are you having a good time?\n- No. I'm not. I'm having a terrible time. The weather is terrible here.\n- I'm sorry to hear that.",
    story_id: 973,
    word_count: 39,
    all_intersection_count: 21,
    new_words_count: 18,
  },
  {
    row_new_words: [
      "waking",
      "up",
      "wakes",
      "at",
      "8:30",
      "saturday",
      "his",
      "class",
      "9:00",
      "students",
      "time",
      "change",
      "clothes",
      "gets",
      "backpack",
      "grabs",
      "granola",
      "bar",
    ],
    words: [
      "waking",
      "up",
      "late",
      "he",
      "wakes",
      "at",
      "8:30",
      "am",
      "on",
      "saturday",
      "his",
      "piano",
      "class",
      "is",
      "9:00",
      "worries",
      "going",
      "to",
      "be",
      "teacher",
      "does",
      "not",
      "like",
      "students",
      "has",
      "no",
      "time",
      "change",
      "clothes",
      "gets",
      "backpack",
      "eat",
      "grabs",
      "a",
      "granola",
      "bar",
    ],
    link: "https://www.eslfast.com/gradedread1/gr/gr1003.htm",
    storage_link: null,
    id: "Waking up late\n\nHe wakes up at 8:30 a.m. on Saturday. His piano class is at 9:00 a.m. He worries he is going to be late. His piano teacher does not like late students. He has no time to change his clothes. He gets his backpack. He has no time to eat. He grabs a granola bar.",
    story_id: 227,
    word_count: 36,
    all_intersection_count: 18,
    new_words_count: 18,
  },
  {
    row_new_words: [
      "selling",
      "bars",
      "Barbara",
      "$1",
      "each",
      "brings",
      "during",
      "time",
      "sells",
      "classmates",
      "$3",
      "days",
      "one",
      "sell",
      "20",
      "earns",
      "uses",
      "things",
    ],
    words: [
      "selling",
      "candy",
      "bars",
      "Barbara",
      "buys",
      "for",
      "$1",
      "each",
      "she",
      "brings",
      "them",
      "to",
      "school",
      "during",
      "lunch",
      "time",
      "sells",
      "her",
      "classmates",
      "$3",
      "some",
      "days",
      "no",
      "one",
      "other",
      "can",
      "sell",
      "20",
      "earns",
      "money",
      "uses",
      "the",
      "buy",
      "things",
      "needs",
    ],
    link: "https://www.eslfast.com/gradedread1/gr/gr1010.htm",
    storage_link: null,
    id: "Selling candy bars\n\nBarbara buys candy bars for $1 each. She brings them to school. During lunch time, she sells them to her classmates. She sells them for $3 each. Some days, no one buys her candy bars. Other days, she can sell 20 candy bars. She earns some money. She uses the money to buy things she needs.",
    story_id: 241,
    word_count: 35,
    all_intersection_count: 17,
    new_words_count: 18,
  },
  {
    row_new_words: [
      "successful",
      "trade",
      "time",
      "kids",
      "out",
      "excited",
      "hungry",
      "Paul",
      "ham",
      "sandwich",
      "tuna",
      "Sarah",
      "thinks",
      "smelly",
      "up",
      "their",
      "sandwiches",
      "get",
    ],
    words: [
      "a",
      "successful",
      "trade",
      "it",
      "is",
      "lunch",
      "time",
      "the",
      "kids",
      "go",
      "out",
      "to",
      "eat",
      "they",
      "are",
      "excited",
      "and",
      "hungry",
      "Paul",
      "has",
      "ham",
      "sandwich",
      "he",
      "does",
      "not",
      "like",
      "wants",
      "tuna",
      "Sarah",
      "she",
      "thinks",
      "smelly",
      "meet",
      "up",
      "their",
      "sandwiches",
      "get",
      "what",
      "want",
    ],
    link: "https://www.eslfast.com/kidsenglish/ke/ke028.htm",
    storage_link: null,
    id: "A successful trade\n\nIt is lunch time. The kids go out to eat. They are excited and hungry. Paul has a ham sandwich. He does not like ham. He wants a tuna sandwich. Sarah has a tuna sandwich. She thinks tuna is smelly. She wants a ham sandwich. Paul and Sarah meet up. They trade their sandwiches. They get what they want.",
    story_id: 351,
    word_count: 39,
    all_intersection_count: 21,
    new_words_count: 18,
  },
  {
    row_new_words: [
      "baby",
      "takes",
      "changes",
      "diaper",
      "feeds",
      "teaches",
      "how",
      "words",
      "easy",
      "take",
      "babies",
      "cries",
      "sometimes",
      "peekaboo",
      "covers",
      "eyes",
      "then",
      "opens",
      "laughs",
    ],
    words: [
      "the",
      "baby",
      "sister",
      "Betty",
      "loves",
      "her",
      "takes",
      "care",
      "of",
      "she",
      "changes",
      "diaper",
      "feeds",
      "teaches",
      "how",
      "to",
      "walk",
      "new",
      "words",
      "it",
      "is",
      "not",
      "easy",
      "take",
      "babies",
      "Betty's",
      "cries",
      "sometimes",
      "plays",
      "peekaboo",
      "with",
      "covers",
      "eyes",
      "and",
      "then",
      "opens",
      "them",
      "laughs",
    ],
    link: "https://www.eslfast.com/kidsenglish/ke/ke023.htm",
    storage_link: null,
    id: "The baby sister\n\nBetty loves her baby sister. Betty takes care of her. She changes her diaper. She feeds her. She teaches her how to walk. She teaches her new words. It is not easy to take care of babies. Betty's baby sister cries sometimes. Betty plays peekaboo with her. She covers her eyes and then opens them. The baby laughs.",
    story_id: 346,
    word_count: 38,
    all_intersection_count: 19,
    new_words_count: 19,
  },
  {
    row_new_words: [
      "stealing",
      "bad",
      "Donna",
      "uncle",
      "supplies",
      "shop",
      "sees",
      "pink",
      "eraser",
      "any",
      "puts",
      "pocket",
      "out",
      "store",
      "tells",
      "back",
      "makes",
      "apologize",
      "manager",
    ],
    words: [
      "stealing",
      "is",
      "bad",
      "Donna",
      "and",
      "her",
      "uncle",
      "go",
      "to",
      "a",
      "school",
      "supplies",
      "shop",
      "sees",
      "pink",
      "eraser",
      "she",
      "likes",
      "it",
      "does",
      "not",
      "have",
      "any",
      "money",
      "puts",
      "the",
      "in",
      "pocket",
      "runs",
      "out",
      "of",
      "store",
      "he",
      "tells",
      "that",
      "back",
      "makes",
      "apologize",
      "manager",
    ],
    link: "https://www.eslfast.com/kidsenglish/ke/ke084.htm",
    storage_link: null,
    id: "Stealing is bad\n\nDonna and her uncle go to a school supplies shop. Donna sees a pink eraser. She likes it. She does not have any money. She puts the eraser in her pocket. She runs out of the store. Her uncle sees her. He tells her that stealing is bad. He puts the eraser back. He makes Donna apologize to the manager.",
    story_id: 410,
    word_count: 39,
    all_intersection_count: 20,
    new_words_count: 19,
  },
  {
    row_new_words: [
      "end",
      "off",
      "this",
      "phrase",
      "secret",
      "safe",
      "said",
      "hey",
      "bob",
      "keep",
      "would",
      "probably",
      "reply",
      "means",
      "tell",
      "won't",
      "anyone",
      "else",
      "promise",
    ],
    words: [
      "so",
      "I'm",
      "going",
      "to",
      "end",
      "off",
      "with",
      "this",
      "phrase",
      "today",
      "and",
      "it's",
      "the",
      "your",
      "secret",
      "is",
      "safe",
      "me",
      "if",
      "you",
      "said",
      "hey",
      "bob",
      "can",
      "keep",
      "a",
      "I",
      "would",
      "probably",
      "reply",
      "say",
      "yes",
      "that",
      "it",
      "means",
      "tell",
      "won't",
      "anyone",
      "else",
      "promise",
    ],
    link: "(9)https://www.youtube.com/watch?v=KjynWAC2bLk&list=PLQut38RVINETJDG9hzlMPp62gDRCPKmrH&index=20",
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/9keepsecretbob.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=odTSBxeaW9SriQ0A2L7yxxdFoqUiLU5Ph3jyNMTFstH2RbyZqC53OQEtQX5ITJ9YxWayJC9eGFLOJmueSnGXSSL0IvoEDH3qDCFu9PHp%2FHDR5jGBbm3%2BDKYLAqitpoubMVgbyRrIfpIVwPT6UGJIjSk4TFsWsLzWYNm5WQwoEuuF9jUJB%2FKmoeaxnVt5xAscJqrW2PcObMb7tIArl%2BCg2MCGhVQZ1%2Fhz0unuaCevtgneDWtnxN2IWB2Samron6gGEGKKnEw5vQtaqtskX9J3ewTxVXpP%2BEwUVWUnD3AYL%2F1NOKeOT4%2FM0pTlwzckdMSCrD%2B0xjOOYx4Myyqk25ym8A%3D%3D",
    id: "So I'm going to end off with this phrase today. And it's the phrase, your secret is safe with me. If you said to me, hey, Bob, can you keep a secret? I would probably reply and say, yes, your secret is safe with me. If I say that to you, it means you can tell me your secret and I won't tell anyone else, I promise.",
    story_id: 1390,
    word_count: 40,
    all_intersection_count: 21,
    new_words_count: 19,
  },
  {
    row_new_words: [
      "stains",
      "George",
      "small-sized",
      "iced",
      "begins",
      "his",
      "back",
      "home",
      "trips",
      "rock",
      "spills",
      "white",
      "shirt",
      "upset",
      "hard",
      "remove",
      "immediately",
      "washes",
      "detergent",
    ],
    words: [
      "coffee",
      "stains",
      "George",
      "loves",
      "he",
      "buys",
      "a",
      "small-sized",
      "cup",
      "of",
      "iced",
      "begins",
      "his",
      "walk",
      "back",
      "home",
      "trips",
      "on",
      "rock",
      "the",
      "spills",
      "white",
      "shirt",
      "is",
      "upset",
      "are",
      "hard",
      "to",
      "remove",
      "runs",
      "and",
      "immediately",
      "washes",
      "with",
      "detergent",
      "water",
    ],
    link: "https://www.eslfast.com/gradedread1/gr/gr1067.htm",
    storage_link: null,
    id: "Coffee stains\n\nGeorge loves coffee. He buys a small-sized cup of iced coffee. He begins his walk back home. He trips on a rock. The coffee spills on his white shirt. George is upset. Coffee stains are hard to remove. He runs back home, and immediately washes his shirt with detergent and water.",
    story_id: 284,
    word_count: 36,
    all_intersection_count: 17,
    new_words_count: 19,
  },
  {
    row_new_words: [
      "out",
      "friends",
      "football",
      "outdoors",
      "having",
      "fun",
      "games",
      "sun",
      "really",
      "chores",
      "hates",
      "making",
      "his",
      "even",
      "more",
      "sweeping",
      "floor",
      "TV",
      "instead",
    ],
    words: [
      "I",
      "like",
      "going",
      "out",
      "with",
      "my",
      "friends",
      "and",
      "love",
      "playing",
      "football",
      "outdoors",
      "having",
      "fun",
      "games",
      "in",
      "the",
      "sun",
      "but",
      "really",
      "don't",
      "doing",
      "chores",
      "brother",
      "likes",
      "walking",
      "dog",
      "he",
      "hates",
      "making",
      "his",
      "bed",
      "what",
      "even",
      "more",
      "is",
      "sweeping",
      "floor",
      "loves",
      "watching",
      "TV",
      "instead",
    ],
    link: "https://learnenglishkids.britishcouncil.org/grammar-vocabulary/grammar-chants/i-going-out",
    storage_link: null,
    id: "I like going out with my friends\nAnd I love playing football outdoors\nI like having fun\nPlaying games in the sun\nBut I really don't like doing chores.\n\nMy brother likes walking the dog\nBut he really hates making his bed\nWhat he hates even more\nIs sweeping the floor\nHe loves watching TV instead.",
    story_id: 824,
    word_count: 42,
    all_intersection_count: 23,
    new_words_count: 19,
  },
  {
    row_new_words: [
      "second",
      "one",
      "already",
      "black",
      "fur",
      "big",
      "another",
      "goes",
      "pet",
      "store",
      "brown",
      "medium-sized",
      "brings",
      "home",
      "puts",
      "two",
      "together",
      "away",
      "each",
    ],
    words: [
      "the",
      "second",
      "dog",
      "she",
      "has",
      "one",
      "already",
      "it",
      "black",
      "fur",
      "is",
      "very",
      "big",
      "wants",
      "another",
      "goes",
      "to",
      "pet",
      "store",
      "buys",
      "brown",
      "medium-sized",
      "brings",
      "home",
      "puts",
      "her",
      "two",
      "dogs",
      "together",
      "they",
      "run",
      "away",
      "from",
      "each",
      "other",
      "do",
      "not",
      "like",
    ],
    link: "https://www.eslfast.com/kidsenglish/ke/ke069.htm",
    storage_link: null,
    id: "The second dog\n\nShe has one dog already. It has black fur. It is very big. She wants another one. She goes to the pet store. She buys another dog. It has brown fur. It is medium-sized. She brings it home. She puts her two dogs together. They run away from each other. They do not like each other.",
    story_id: 394,
    word_count: 38,
    all_intersection_count: 19,
    new_words_count: 19,
  },
  {
    row_new_words: [
      "group",
      "project",
      "Ms",
      "Howard",
      "assigns",
      "students",
      "groups",
      "Adam",
      "Joe",
      "bill",
      "smart",
      "draws",
      "cooperative",
      "make",
      "turn",
      "their",
      "high",
      "grade",
      "joyful",
    ],
    words: [
      "group",
      "project",
      "Ms",
      "Howard",
      "assigns",
      "a",
      "she",
      "wants",
      "the",
      "students",
      "to",
      "work",
      "in",
      "groups",
      "Adam",
      "works",
      "with",
      "Joe",
      "and",
      "bill",
      "is",
      "smart",
      "draws",
      "well",
      "cooperative",
      "they",
      "make",
      "good",
      "turn",
      "their",
      "likes",
      "it",
      "gives",
      "them",
      "high",
      "grade",
      "are",
      "joyful",
    ],
    link: "https://www.eslfast.com/kidsenglish/ke/ke012.htm",
    storage_link: null,
    id: "Group project\n\nMs. Howard assigns a project. She wants the students to work in groups. Adam works with Joe and Bill. Adam is smart. Joe draws well. Bill is cooperative. They make a good group. They turn in their project. Ms. Howard likes it. She gives them a high grade. Adam, Joe, and Bill are joyful.",
    story_id: 342,
    word_count: 38,
    all_intersection_count: 19,
    new_words_count: 19,
  },
];

export default storiesData;
