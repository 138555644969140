import { useEffect, useMemo, useState } from "react";
import supabaseClient from "../../common/supabaseClient";
import useMountedWaitAsyncEffect from "../../hooks/useMountedWaitAsyncEffect";
import { useLessonsStore } from "../../store/lessonsStore";
import { Word } from "../../types/supabase";
import { usePrevious } from "react-use";
import deepEqual from "deep-equal";

const useGlobalWords = ({ words: inputWords }: { words: string[] }): Word[] => {
  const setGlobalWords = useLessonsStore((state) => state.setGlobalWords);
  const globalWords = useLessonsStore((state) => state.globalWords);
  // const [isFirstLoaded, setIsFirstLoaded] = useState(false);

  // console.log("🚀 ~ inputWords:", inputWords);
  const words = useMemo(() => {
    return globalWords.filter((word) => {
      try {
        const searchWords =
          (inputWords.length && inputWords.includes(word.name)) ||
          inputWords.map((w) => w.toLowerCase()).includes(word.name);
        return searchWords;
      } catch (err) {
        console.log("🚀 ~ err:", err);
        return [];
      }
    });
  }, [inputWords, globalWords]);
  console.log("🚀 &&&&&&&&&&:", words);

  const prevInputWords = usePrevious(inputWords);
  console.log("🚀 ~ prevInputWords:", prevInputWords);

  useMountedWaitAsyncEffect(
    // useEffect(() => {
    async () => {
      try {
        if (
          inputWords.filter(Boolean).length &&
          inputWords.join("") !== words.map((w) => w.name).join("") &&
          // !deepEqual(prevInputWords, inputWords) &&
          words.length < inputWords.length
        ) {
          // ||
          const rowWords = words.map((w) => w.name).filter(Boolean);
          const notExistingWords = inputWords.filter(Boolean).filter((w) => !rowWords.includes(w));
          if (notExistingWords.length) {
            console.error(`Not existing words found: ${notExistingWords.join(", ")}`);
          }

          // !isFirstLoaded
          const wordsToSearch = Array.from(
            new Set([...inputWords, ...inputWords.map((w) => w.toLowerCase())]),
          );
          console.log("🚀 ~ wordsToSearch:", wordsToSearch);
          // console.log("🚀 ~ wordsToSearch:", wordsToSearch);
          const { data } = await supabaseClient
            .from("words")
            .select()
            .in("name", wordsToSearch)
            .returns<Word[]>();
          console.log("🚀 ~ data:", data);
          // setIsFirstLoaded(true);
          if (data) setGlobalWords(data);
        }
      } catch (err) {
        console.log("🚀 ~ err:", err);
      }
    },
    [words, inputWords, prevInputWords],
  );

  useEffect(() => {
    (async () => {
      try {
        if (
          inputWords.filter(Boolean).length &&
          inputWords.join("") !== words.map((w) => w.name).join("") &&
          !deepEqual(prevInputWords, inputWords)
        ) {
          const wordsToSearch = Array.from(
            new Set([...inputWords, ...inputWords.map((w) => w.toLowerCase())]),
          );
          // console.log("🚀 ~ wordsToSearch:", wordsToSearch);
          const { data } = await supabaseClient
            .from("words")
            .select()
            .in("name", wordsToSearch)
            .returns<Word[]>();
          // console.log("🚀 ~ data:", data);
          if (data) setGlobalWords(data);
        }
      } catch (err) {
        console.log("🚀 ~ err:", err);
      }
    })();
  }, []);

  return words;
};

export default useGlobalWords;
