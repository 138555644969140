import {
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Box from "../../Box";
import filterRules from "./utils/filterRules";
import AccentWord from "../../../Difference/components/AccentWord";
import { RuleType } from "../../Root/grammarMap";
import { useLessonsStore } from "../../../../store/lessonsStore";
import AccordionRule from "./AccordionRule";
import HStack from "../../HStack";
import { PropsWithChildren } from "react";

type CreateDataProp = {
  first: any;
  second: any;
  lesson: number;
  ua: string;
};

function createData(item1: CreateDataProp, item2?: CreateDataProp, item3?: CreateDataProp) {
  return {
    item1,
    item2,
    item3,
  };
}

const Title: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <Typography
      sx={{ textDecoration: "1underline" }}
      component="span"
      fontWeight={700}
      fontSize={25}
      display="block"
    >
      {children}
    </Typography>
  );
};
const Sentence: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <Typography component="span" fontSize={20} display="block" fontStyle="italic">
      {children}
    </Typography>
  );
};
const AccentSpan: React.FC<PropsWithChildren & { color?: string }> = ({ children, color }) => {
  return (
    <span style={{ color: color || "#4aa805", textDecoration: "underline" }}>
      <span style={{ color: "black" }}>{children}</span>
    </span>
  );
};

const rows = (lessonNumber: number) => [
  createData(
    {
      first: "tall",
      second: "short",
      lesson: 11,
      ua: "високий / низький",
    },
    {
      first: "yes",
      second: "no",
      lesson: 11,
      ua: "так / ні",
    },
    {
      first: "do",
      second: "doesn't",
      lesson: 11,
      ua: "робити / не робити",
    },
  ),
];

const Gerund = () => {
  const lessonNumber = useLessonsStore((state) => state.lessonNumber);

  return (
    <>
      <HStack>
        <Box>
          <Typography variant="h3">Герундій</Typography>
          <Typography>
            Герундій в англійській мові - це форма дієслова, що поєднує в собі якості іменника та
            дієслова, найчастіше українською мовою перекладається у формі іменника (плавання,
            читання, водіння тощо).
          </Typography>
        </Box>

        <Typography variant="h3">Слова після яких зазвичай використовується герундій.</Typography>
        <Box width="100%">
          <Title>love</Title>
          <Sentence>
            Do you <AccentSpan color="black">love</AccentSpan> <AccentSpan>reading</AccentSpan>{" "}
            books?
          </Sentence>
          <Sentence>
            I <AccentSpan color="black">love</AccentSpan> <AccentSpan>walking</AccentSpan> in the
            park.
          </Sentence>
        </Box>
        <Box width="100%">
          <Title>like</Title>
          <Sentence>
            {" "}
            I <AccentSpan color="black">like</AccentSpan> <AccentSpan>learning</AccentSpan> English.
          </Sentence>
          <Sentence>
            He <AccentSpan color="black">likes</AccentSpan> <AccentSpan>playing</AccentSpan> the
            piano.
          </Sentence>
        </Box>
        <Box width="100%">
          <Title>start</Title>
          <Sentence>
            Don't <AccentSpan color="black">start</AccentSpan> <AccentSpan>learning</AccentSpan>{" "}
            English late.
          </Sentence>
          <Sentence>
            <AccentSpan color="black">Start</AccentSpan> <AccentSpan>working</AccentSpan> on English
            today.
          </Sentence>
        </Box>
      </HStack>
      {/* <TableContainer
        component={Paper}
        sx={{
          width: 600,
        }}
      >
        <Table sx={{ minWidth: 600, width: 600 }} aria-label="simple table">
          <TableBody>
            {rows(lessonNumber)
              .filter((r) => r.item1.lesson <= lessonNumber)
              .map((row, index) => (
                <TableRow key={index}>
                  <CustomTableCell
                    width={200}
                    first={row.item1.first}
                    second={row.item1.second}
                    lesson={row.item3?.lesson || 0}
                    ua={row.item1?.ua}
                  />
                  <CustomTableCell
                    width={200}
                    first={row.item2?.first}
                    second={row.item2?.second}
                    lesson={row.item2?.lesson || 0}
                    ua={row.item2?.ua}
                  ></CustomTableCell>
                  <CustomTableCell
                    width={200}
                    first={row.item3?.first}
                    second={row.item3?.second}
                    lesson={row.item3?.lesson || 0}
                    ua={row.item3?.ua}
                  ></CustomTableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer> */}
    </>
  );
};

const CustomTableCell: React.FC<{
  ua?: string;
  first: string;
  second: string;
  width: number;
  lesson: number;
}> = ({ first, second, lesson, width, ua }) => {
  const lessonNumber = useLessonsStore((state) => state.lessonNumber);
  const isReady = lesson <= lessonNumber;

  return (
    <TableCell
      component="th"
      scope="row"
      sx={{
        m: 0,
        width,
        pointerEvents: isReady ? "auto" : "none",
        backgroundColor:
          lesson === lessonNumber ? "#ddffc5" : lesson <= lessonNumber ? "#fcffdb" : "#d0d0d0",
        height: "auto",
        cursor: "pointer",
        minHeight: 0,
        borderRight: "1px solid rgba(224, 224, 224, 1)",
        borderLeft: "1px solid rgba(224, 224, 224, 1)",
        borderBottom: "1px solid rgba(224, 224, 224, 1)",
        borderTop: "1px solid rgba(224, 224, 224, 1)",
        py: 1,
      }}
    >
      <Typography variant="body1" fontWeight={500}>
        {first} / {second}
      </Typography>
      <Typography variant="caption">{ua}</Typography>
    </TableCell>
  );
};

export default Gerund;
